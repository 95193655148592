// @flow
/* eslint-disable import/max-dependencies */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import api from "../../services/api";
import { CONTACT_INFO_FORM } from "../../constants";

export type UseContactInfoFormHook = {
  isLoading: boolean,
};

export const useContactInfoForm: () => UseContactInfoFormHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const {
    isLoading,
    data,
  } = useQuery(["apiGetUserContanctInfo"], () => api.apiGetUserContanctInfo(), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setFormData(CONTACT_INFO_FORM, data.data));
    }
  }, [data, dispatch]);

  return {
    isLoading,
  };
};
