// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const TrackingTools: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 6H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V6H4C2.897 6 2 6.897 2 8V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V8C22 6.897 21.103 6 20 6ZM15 4V6H9V4H15ZM8 8H20V11H4V8H8ZM4 19V13H10V15H14V13H20L20.001 19H4Z" />
  </SvgIcon>
);

export default TrackingTools;
