// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import DialogComponent from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDialog";
import DateRangePresetsDefault from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePresets";
import DateRangeDateCellDefault from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDateCell";
import dayjs from "dayjs";
import { withStyles } from "@mui/styles";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { maxDate, minDate, presets } from "./presets";
import DateRangeFooter from "../DateRangeFooter";
import SelectBody, { type Props } from "../SelectPopup/SelectBody";
import { useDictionaryValueList } from "../../hooks/useDictionaryValueList";
import TextFieldMultiselectBody, { type Props as PropsBody } from "../TextFieldMultiselect/TextFieldMultiselectBody";
import type { UseMemoryOptionsHook } from "../../hooks/useMemoryOptions";
import { useMemoryOptions } from "../../hooks/useMemoryOptions";
import { countrySelectStyles } from "../../styles";

const StyledTextFieldMultiselectBody: StatelessFunctionalComponent<
  ComponentProps<PropsBody, string, UseMemoryOptionsHook>
  > = withHookPropsState(withStyles({ popper: { maxWidth: "360px" } })(TextFieldMultiselectBody), useMemoryOptions);
const DateRangePicker: * = withStyles({})(DialogComponent);

const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<Props, Dictionaries, UseDictionaryType>
  > = withStyles(countrySelectStyles)(withHookPropsState(SelectBody, useDictionaryValueList));

// eslint-disable-next-line import/prefer-default-export
export const reportColumnsMapMain: { [string]: Column<*> } = {
  date: {
    field: "date",
    label: "Date",
    searchable: true,
    sortable: true,
    // $FlowFixMe onClose
    SearchComponent: ({ onClose, onChange, value }: *): * => {
      const changeDates: * = ({ startDate: start, endDate: end }: { startDate: *, endDate: *}) => {
        onChange({
          date: {
            from: dayjs(start).format("YYYY-MM-DD"),
            to: dayjs(end).format("YYYY-MM-DD"),
          },
        });
        onClose();
      };

      return (
        <DateRangePicker
          applyOnClose
          applyOnSelect
          startDate={value.from}
          endDate={value.to}
          onChange={changeDates}
          onChangePreset={changeDates}
          onClose={onClose}
          currentPreset=""
          presets={presets}
          minDate={minDate}
          maxDate={maxDate}
          CellComponent={DateRangeDateCellDefault}
          PresetsComponent={DateRangePresetsDefault}
          FooterComponent={DateRangeFooter}
        />
      );
    },
  },
  country: {
    field: "country",
    label: "Country",
    searchable: true,
    sortable: true,
    SearchComponent: ({ ...props }: *): * => (
      <CountrySelect
        {...props}
        label="Country"
        hookProps="country"
        onChange={(country: string | string[]): * => props.onChange({ country })}
        defaultValue={[]}
        isSplitListBoxColumn
      />
    ),
  },
  subId: {
    field: "subId",
    label: "Subid",
    searchable: true,
    sortable: true,
    SearchComponent: ({ value, onChange }: *): * => (
      <StyledTextFieldMultiselectBody
        hookProps="subIdMain"
        value={value}
        label="Subid"
        placeholder="Input subId"
        name="subId"
        onChange={(subId: string | string[]): * => onChange({ subId })}
      />
    ),
  },
  subId2: {
    field: "subId2",
    label: "Subid2",
    searchable: true,
    sortable: true,
    SearchComponent: ({ value, onChange }: *): * => (
      <StyledTextFieldMultiselectBody
        hookProps="subId2Main"
        value={value}
        label="Subid 2"
        placeholder="Input subId 2"
        name="subId"
        onChange={(subId2: string | string[]): * => onChange({ subId2 })}
      />
    ),
  },
  clicks: {
    field: "clicks",
    label: "Clicks",
    sortable: true,
  },
  cpa_registrations: {
    field: "cpa_registrations",
    label: "Registrations",
    sortable: true,
  },
  cpa_confirms: {
    field: "cpa_confirms",
    label: "Confirms",
    sortable: true,
  },
  cpa_installs: {
    field: "cpa_installs",
    label: "Installs",
    sortable: true,
  },
  sales: {
    field: "sales",
    label: "Sales",
    sortable: true,
  },
  click2reg: {
    field: "click2reg",
    label: "Click2Reg %",
    sortable: true,
  },
  reg2conf: {
    field: "reg2conf",
    label: "Reg2Conf %",
    sortable: true,
  },
  reg2sale: {
    field: "reg2sale",
    label: "Reg2Sale %",
    sortable: true,
  },
  epc: {
    field: "epc",
    label: "EPC $",
    sortable: true,
  },
  revenue: {
    field: "revenue",
    label: "Revenue $",
    sortable: true,
  },
  additional_revenue: {
    field: "additional_revenue",
    label: "Additional revenue $",
    sortable: true,
  },
  total_revenue: {
    field: "total_revenue",
    label: "Total revenue $",
    sortable: true,
  },
};
