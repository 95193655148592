// @flow
/* eslint-disable import/max-dependencies */
import React, { useState, type Node, type StatelessFunctionalComponent } from "react";
import {
  LineChart, CartesianGrid, Line, ResponsiveContainer, XAxis, YAxis, Tooltip,
} from "recharts";
import {
  Box,
  Skeleton, useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import type { UseStateType } from "@fas/ui-core/lib/SearchComponentsV2/helpers/types";
import type { Option } from "@fas/cpa-cabinet-ui";
import type { LineInfoType } from "./types/Chart.types";
import LinearGradient from "../LinearGradient";
import GlowShadowFilter from "../GlowShadowFilter";
import ChartLegend from "./ChartLegend";
import ChartYaxesLegend from "./ChartYaxesLegend";
import XAxisContent from "./XAxisContent";
import type { DashboardItemType } from "../../hooks/useChart";
import NoData from "../NoData";

const formatDate: (string) => string = (v) => v;

export type Props = {
  data: DashboardItemType[],
  period: string,
  periods: Option[],
  onChangePeriod: (string) => mixed,
  isLoading: boolean,
  refetch: () => mixed,
}

const commonLineProps: * = {
  filter: "url(#glowShadow)",
  type: "monotone",
  strokeWidth: 4,
  strokeLinecap: "round",
  dot: false,
  activeDot: false,
  isAnimationActive: false,
};
const colorLine: string = "rgba(255, 255, 255, 0.10)";
const axisStyle: * = {
  fontSize: "12px",
  fill: "rgba(217, 217, 217, 0.70)",
};
const TickComponent = (p: *): Node => <XAxisContent {...p} colorLine="#DDDF4B" axisStyle={axisStyle} />;

const Chart: StatelessFunctionalComponent<Props> = ({
  data,
  period,
  periods,
  onChangePeriod,
  isLoading,
  refetch,
}: Props): Node => {
  const theme: * = useTheme();
  const isMob: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const axisStyleLeft: * = {
    fontSize: "12px",
    fontWeight: "700",
    fill: "#CDDC39",
  };
  const axisStyleRight: * = {
    fontSize: "12px",
    fontWeight: "700",
    fill: "#836FA9",
  };

  const [lines, setLines]: UseStateType<LineInfoType[]> = useState([
    {
      hide: false,
      label: "Clicks",
      labelLegend: "Clicks",
      dataKey: "clicks",
      colors: ["#CDDC39", "#DDDF4B"],
      yAxisId: "left",
    },
    {
      hide: false,
      label: "Leads",
      labelLegend: "Leads",
      dataKey: "leads",
      colors: ["#836FA9", "#6247AA"],
      yAxisId: "right",
    },
  ]);

  const onChangeLegend: (string, boolean) => void = (key: string, value: boolean) => {
    const index: number = lines.findIndex(({ dataKey }: LineInfoType): boolean => dataKey === key);
    const newLines: LineInfoType[] = [
      ...lines.slice(0, index),
      { ...lines[index], hide: value },
      ...lines.slice(index + 1),
    ];
    setLines(newLines);
  };

  const isShowChart: boolean = !!data.length;
  const isShowNoData: boolean = !isLoading && data.length === 0;
  const isShowLoading: boolean = !!isLoading && data.length === 0;

  let body: Node = <Box w={1} h={1} />;

  if (isShowLoading) {
    body = (
      <ResponsiveContainer minHeight={150} minWidth={150} aspect={2}>
        <Box py={2} height={1}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      </ResponsiveContainer>
    );
  }
  else if (isShowNoData) {
    body = (
      <NoData />
    );
  }
  else if (isShowChart) {
    body = (
      <Box sx={({
        [theme.breakpoints.only("xs")]: {
          [`& path[filter="${commonLineProps.filter}"]`]: {
            strokeWidth: 3,
          },
        },
      })}
      >
        <ResponsiveContainer minHeight={100} minWidth={200} aspect={2}>
          <LineChart
            mirror
            data={data}
            margin={{
              top: 30, right: 20, left: 0, bottom: 20,
            }}
          >
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.cardBorderRadius,
                padding: "8px 12px",
                boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.2)",
                border: "none",
                marginTop: isMob ? "calc(((100vw - 24px) / 2) - 80px)" : "0",
              }}
              wrapperStyle={{
                outline: "none",
                zIndex: theme.zIndex.tooltip,
              }}
              labelFormatter={formatDate}
              cursor={{ stroke: colorLine }}
            />
            <CartesianGrid vertical={false} stroke={colorLine} />
            <XAxis
              padding={{
                left: 20, right: 20,
              }}
              dataKey="date"
              tickFormatter={formatDate}
              tick={TickComponent}
              axisLine={{ stroke: colorLine }}
              tickLine={false}
              tickSize={20}
            />

            <defs>
              <GlowShadowFilter id="glowShadow" glowSize={7} />
              {lines.map(({ dataKey, colors }: LineInfoType): Node => (
                <LinearGradient key={dataKey} id={dataKey} colors={colors} lineOffset={0.04} />
              ))}
            </defs>

            <YAxis
              width={60}
              tickLine={false}
              axisLine={false}
              tick={axisStyleLeft}
              yAxisId="left"
            />
            <YAxis
              width={50}
              tick={axisStyleRight}
              tickLine={false}
              axisLine={false}
              yAxisId="right"
              orientation="right"
            />

            {lines.map((props: LineInfoType): Node => (
              <Line
                key={props.dataKey}
                {...commonLineProps}
                {...props}
                dataKey={props.dataKey}
                stroke={`url(#${props.dataKey})`}
                name={props.label}
                yAxisId={props.yAxisId}
              />
            ))}

          </LineChart>
        </ResponsiveContainer>
      </Box>

    );
  }

  return (
    <>
      <ChartLegend
        payload={lines}
        onChangeLegend={onChangeLegend}
        period={period}
        periods={periods}
        onChangePeriod={onChangePeriod}
        disabled={isLoading}
        onApply={refetch}
      />
      <ChartYaxesLegend payload={lines} />
      {body}
    </>
  );
};

export default Chart;
