// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box } from "@mui/material";
import NoData from "../NoData";
import ReportEmptyIcon from "../../icons/ReportEmpty";

const NoDataReport: StatelessFunctionalComponent<*> = () => (
  <Box sx={{
    height: 1, display: "flex", flex: 1, justifyContent: "center", alignItems: "center",
  }}
  >
    <NoData
      Icon={<Box><ReportEmptyIcon /></Box>}
      headerText="No data available for the selected filters"
      text="Select the right filters to see your results, or try new cool offers to give your stats a boost!"
    />
  </Box>
);

export default NoDataReport;
