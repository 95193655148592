// @flow
import { type DefaultTheme } from "../themes";
import formGridStyles from "./formGrid";

const paymentInfoForm: (DefaultTheme) => * = (theme) => ({
  root: {
    "& form": {
      ...formGridStyles(theme).root,
    },
  },
});

export default paymentInfoForm;
