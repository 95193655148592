// @flow
import { type DefaultTheme } from "../themes";

const paymentHistory: (DefaultTheme) => * = (theme) => ({
  exportContainer: {
    position: "absolute",
    top: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "14px",
      lineHeight: 1.428,
      marginRight: "12px",
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
        lineHeight: "1.5",
      },
    },
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "12px 0 20px",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      margin: "8px 0 12px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 20px",
    },
  },
  dateRangeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  applyFiltersBtn: {
    ...theme.typography.mainButton,
    marginTop: "20px",
    whiteSpace: "noWrap",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 0 20px",
    },
  },
});

export default paymentHistory;
