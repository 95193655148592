// @flow
import { type DefaultTheme } from "../themes";

// eslint-disable-next-line import/prefer-default-export
export const formFieldLabelStyles: (DefaultTheme) => * = (theme) => ({
  root: {
    position: "absolute!important",
    left: "8px!important",
    top: "-8px!important",
    zIndex: 1,
    color: "rgba(45, 46, 47, 0.70)",
    background: theme.palette.text.primary,
    padding: "0px 4px ",
    textTransform: "uppercase",
    transform: "none",
    "&.Mui-focused": {
      color: "rgba(45, 46, 47, 0.70)",
    },
    "&.Mui-error": {
      background: theme.palette.error.main,
    },
    "& > p": {
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: 1,
      color: "rgba(45, 46, 47, 0.70)",
    },
  },
});

export default formFieldLabelStyles;
