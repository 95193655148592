// @flow

import type { StatelessFunctionalComponent } from "react";
import { withStyles } from "@mui/styles";
import DateRangeInput from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeInput";
import type { DefaultTheme } from "../../themes";

const DateInputComponent: StatelessFunctionalComponent<*> = withStyles((theme: DefaultTheme): * => ({
  root: {
    width: "100%",

    "& .MuiInputBase-root, & .MuiInputBase-colorPrimary": {
      border: "1px solid rgba(218, 224, 227, 0.40)",
      background: " rgba(0, 0, 0, 0.20)",
      backgroundBlendMode: "multiply",
      padding: "7px 8px",
    },
    "& .MuiInputBase-root.Mui-focused": {
      borderColor: theme.palette.text.primary,
      "& .MuiSvgIcon-root, & input": {
        color: theme.palette.text.primary,
      },
    },
    "&:before": {
      content: "none",
    },
    "& input": {
      fontSize: "14px",
      lineHeight: 1.428,
      color: theme.palette.text.main,
      padding: 0,
      height: "auto",
    },
    "& fieldset": {
      border: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiButtonBase-root": {
      padding: 0,
      marginLeft: "8px",
    },
  },
}))(DateRangeInput);

export default DateInputComponent;
