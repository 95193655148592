// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import handleError from "@fas/cpa-state-manager/redux/sagas/handleError";
import getManagerInfoSaga from "@fas/cpa-state-manager/redux/sagas/getManagerInfo";
import api from "../../services/api";

const fetchManagerInfo: () => Saga<void> = getManagerInfoSaga({ fetchMethod: api.getApiV1ManagerContactInfo });

export default function* mainSaga(): Saga<void> {
  return yield all([
    call(handleError),
    call(fetchManagerInfo),
  ]);
}
