// @flow
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTableFilters,
  getTableSorting,
  getTablePage,
  getTablePageSize,
  getTableFields,
  getTableData,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  changeTableItemsTotalAmount,
  setTableData,
  setTableFields,
  setTablePendingFields,
  changeTableFilters,
  changeTablePendingFilters,
  changeTableSorting,
  changeTablePage,
  setTableExportParams,
  setTableTotalData,
} from "@fas/cpa-state-manager/redux/actions/table";
import { useQuery } from "@tanstack/react-query";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import api from "../../services/api";
import reportsConfigs, { type ReportConfig } from "../../components/Reports/reportsConfigs";

export type UseReportHookArgs = {
  tableKey: string,
  columnsMap? : { [string]: Column<*> },
};

export type UseReportTableHook = {
  columns: Column<*>[],
  sorting?: Sorting,
  isLoading?: boolean,
  isLoadingExport?: boolean,
  data: Array<*>,
  refetch: () => void,
  onChangeFilters: (data: Filters) => mixed,
  onChangeSorting: (data: Sorting) => mixed,
};

export type RequestBody = {|
  filters: Filters,
  sorting?: Array<Sorting>,
  page?: number,
  limit?: number,
  fields: Array<string>,
  group?: Array<string>,
|};

export const useReportTable: (UseReportHookArgs) => UseReportTableHook = ({ tableKey, columnsMap: columnsMapProp }) => {
  const dispatch: <A>(A) => A = useDispatch();
  const { columnsMap: columnsMapTable, operationId }: ReportConfig = reportsConfigs(tableKey);
  const columnsMap = columnsMapProp || columnsMapTable;
  const tableData: Array<*> = useSelector((state: *) => getTableData(state, tableKey));
  const filters: Filters = useSelector((state: *) => getTableFilters(state, tableKey));
  const sorting: Sorting = useSelector((state: *) => getTableSorting(state, tableKey));
  const page: number = useSelector((state: *) => getTablePage(state, tableKey));
  const limit: number = useSelector((state: *) => getTablePageSize(state, tableKey));
  const fields: string[] = useSelector((state: *) => getTableFields(state, tableKey));
  const [current = "", direction = ""] = (Object.entries(sorting)[0]) || [];
  const {
    // $FlowFixMe
    date: { from, to } = {},
    groupBy,
    ...restFilters
  }: Filters = filters;

  const requestBody: RequestBody = {
    filters: Object
      .keys(restFilters)
      .filter((key: string): boolean => (
        Array.isArray(filters[key]) ? Boolean(filters[key].length) : Boolean(filters[key])))
      .reduce((acc: *, key: string): * => ({
        ...acc,
        [key]: typeof filters[key] === "string" ? [filters[key].trim()] : filters[key],
      }), {
        // $FlowFixMe
        date: [{ from, to }],
      }),
    // $FlowFixMe
    ...(groupBy && { group: [groupBy] }),
    // $FlowFixMe
    ...(current && { sorting: [{ current, direction }] }),
    fields,
    page,
    limit,
  };

  const {
    isLoading,
    isFetching,
    data,
    refetch,
  } = useQuery([operationId, requestBody], () => api[operationId](requestBody), {
    staleTime: 5 * 60 * 1000,
    onSuccess: async (responce) => {
      loadOperrations(responce);
    },
  });

  useEffect(() => {
    if (data?.data) {
      loadOperrations(data);
    }
  }, [data]);

  const loadOperrations: ({ data: *, total: *, count: number }) => mixed = (responce) => {
    dispatch(setTableData(tableKey, responce.data));
    dispatch(setTableTotalData(tableKey, responce.total[0]));
    dispatch(changeTableItemsTotalAmount(tableKey, responce.count));
    dispatch(setTableFields(tableKey, fields));
    dispatch(setTableExportParams(tableKey, { filters }));
    dispatch(setTablePendingFields(tableKey, fields));
    dispatch(changeTablePendingFilters(tableKey, filters));
  };

  const columns: Column<*>[] = Object
    .keys(columnsMap)
    .filter((key: string): boolean => fields.includes(key))
    .reduce((acc: Column<*>[], key: string): Column<*>[] => [...acc, columnsMap[key]], []);

  const isLoadingTable: boolean = isLoading || isFetching;

  const onChangeFilters: (Filters) => mixed = (newFields) => {
    dispatch(changeTableFilters(tableKey, newFields));
    dispatch(changeTablePage(tableKey, 1));
  };
  const onChangeSorting: (Sorting) => mixed = (newSorting) => dispatch(changeTableSorting(tableKey, newSorting));

  return {
    columns,
    sorting,
    filters,
    isLoading: isLoadingTable,
    refetch,
    data: (isLoadingTable) ? [] : tableData,
    onChangeFilters,
    onChangeSorting,
  };
};
