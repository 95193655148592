// @flow

import { type DefaultTheme } from "../themes";
import tableStyles from "./tableReport";

const reportTableMainTotal: (DefaultTheme) => * = (theme) => ({
  ...tableStyles(theme),
  tableBody: {
    ...tableStyles(theme).tableBody,
    position: "sticky",
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      right: 0,
      top: 0,
    },
    [theme.breakpoints.up("md")]: {
      bottom: 0,
      left: 0,
    },
  },
  cell: {
    ...tableStyles(theme).cell,
    background: "rgb(55,55,33)",
    [theme.breakpoints.down("md")]: {
      margin: "1px 0",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgb(55,55,33)",
    },
  },
});

export default reportTableMainTotal;
