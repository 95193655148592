// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Grid, Skeleton } from "@mui/material";

const SkeletonItem: StatelessFunctionalComponent<{}> = () => (
  <Grid item xs container spacing={1}>
    <Grid item xs={4}>
      <Skeleton variant="rectangular" height={40} />
    </Grid>
    <Grid item xs={4}>
      <Skeleton variant="rectangular" height={40} />
    </Grid>
    <Grid item xs={4}>
      <Skeleton variant="rectangular" height={40} />
    </Grid>
  </Grid>
);

const ReportSkeleton: StatelessFunctionalComponent<{}> = () => (
  <Grid container>
    <Grid item xs container direction="column" spacing={1}>
      <Grid item xs container spacing={1}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={15} width="100%" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={15} width="100%" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" height={15} width="100%" />
        </Grid>
      </Grid>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </Grid>
  </Grid>
);

const BestOffersSkeletonItem: StatelessFunctionalComponent<{}> = () => (
  <Grid container item xs spacing={1} sx={{ mt: 1 }}>
    <Skeleton variant="rectangular" sx={{ height: { xs: "32px", md: "40px" } }} width="100%" />
  </Grid>
);

export const BestOffersSkeleton: StatelessFunctionalComponent<{ isLoading?: boolean, }> = ({
  isLoading,
}) => (isLoading ? (
  <Grid container sx={{ minWidth: "285px" }} width="100%">
    <Grid item xs container direction="column">
      <Grid item xs container>
        <Grid item xs container justifyContent="center">
          <Skeleton variant="rectangular" height={24} width="60%" />
        </Grid>
        <Grid item xs container justifyContent="center">
          <Skeleton variant="rectangular" height={24} width="60%" />
        </Grid>
        <Grid item xs container justifyContent="center">
          <Skeleton variant="rectangular" height={24} width="60%" />
        </Grid>
        <Grid item xs container justifyContent="center">
          <Skeleton variant="rectangular" height={24} width="60%" />
        </Grid>
      </Grid>
      <BestOffersSkeletonItem />
      <BestOffersSkeletonItem />
      <BestOffersSkeletonItem />
      <BestOffersSkeletonItem />
      <BestOffersSkeletonItem />
    </Grid>
  </Grid>
) : null);

export default ReportSkeleton;
