// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, type Node, useState } from "react";
import useStateRef from "react-usestateref";
import {
  Backdrop, IconButton, Paper, Popper,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { mergeClasses } from "@fas/cpa-cabinet-ui/lib/utils";
import { TextFieldForm } from "../TextFieldForm/TextFieldForm";
import TextFieldMultiselectBody from "./TextFieldMultiselectBody";

export type Props = {
  disabled?: boolean,
  classes?: *,
  label?: string,
  formControlSx?: *,
  name: string,
  value?: *,
  onChange?: (*) => *,
  placeholderInput?: string,
  inputProps?: *,
  options: string[],
  setOptions: (string[]) => *,
};

const sx: * = {
  fontSize: "inherit",
  color: "inherit",
  "&&&": {
    fill: "currentColor",
  },
};

const useStyles: * = makeStyles((): * => ({
  icon: {
    padding: 0,
  },
  header: {
    display: "none",
  },
}));

const ListWrapper: * = ({ children, ...ownProps }: { children: Node }): Node => (
  <Popper {...ownProps}>
    <Paper sx={{ background: "#000", padding: 0, boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)" }}>
      {children}
    </Paper>
  </Popper>
);

const TextFieldMultiselect: StatelessFunctionalComponent<Props> = ({
  disabled,
  classes: propClasses = {},
  label,
  formControlSx = {},
  name,
  onChange,
  value,
  placeholderInput = "Start typing to select values",
  options,
  setOptions,
  ...props
}) => {
  const classes: {[string]: string} = mergeClasses(useStyles(), propClasses);
  const [open, setOpen]: * = useState<boolean>(false);
  const [pendingValue, setPendingValue, pendingValueRef]: * = useStateRef(value || []);

  const handleOpen: * = () => {
    setOpen(true);
  };

  const handleClose: * = () => {
    if (value !== pendingValueRef.current && onChange) {
      onChange(pendingValueRef.current);
    }
    setOpen(false);
  };

  const hasValue: boolean = !!pendingValue.length;

  return (
    <div>
      <Backdrop data-testid={open ? "backdrop" : ""} invisible sx={{ zIndex: "modal" }} open={open} onClick={handleClose} />
      {!open && (
        <TextFieldForm
          {...props}
          disabled={disabled}
          formControlSx={formControlSx}
          name={name}
          label={label || ""}
          isAuthenticated
          value={pendingValue.join(", ")}
          className={classes.inputField}
          inputProps={{
            ...props.inputProps,
            readOnly: true,
            onClick: handleOpen,
          }}
          EndIcon={(
            <>
              {hasValue && !disabled && (
                <IconButton
                  disabled={disabled}
                  data-testid="clear"
                  size="small"
                  onClick={(): * => {
                    setPendingValue([]);
                    onChange && onChange([]);
                  }}
                  className={classes.adornmentButton}
                >
                  <Close sx={sx} />
                </IconButton>
              )}
              <IconButton
                disabled={disabled}
                data-testid="open"
                size="small"
                onClick={handleOpen}
                className={classes.icon}
              >
                {open ? <ArrowDropUp sx={sx} /> : <ArrowDropDown sx={sx} />}
              </IconButton>
            </>
          )}
        />
      )}
      {open && (
        <TextFieldMultiselectBody
          options={options}
          setOptions={setOptions}
          ListWrapper={ListWrapper}
          onChange={setPendingValue}
          onClose={handleClose}
          label={label}
          value={pendingValue}
          classes={classes}
          name={name}
          placeholder={placeholderInput}
          formControlSx={formControlSx}
        />
      )}
    </div>
  );
};

export default TextFieldMultiselect;
