// @flow
/* eslint-disable import/max-dependencies */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { DASHBOARD_TABLE } from "@fas/cpa-state-manager/redux/constants";
import { getTableData, getTableFilters } from "@fas/cpa-state-manager/services/selectors";
import { setTableData } from "@fas/cpa-state-manager/redux/actions";
import { changeTableFilters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import type { Option } from "@fas/cpa-cabinet-ui";
import type { Filters } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import dayjs from "dayjs";
import type { Preset } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";
import { defaultPreset, presets } from "./presets";
import api from "../../services/api";

function getGroupByDateRange(from: string, to: string): string {
  const diff: number = Math.abs(dayjs(from).diff(dayjs(to), "day"));
  if (diff > 31) {
    return "month";
  }
  return "day";
}

export type DashboardItemType = {
  date: string,
  clicks: number,
  leads: number,
};

export type UseChartHook = {
  data: DashboardItemType[],
  period: string,
  periods: Option[],
  onChangePeriod: (string) => mixed,
  isLoading: boolean,
  refetch: () => mixed,
};

export const useChart: () => UseChartHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  // eslint-disable-next-line max-len
  const tableData: DashboardItemType[] = useSelector((state: *): DashboardItemType[] => getTableData(state, DASHBOARD_TABLE));
  const filters: Filters = useSelector((state: *): Filters => getTableFilters(state, DASHBOARD_TABLE));

  const {
    isLoading,
    isFetching,
    data,
    refetch,
  } = useQuery(["apiReportDashboard", filters], () => api.apiReportDashboard({
    filters: {
      date: [{ from: filters.dateFrom, to: filters.dateTo }],
    },
    group: [getGroupByDateRange(filters.dateFrom, filters.dateTo)],
  }));

  useEffect(() => {
    if (data?.data) {
      dispatch(setTableData(DASHBOARD_TABLE, data.data));
    }
  }, [data, dispatch]);

  const onChangePeriod: (string) => void = (newPeriod: string) => {
    const selectedPeriod: Preset = presets
      .find(({ value }: { value: string }): boolean => newPeriod === value) || defaultPreset;
    dispatch(changeTableFilters(DASHBOARD_TABLE, {
      dateFrom: selectedPeriod.start,
      dateTo: selectedPeriod.end,
      preset: selectedPeriod.value,
    }));
  };

  return {
    isLoading: isFetching || isLoading,
    onChangePeriod,
    data: tableData,
    period: filters.preset,
    periods: presets.map(({ label: title, value }: Preset): Option => ({ title, value })),
    refetch,
  };
};
