// @flow
import type { Environment } from "@fas/cpa-state-manager/services/auth";
import getEndpoints from "./endpoints";

const baseUrl: string = "https://api.together-ads.com";

const env: Environment = getEndpoints({
  baseUrl,
});

export default env;
