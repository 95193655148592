// @flow
import { type DefaultTheme } from "../themes";

const userInfo: (DefaultTheme) => * = (theme) => ({
  layout: {
    marginBottom: "12px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "16px",
    },
  },
  card: {
    border: "none",
    "& > .MuiCardContent-root": {
      padding: 0,
      [theme.breakpoints.up("sm")]: {

      },
      "& > div": {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  avatarWrapper: {
    padding: "12px",
    [theme.breakpoints.up("md")]: {
      paddingTop: "28px",
      paddingBottom: "8px",
    },
  },
  avatarButton: {
    padding: "4px",
    border: "2px solid rgba(218, 224, 227, 0.4);",
  },

  avatar: {
    [theme.breakpoints.up("sm")]: {
      background: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.primary}`,
      width: "36px",
      height: "36px",
      filter: "drop-shadow(0px 0px 10px #DDDF4B)",
    },
    [theme.breakpoints.up("md")]: {
      width: "72px",
      height: "72px",
      filter: "drop-shadow(0px 0px 20px #DDDF4B)",
    },
  },
  profileInfo: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  profileName: {
    ...theme.typography.bodyBold,
    color: theme.palette.text.main,
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    textAlign: "center",
  },
  profileId: {
    ...theme.typography.bodySmall,
    color: theme.palette.text.mutted,
  },
  profileLink: {
    color: theme.palette.text.mutted,
    ...theme.typography.bodySmall,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default userInfo;
