/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Divider } from "@mui/material";
import { withStyles } from "@mui/styles";
import type { CardSetupProps } from "@fas/cpa-cabinet-ui";
import {
  SetupCard,
  Table,
  withHookPropsState,
  withState,
} from "@fas/cpa-cabinet-ui";
import Chart from "../Chart";
import {
  useBestOffers, useChart, type UseChartHook,
} from "../../hooks";
import type { Props as ChartProps } from "../Chart/Chart";
import {
  tableStyles, setupCardStyles,
} from "../../styles";
import withNoData from "../WithNoData";
import NoBestOffersData from "./NoBestOffersData";
import { BestOffersSkeleton } from "../ReportSkeleton";

const ChartComponent: StatelessFunctionalComponent<$Diff<ChartProps, UseChartHook>> = withState(Chart, useChart);
const StyledBestOffers: StatelessFunctionalComponent<*> = withStyles(tableStyles)(Table);

const BestOffersWithLoading: StatelessFunctionalComponent<*> = withNoData(NoBestOffersData, StyledBestOffers);
// $FlowFixMe
const BestOffers: StatelessFunctionalComponent<*> = withState(BestOffersWithLoading, useBestOffers);

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);

const ReportLoading: * = withHookPropsState(BestOffersSkeleton, (): * => {
  const { isLoading } = useBestOffers();
  return {
    isLoading,
  };
});

function Dashboard(): Node {
  return (
    <StyledSetupCard title="">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box width={1} height={1} flexWrap="nowrap" sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, alignItems: "stretch" }}>
        <Box display="flex" flexDirection="column" width={1} flexWrap="nowrap" sx={{ overflow: { xs: "visible", lg: "hidden" } }}>
          <Box width={1} flexDirection="column" sx={{ display: "flex", flex: "1", overflow: { xs: "visible", lg: "hidden" } }}>
            <Typography variant="h5" data-testid="best-opportunities-title">Dashboard</Typography>
            <ChartComponent />
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", lg: "block" }, margin: "0 28px" }} />
        <Box
          data-testid="best-opportunities-component"
          sx={{
            flexDirection: "column",
            display: { xs: "block", lg: "flex" },
            height: { xs: "fit-content", lg: "100%" },
            marginTop: { xs: "40px", lg: "0" },
            "& .MuiTableContainer-root": {
              overflowX: {
                sx: "auto",
                sm: "hidden",
              },
            },
          }}
        >
          <Typography variant="h5" data-testid="best-opportunities-title">
            Best Opportunities
          </Typography>
          <BestOffers>
            <ReportLoading hookProps />
          </BestOffers>
        </Box>
      </Box>
    </StyledSetupCard>
  );
}

export default Dashboard;
