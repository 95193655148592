// @flow
/* eslint-disable import/max-dependencies, no-unused-vars */
import environment from "environment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import api from "../../services/api";
import { USER_INFO_FORM } from "../../constants";

export type UseUserInfoFormHook = {
  isLoading?: boolean,
};
export const useUserInfoForm: () => UseUserInfoFormHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const {
    isLoading,
    data,
  } = useQuery(["apiGetUserInfo"], () => api.apiGetUserInfo(), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setFormData(USER_INFO_FORM, data.data));
    }
  }, [data, dispatch]);

  return {
    isLoading,
  };
};
