/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import {
  type CabinetRouterProps,
  ManagerInfo,
  withState,
  type ManagerInfoProps,
} from "@fas/cpa-cabinet-ui";
import { type UseAuthHook, useLogout } from "@fas/cpa-state-manager/hooks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { Provider } from "react-redux";
import NotificationsComponent from "@fas/cpa-cabinet-ui/lib/Notifications";
import withNotifications from "@fas/cpa-state-manager/services/notifications";
import type { UseManagerInfoHook } from "@fas/cpa-state-manager/redux/hooks";
import { useManagerInfo } from "@fas/cpa-state-manager/redux/hooks";
import { withStyles } from "@mui/styles";
import AppHeaderBar from "../../components/AppHeaderBar";
import AppNavBar from "../../components/AppNavBar";
import AppNavBarMob from "../../components/AppNavBar/AppNavBarMob";
import configureStore, { type CabinetStore } from "./store";
import ProfilePage from "../../components/Profile";
import PostbacksPage from "../../components/Postbacks";
import ReportsPage from "../../components/Reports";
import PaymentHistoryPage from "../../components/PaymentHistory";
import {
  useOutstandingBalance, usePostbacks, useProfile, useReports,
} from "../../hooks";
import DashboardPage from "../../components/Dashboard";
import { APP_HEADER_BAR_HEIGHT, APP_FOOTER_BAR_HEIGHT } from "./types/Cabinet.types";

import { RenderMediaQueryComponent } from "../../utils";
import OutstandingBalance from "../../components/OutstandingBalance";
import { managerInfoStyles } from "../../styles";
import Footer from "../../components/Footer";

// $FlowFixMe
const Notifications: StatelessFunctionalComponent<{}> = withNotifications(NotificationsComponent);

const Dashboard: StatelessFunctionalComponent<{}> = () => <DashboardPage />;
const Profile: StatelessFunctionalComponent<{}> = withState(ProfilePage, useProfile);
const Reports: StatelessFunctionalComponent<{}> = withState(ReportsPage, useReports);

const Postbacks: StatelessFunctionalComponent<{}> = withState(PostbacksPage, usePostbacks);
const PaymentHistory: StatelessFunctionalComponent<{}> = () => <PaymentHistoryPage />;
const Logout: StatelessFunctionalComponent<{}> = withState((props: *): Node => <Navigate {...props} />, useLogout);

export const CabinetComponents: $Diff<CabinetRouterProps, UseAuthHook> = {
  Dashboard,
  Postbacks,
  Profile,
  Reports,
  PaymentHistory,
  Logout,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3000,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const StyledManagerInfo: StatelessFunctionalComponent<ManagerInfoProps> = withStyles(managerInfoStyles)(ManagerInfo);

const Manager: StatelessFunctionalComponent<$Diff<ManagerInfoProps, UseManagerInfoHook>> = withState(
  StyledManagerInfo,
  useManagerInfo
);
const OutstandingBalanceLayout: StatelessFunctionalComponent<*> = withState(OutstandingBalance, useOutstandingBalance);

const store: CabinetStore = configureStore();

const CabinetLayout: StatelessFunctionalComponent<{}> = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Notifications />
      <Box>
        <AppHeaderBar />
      </Box>
      <Box
        sx={{
          height: { sx: "inherit", sm: "100%" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <RenderMediaQueryComponent mediaKey="sm" method="up">
          <AppNavBar />
        </RenderMediaQueryComponent>

        <Box
          sx={{
            flexGrow: 1,
            minWidth: "0px",
            overflow: "auto",
            display: { xs: "flex", sm: "block" },
            flexDirection: "column",
            minHeight: { xs: "calc(100vh - 125px)", sm: "initial" },
            margin: { xs: `${APP_HEADER_BAR_HEIGHT} 0 ${APP_FOOTER_BAR_HEIGHT}`, sm: "12px 12px 12px 0", md: "16px 16px 16px 0" },
          }}
        >
          <RenderMediaQueryComponent mediaKey="sm" method="down">
            <Box
              width={1}
              sx={{
                padding: "12px 12px 0",
                backgroundColor: "transparent.main",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "8px",
              }}
            >
              <Box height={1} width={1}>
                <OutstandingBalanceLayout />
              </Box>
              <Box height={1} width={1}>
                <Manager title="Personal manager" />
              </Box>
            </Box>
          </RenderMediaQueryComponent>
          <Outlet />
          <Footer />
        </Box>

        <RenderMediaQueryComponent mediaKey="sm" method="down">
          <AppNavBarMob />
        </RenderMediaQueryComponent>
      </Box>
    </QueryClientProvider>
  </Provider>
);

export default CabinetLayout;
