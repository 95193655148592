// @flow
import React, { type Node } from "react";
import type { CommonFormProps } from "@fas/cpa-cabinet-ui/lib/PaymentInfoForm";

function WireTransfer({
  components: {
    TextFieldForm,
    DictionarySelectFieldForm,
    FormContainer,
  },
  onSave,
  disabled,
  validate,
  id,
}: CommonFormProps): Node {
  return (
    <FormContainer
      id={id}
      onSave={onSave}
      validate={validate}
    >
      <TextFieldForm
        disabled={disabled}
        name="bankAccountName"
        label="Bank Account Name"
        placeholder="What is your bank account name?"
      />
      <TextFieldForm
        disabled={disabled}
        name="beAddress"
        label="Beneficiary Address"
        placeholder="What is your beneficiary address?"
      />
      <DictionarySelectFieldForm
        disabled={disabled}
        name="beCountry"
        label="Beneficiary Country"
        hookProps="countryId"
        placeholder="What is your beneficiary country?"
      />
      <DictionarySelectFieldForm
        disabled={disabled}
        name="payoutCountry"
        label="Payout Country"
        hookProps="countryId"
        placeholder="What is your payout country?"
      />
      <TextFieldForm
        disabled={disabled}
        name="bankName"
        label="Bank Name"
        placeholder="What is your bank name?"
      />
      <TextFieldForm
        disabled={disabled}
        name="bankAddress"
        label="Bank Address"
        placeholder="What is your bank address?"
      />
      <TextFieldForm
        disabled={disabled}
        name="swiftCode"
        label="S.W.I.F.T./BIC code"
        placeholder="What is your S.W.I.F.T./BIC code?"
      />
      <TextFieldForm
        disabled={disabled}
        name="iban"
        label="IBAN"
        placeholder="What is your IBAN?"
      />
      <TextFieldForm
        disabled={disabled}
        name="accountNumber"
        label="Account Number"
        placeholder="What is your account number?"
      />
      <TextFieldForm
        disabled={disabled}
        name="vatNumber"
        label="VAT Number"
        placeholder="What is your VAT number?"
      />
    </FormContainer>
  );
}

export default WireTransfer;
