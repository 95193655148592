/* eslint-disable import/max-dependencies */
// @flow
import environment from "environment";
import { useSelector, useDispatch } from "react-redux";
import { addNotification, changeTablePage } from "@fas/ui-framework";
import FileSaver from "file-saver";
import { type Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import { getTableFilters } from "@fas/cpa-state-manager/services/selectors/table";
import { changeTableFilters } from "@fas/cpa-state-manager/redux/actions/table";
import { useMutation } from "@tanstack/react-query";
import { PAYMENT_HISTORY_TABLE } from "../../constants";
import { getDownloadFileNameZip } from "../../utils";
import { usePaymentHistory, type UsePaymentHistory } from "../usePaymentHistory";
import { getAuthorizationHeaders } from "../../services/api";

export type UsePaymentHistoryActions = {
  classes?: *,
  filters: Filters,
  isLoading?: boolean,
  isLoadingExport: boolean,
  isNoData: boolean,
  onApply: () => mixed,
  onExport: () => mixed,
  onChangeDate: ({ startDate: string, endDate: string }) => mixed,
};

export const usePaymentHistoryActions: () => UsePaymentHistoryActions = () => {
  // const store = useStore();
  const dispatch: <A>(A) => A = useDispatch();
  const { isLoading, data: tableData, refetch: refetchTable }: UsePaymentHistory = usePaymentHistory();

  const filters: Filters = useSelector((state: *): Filters => getTableFilters(state, PAYMENT_HISTORY_TABLE));
  const { dateFrom: from, dateTo: to }: Filters = filters;

  const onApply = () => {
    refetchTable();
  };

  const { mutate, isLoading: isLoadingExportZip } = useMutation({
    mutationKey: ["apiPayoutHistoryExport"],
    mutationFn: () => fetch(environment.endpoints.post.payoutHistoryExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(),
      },
      body: JSON.stringify({ filters: { created: [{ from, to }] } }),
    }),
    onSuccess: async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      const blob = await response.blob();
      const filename = getDownloadFileNameZip(response.headers);

      FileSaver.saveAs(blob, filename);
      dispatch(addNotification({ message: "Successfully downloaded", severity: "success" }));
    },
    onError: (e: { message: string }) => {
      dispatch(addNotification({ message: e.message || "Something went wrong...", severity: "error" }));
    },
  });

  const onExport = () => {
    mutate();
  };

  const onChangeDate: ({ startDate: string, endDate: string }) => mixed = ({
    startDate,
    endDate,
  }) => {
    if (filters.dateFrom !== startDate || filters.dateTo !== endDate) {
      dispatch(changeTableFilters(
        PAYMENT_HISTORY_TABLE,
        {
          ...filters,
          dateFrom: startDate,
          dateTo: endDate,
        }
      ));
      dispatch(changeTablePage(PAYMENT_HISTORY_TABLE, 1));
    }
  };

  return {
    filters,
    isLoading,
    isLoadingExport: isLoadingExportZip,
    isNoData: tableData.length === 0,
    onChangeDate,
    onApply,
    onExport,
  };
};
