// @flow

import { type DefaultTheme } from "../themes";

const reportTableMainTotal: (DefaultTheme) => * = (theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "8px",
    marginTop: "20px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
      marginTop: "8px",
    },
    "&[data-empty=\"true\"]": {
      display: "none",
    },
  },
  item: {
    background: "rgba(221, 223, 75, 0.15)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      padding: "10px 20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2px 12px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "8px 12px",
    },
  },
  label: {
    marginRight: "12px",
    color: theme.palette.text.mutted,
    ...theme.typography.bodyExtraSmallBold,
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
      lineHeight: "1.428",
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodyExtraSmallBold,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
      lineHeight: "1.428",
    },
  },
  data: {
    ...theme.typography.bodyBold,
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.h4,
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodySmallBold,
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodyBold,
    },
  },
});

export default reportTableMainTotal;
