// @flow
import React from "react";
import type { StatelessFunctionalComponent, Node } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import type { Option } from "@fas/cpa-cabinet-ui";
import LegendItem from "./LegendItem";
import { type LineInfoType } from "./types/Chart.types";
import RefreshButton from "../Report/RefreshButton";

type Props = {
  payload: LineInfoType[],
  onChangeLegend: (string, boolean) => mixed,
  period: string,
  disabled: boolean,
  periods: Option[],
  onChangePeriod: (string) => mixed,
  onApply: () => mixed,
}

const ChartLegend: StatelessFunctionalComponent<Props> = ({
  payload, onChangeLegend, period, periods, onChangePeriod, disabled, onApply,
}: Props) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
      {payload.map((item: LineInfoType): Node => (
        <LegendItem
          key={item.dataKey}
          hide={item.hide}
          dataKey={item.dataKey}
          label={item.labelLegend}
          onChange={onChangeLegend}
        />
      ))}
    </Box>
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <RefreshButton onApply={onApply} disabled={disabled} />
      <Select
        variant="standard"
        disableUnderline
        data-testid="period-select"
        value={period}
        onChange={({ target: { value } }: *): mixed => onChangePeriod(value)}
        disabled={disabled}
      >
        {periods.map(({ title, value }: Option): Node => <MenuItem key={value} value={value}>{title}</MenuItem>)}
      </Select>
    </Box>
  </Box>
);

export default ChartLegend;
