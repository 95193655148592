// @flow
import React from "react";
import type { Node, StatelessFunctionalComponent } from "react";
import { withStyles } from "@mui/styles";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import type { ContextRouter } from "react-router-dom";
import { Link as RouterLink, useLocation } from "react-router-dom";
import type { Url } from "@fas/cpa-cabinet-ui/lib/AppNavSidebar/AppNavSidebar.types";
import links from "./links";
import { APP_FOOTER_BAR_HEIGHT } from "../../pages/Cabinet/types/Cabinet.types";

const getKey: (string, string | string[]) => string = (text, url) => text + (Array.isArray(url) ? url.join("") : url);
const getUrl: (string | string[]) => string = (url) => (Array.isArray(url) ? url[0] : url);

const StyledBottomNavigation: StatelessFunctionalComponent<*> = withStyles((theme: *): * => ({
  root: {
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
    background: theme.palette.background.default,
    height: APP_FOOTER_BAR_HEIGHT,
  },
}))(BottomNavigation);

const StyledBottomNavigationAction: StatelessFunctionalComponent<*> = withStyles((theme: *): * => ({
  selected: {
    background: theme.palette.text.primary,
    "& .MuiSvgIcon-root": {
      fill: theme.palette.text.dark,
    },
    "& .MuiTouchRipple": {
      background: "rgba(255, 255, 255, 0.1)",
    },
  },
  root: {
    padding: 0,
    minWidth: "72px",
  },
}))(BottomNavigationAction);

function AppNavBarMob(): Node {
  const { pathname = "" }: $PropertyType<ContextRouter, 'location'> = useLocation();
  const index: number = links.findIndex(({ url }: Url): boolean => url.includes(pathname));
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
    >
      <StyledBottomNavigation
        value={index}
        data-testid="navigation-menu-bottom"
      >
        {links.map(({ text, url, icon }: Url, i: number): Node => (
          <StyledBottomNavigationAction
            key={getKey(text, url)}
            data-testid={`navigation-menu-item-${text}`}
            component={RouterLink}
            to={getUrl(url)}
            icon={icon}
            value={i}
          />
        ))}
      </StyledBottomNavigation>
    </Paper>
  );
}

export default AppNavBarMob;
