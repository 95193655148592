// @flow
import { type DefaultTheme } from "../themes";

const tableFilters: (DefaultTheme) => * = (theme) => ({
  popoverPaper: {
    background: "#000",
    boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)",
    padding: "20px",
    marginTop: "10px",
    width: "auto",
  },
  filtersContainer: {
    ...theme.typography.bodyExtraSmallBold,
    alignItems: "flex-start",
    marginBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.bodyBold,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
      alignItems: "center",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "12px",
    },
    "& > :nth-child(1)": {
      display: "flex",
      marginRight: "8px",
      order: 2,
      [theme.breakpoints.up("md")]: {
        order: "unset",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "12px",
      },
    },
    "& > :nth-child(2)": {
      order: 3,
      [theme.breakpoints.up("md")]: {
        order: "unset",
      },
    },
    "& > :nth-child(3)": {
      marginLeft: "auto",
      marginBottom: "20px",
      order: 1,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: "unset",
        width: "auto",
        marginBottom: "0",
      },
    },
  },
  deleteAllBtn: {
    ...theme.typography.bodyExtraSmallBold,
    marginLeft: "12px",
    border: "none",
    padding: "8px 12px",
    color: theme.palette.text.primary,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    [theme.breakpoints.up("sm")]: {
      padding: "10px 12px",
      marginLeft: "20px",
      ...theme.typography.bodySmallBold,
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodyExtraSmallBold,
      padding: "8px 12px",
      marginLeft: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "8px 12px",
      marginLeft: "20px",
      ...theme.typography.bodySmallBold,
    },
    "&:hover": {
      border: "none",
      backgroundColor: theme.palette.transparent.main,
    },
  },
  chipsContainer: {
    gap: "8px",
    display: "flex",
    flexWrap: "noWrap",
    overflowX: "auto",
    marginRight: "12px",
    "& > :nth-child(1) .MuiChip-deleteIcon": {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      gap: "12px",
    },
  },
  filtersChips: {
    ...theme.typography.bodySmall,
    borderRadius: 0,
    maxWidth: "250px",
    padding: "8px 12px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "none",
    color: theme.palette.text.main,
    "&.MuiButtonBase-root": {
      ...theme.typography.bodySmall,
      [theme.breakpoints.up("sm")]: {
        ...theme.typography.body,
      },
      [theme.breakpoints.up("md")]: {
        ...theme.typography.bodySmall,
      },
      [theme.breakpoints.up("lg")]: {
        ...theme.typography.body,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.transparent.main,
    },
    "& .MuiChip-label": {
      padding: "0 8px 0 0",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      margin: 0,
      color: theme.palette.text.mutted,
      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
      },
    },
    height: "auto",
    ...theme.typography.body,
  },
});

export default tableFilters;
