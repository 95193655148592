// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";

type Props = {
  disabled?: boolean,
  disableApply?: boolean,
  onApply: () => *,
  sx?: {},
};

const RefreshButton: StatelessFunctionalComponent<Props> = ({
  disabled, disableApply, onApply, sx = {}, ...props
}) => (
  <Button
    {...props}
    disabled={disabled || disableApply}
    onClick={onApply}
    sx={{
      ...sx,
      padding: "8px",
      height: "40px",
      width: "40px",
      minWidth: "40px",
      color: "#fff",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    }}
    variant="transparent"
    data-testid="apply-btn"
  >
    <Refresh />
  </Button>
);

export default RefreshButton;
