/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Helmet } from "react-helmet";
import {
  SetupCard,
  withHookPropsState,
  withState,
  Table,
  type CardSetupProps,
} from "@fas/cpa-cabinet-ui";
import { withStyles } from "@mui/styles";
import { usePagination } from "@fas/cpa-state-manager/redux/hooks";
import { useSelector } from "react-redux";
import { getTableFields } from "@fas/cpa-state-manager/services/selectors/table";

import { setupCardStyles, tableReportStyles, paymentHistory } from "../../styles";
import { PAYMENT_HISTORY_TABLE } from "../../constants";
import {
  usePaymentHistoryActions,
  usePaymentHistory,
} from "../../hooks";

import Pagination from "../Pagination";
import PaymentHistoryActions from "../PaymentHistoryActions";
import type {
  Props,
  PaginationProps,
  ActionsProps,
  TableProps,
} from "./types/PaymentHistory.types";
import ReportSkeletonLoading from "../Report/ReportSkeletonLoading";
import NoDataTable from "./NoData";
import withNoData from "../WithNoData";

const TableActions: StatelessFunctionalComponent<ActionsProps> = withHookPropsState(
  PaymentHistoryActions,
  usePaymentHistoryActions
);
// $FlowFixMe
const PaymantHistoryPagination: StatelessFunctionalComponent<PaginationProps> = withHookPropsState(
  Pagination, (tableKey: string) => {
    const { isLoading } = usePaymentHistory();
    return {
      ...usePagination(tableKey),
      isLoading,
    };
  }
);
const ReportLoading: * = withHookPropsState(ReportSkeletonLoading, ({ tableKey }: { tableKey: string }): * => {
  const { isLoading } = usePaymentHistory();
  return {
    isLoading,
    fieldsLength: useSelector((state: *): * => getTableFields(state, tableKey)).length - 1,
  };
});

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledTableActions: StatelessFunctionalComponent<ActionsProps> = withStyles(paymentHistory)(TableActions);
const StyledTable: StatelessFunctionalComponent<TableProps> = withStyles(tableReportStyles)(Table);

const TableWithLoading: StatelessFunctionalComponent<TableProps> = withNoData(NoDataTable, StyledTable);

// $FlowFixMe
// eslint-disable-next-line max-len
const PaymentHistoryTable: StatelessFunctionalComponent<TableProps> = withState(TableWithLoading, usePaymentHistory);

const PaymantHistory: StatelessFunctionalComponent<Props> = () => (
  <StyledSetupCard title="Finance">
    <Helmet>
      <title>Finance</title>
    </Helmet>
    <StyledTableActions />

    <PaymentHistoryTable>
      <ReportLoading hookProps={{ tableKey: PAYMENT_HISTORY_TABLE }} />
    </PaymentHistoryTable>

    <PaymantHistoryPagination hookProps={PAYMENT_HISTORY_TABLE} />
  </StyledSetupCard>
);

export default PaymantHistory;
