// @flow
import { type DefaultTheme } from "../themes";

const popover: (DefaultTheme) => * = (theme) => ({
  paper: {
    marginTop: "12px",
    overflowY: "auto",
    background: "#000",
    boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)",
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "32px",
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      maxHeight: "100dvh",
      height: "100dvh",
      maxWidth: "100vw",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
});

export default popover;
