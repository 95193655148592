// @flow
import { type DefaultTheme } from "../themes";

const appNavBarMenuStyles: (DefaultTheme) => * = (theme) => ({
  listComponent: {
    flex: 1,
    padding: 0,
    marginBottom: "16px",
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.text.primary}!important`,
      color: theme.palette.text.dark,
      [theme.breakpoints.up("md")]: {
        boxShadow: "0px 8px 20px 0px rgba(221, 223, 75, 0.60)",
      },

      "& .MuiSvgIcon-root": {
        fill: `${theme.palette.text.dark}!important`,
      },
    },
  },
  menuItem: {
    color: theme.palette.text.main,
    padding: "8px",
    marginTop: "24px",
    "&:first-child": {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      padding: "14px 16px",
      marginTop: "4px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "16px",
      marginTop: "8px",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.05)",
      "& .MuiSvgIcon-root": {
        fill: theme.palette.text.primary,
      },
    },
  },
  menuItemIcon: {
    color: theme.palette.transparent.text,
    minWidth: "24px!important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "16px",
    },
  },
  menuItemText: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      marginLeft: "8px",
    },
    "& span": {
      ...theme.typography.bodySmallBold,
      textTransform: "uppercase",
      [theme.breakpoints.up("md")]: {
        ...theme.typography.bodySmallBold,
      },
      [theme.breakpoints.up("lg")]: {
        ...theme.typography.bodyBold,
      },
    },
  },
});

export default appNavBarMenuStyles;
