// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  Button,
  Box,
  Typography,
} from "@mui/material";
import { DateRangePicker } from "@fas/cpa-cabinet-ui";
import type { Props } from "./types/PaymentHistoryActions.types";
import DateRangeFooter from "../DateRangeFooter";
import DateInputComponent from "../ConfigureMainReport/DateInputComponent";
import { maxDate, minDate } from "../Reports/presets";
import { presets } from "./presets";
import FilterIcon from "../../icons/Filter";
import dateRangePickerStyles from "../../styles/dateRangePaper";
import ExportButton from "../Report/ExportButton";

const TableFilters: StatelessFunctionalComponent<Props> = ({
  isLoading,
  isLoadingExport,
  onApply,
  onExport,
  onChangeDate,
  isNoData,
  filters: {
    dateFrom,
    dateTo,
  },
  classes = {},
}) => (
  <Box className={classes.root}>
    <Box className={classes.exportContainer}>
      <Typography noWrap sx={{ display: { xs: "block", sm: "none" } }}>
        Export
      </Typography>
      <Typography noWrap sx={{ display: { xs: "none", sm: "block" } }}>
        Export invoices
      </Typography>
      <ExportButton
        onApply={onExport}
        disabled={isNoData || isLoading || isLoadingExport}
        isLoading={isLoadingExport}
      />
    </Box>
    <Box className={classes.filtersContainer}>
      <Box className={classes.dateRangeContainer}>
        <Typography noWrap>
          Date range
        </Typography>
        <DateRangePicker
          applyOnClose
          applyOnSelect
          onChangeDate={onChangeDate}
          onChangePreset={onChangeDate}
          startDate={String(dateFrom)}
          endDate={String(dateTo)}
          readOnly={false}
          format="YYYY-MM-DD"
          mask="9999-99-99 - 9999-99-99"
          presets={presets}
          minDate={minDate}
          maxDate={maxDate}
          data-testid="date-range-picker"
          disabled={isLoading}
          FooterComponent={DateRangeFooter}
          DateInputComponent={DateInputComponent}
          classes={dateRangePickerStyles()}
        />
      </Box>
      <Button
        className={classes.applyFiltersBtn}
        data-testid="apply-btn"
        variant="gradient"
        onClick={onApply}
        disabled={isLoading}
        startIcon={<FilterIcon />}
      >
        apply filters
      </Button>
    </Box>
  </Box>
);

export default TableFilters;
