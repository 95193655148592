// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Download: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 16L16.5 11H13.5V4H11.5V11H8.5L12.5 16Z" />
    <path d="M20.5 18H4.5V11H2.5V18C2.5 19.103 3.397 20 4.5 20H20.5C21.603 20 22.5 19.103 22.5 18V11H20.5V18Z" />
  </SvgIcon>
);

export default Download;
