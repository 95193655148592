// @flow
import React, { type Node } from "react";

const Logo: () => Node = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 188 24" fill="none">
    <path d="M5.93088 23.3467H10.1161V3.48H14.9056V0.67111H1.14136V3.48H5.93088V23.3467Z" fill="white" />
    <path d="M24.2967 0.67111C19.7175 0.67111 17.4884 3.03111 17.4884 7.88889V16.1111C17.4884 20.9778 19.7131 23.3467 24.2967 23.3467C28.8803 23.3467 31.105 20.9822 31.105 16.1111V7.88889C31.105 3.03111 28.8803 0.67111 24.2967 0.67111ZM26.9153 16.1156C26.9153 17.6533 26.7273 18.7422 26.3378 19.4356C25.926 20.1689 25.2412 20.5422 24.2967 20.5422C23.3522 20.5422 22.6674 20.1689 22.2555 19.4356C21.8661 18.7378 21.6781 17.6489 21.6781 16.1111V7.88889C21.6781 6.35111 21.8661 5.27111 22.2555 4.57778C22.6674 3.84889 23.3522 3.48 24.2967 3.48C25.2412 3.48 25.926 3.84889 26.3378 4.57778C26.7273 5.26667 26.9153 6.35111 26.9153 7.88889V16.1156Z" fill="white" />
    <path d="M41.0511 0.67111C36.4585 0.67111 34.2294 3.03111 34.2294 7.88889V16.1289C34.2294 20.9867 36.463 23.3467 41.0511 23.3467C42.2373 23.3467 43.4816 23.0933 44.7529 22.5911C45.9838 22.1067 47.0268 21.48 47.8594 20.7244V11.5911H41.5032V14.4H43.6741V19.7422L43.5756 19.8178C43.2892 20.0311 42.8997 20.2089 42.4253 20.3422C41.9553 20.4756 41.4942 20.5378 41.0555 20.5378C40.0976 20.5378 39.4083 20.1689 38.9965 19.44C38.6071 18.7467 38.4191 17.6667 38.4191 16.1289V7.88889C38.4191 6.35111 38.6071 5.27111 38.9965 4.57778C39.4083 3.84889 40.0976 3.48 41.0555 3.48C42.0135 3.48 42.667 3.82222 43.0833 4.50222C43.4816 5.15111 43.6741 6.15111 43.6741 7.56V7.98667H47.8594V7.56C47.8594 2.92444 45.6347 0.67111 41.0511 0.67111Z" fill="white" />
    <path d="M61.928 20.5422H55.169V12.8711H61.5789V10.0622H55.169V3.48H61.928V0.67111H50.9837V23.3467H61.928V20.5422Z" fill="white" />
    <path d="M69.8151 23.3467H74.0003V3.48H78.7854V0.67111H65.0255V3.48H69.8151V23.3467Z" fill="white" />
    <path d="M86.0995 0.67111H81.9142V23.3467H86.0995V12.8711H91.359V23.3467H95.5397V0.67111H91.359V10.0622H86.0995V0.67111Z" fill="white" />
    <path d="M110.768 20.5422H104.009V12.8711H110.423V10.0622H104.009V3.48H110.768V0.67111H99.8235V23.3467H110.768V20.5422Z" fill="white" />
    <path d="M123.946 23.3467H128.207C128.046 22.8578 127.965 22.1867 127.965 21.3111V17.0667C127.965 14.5956 127.039 13.1644 125.123 12.6844L124.237 12.4622L125.109 12.2044C126.085 11.9156 126.842 11.3689 127.365 10.5778C127.894 9.77778 128.158 8.75111 128.158 7.52444V5.84444C128.158 4.19111 127.558 2.94667 126.323 2.04C125.078 1.12889 123.288 0.666666 121.005 0.666666H115.043V23.3422H119.228V13.8533H120.803C121.887 13.8533 122.634 14.0756 123.091 14.5244C123.547 14.9778 123.771 15.7156 123.771 16.7822V21.5333C123.771 22.32 123.825 22.9289 123.937 23.3378L123.946 23.3467ZM123.306 10.3822C122.858 10.8311 122.106 11.0489 121.009 11.0489H119.232V3.48H121.009C122.115 3.48 122.867 3.69333 123.314 4.13778C123.758 4.57778 123.977 5.32444 123.977 6.41333V8.10667C123.977 9.19556 123.758 9.94222 123.306 10.3867V10.3822Z" fill="white" />
    <path d="M162.477 0.67111H156.68V23.3467H162.477C165.14 23.3467 167.127 22.7511 168.394 21.5733C169.656 20.4 170.292 18.5644 170.292 16.1111V7.88889C170.292 5.44889 169.652 3.61778 168.394 2.44444C167.127 1.26667 165.14 0.67111 162.477 0.67111ZM166.107 16.1156C166.107 17.7689 165.856 18.8889 165.341 19.5422C164.818 20.2044 163.855 20.5422 162.477 20.5422H160.87V3.48H162.477C163.855 3.48 164.818 3.81778 165.341 4.48C165.856 5.13333 166.107 6.24889 166.107 7.88889V16.1156Z" fill="white" />
    <path d="M183.636 11.4933L179.777 9.42667C178.501 8.72889 177.857 7.68444 177.857 6.31556C177.857 5.34667 178.031 4.65333 178.394 4.19555C178.761 3.72444 179.347 3.48444 180.131 3.48444C180.914 3.48444 181.55 3.72 181.917 4.18667C182.275 4.64 182.449 5.33778 182.449 6.31556V7.10667H186.635V5.55111C186.635 4.04444 186.071 2.88444 184.911 2C183.743 1.11555 182.136 0.666666 180.126 0.666666C178.117 0.666666 176.626 1.13333 175.435 2.05333C174.245 2.96889 173.667 4.11555 173.667 5.55111V6.93333C173.667 8.00444 174.016 9.03555 174.706 9.99111C175.404 10.96 176.402 11.8 177.678 12.4844L180.825 14.1778C182.056 14.8356 182.678 15.7867 182.678 17.0089V17.2622C182.678 18.4489 182.499 19.2667 182.123 19.7644C181.738 20.28 181.066 20.5422 180.126 20.5422C179.186 20.5422 178.578 20.2311 178.175 19.6222C177.79 19.04 177.602 18.1333 177.602 16.8622V16.1422H173.417V17.6178C173.417 19.4533 173.998 20.88 175.144 21.8622C176.304 22.8533 177.978 23.3556 180.126 23.3556C182.275 23.3556 184.003 22.8889 185.153 21.9733C186.303 21.0578 186.863 19.7644 186.863 18.0267V16.5333C186.863 14.3022 185.807 12.6533 183.631 11.4978L183.636 11.4933Z" fill="white" />
    <path d="M142.213 0.67111L137.285 23.3467H141.586L145.391 2.77778L149.196 23.3467H153.497L148.583 0.67111H142.213Z" fill="white" />
  </svg>
);

export default Logo;
