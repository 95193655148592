// @flow

import { type DefaultTheme } from "../themes";

const tabsSwitcher: (DefaultTheme) => * = (theme) => ({
  divider: {
    flexGrow: 1,
    border: "none",
    margin: "0 -10px 20px",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "20px",
      margin: "0 0 20px",
      borderBottom: `1px solid ${theme.palette.transparent.main}`,
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "20px",
    },
  },
});

export default tabsSwitcher;
