// @flow

import React, { type Node, type StatelessFunctionalComponent } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import ExportButton from "../Report/ExportButton";

type Props = {
  onClick: () => *,
  children: Node,
  Icon?: StatelessFunctionalComponent<*>,
  disabled?: boolean,
  isLoading?: boolean,
  visible?: boolean,
}

const AdditionalTabButton: StatelessFunctionalComponent<Props> = ({
  isLoading,
  onClick,
  children,
  disabled,
  Icon,
  visible = true,
}): Node => visible && (
  <Box
    sx={(): * => ({
      display: "flex",
      alignItems: "center",
      position: { xs: "absolute", sm: "static" },
      right: 0,
      top: 0,
      borderBottom: { xs: "none", sm: "1px solid rgba(255, 255, 255, 0.1)" },
      paddingBottom: { xs: "20px", md: "12px", lg: "20px" },
    })}
  >
    <Typography
      noWrap
      sx={(theme): * => ({
        fontSize: "14px",
        lineHeight: 1.428,
        marginRight: "12px",
        [theme.breakpoints.up("md")]: {
          ...theme.typography.body,
        },
      })}
    >
      {children}
    </Typography>
    <ExportButton
      onApply={onClick}
      disabled={disabled}
      isLoading={isLoading}
      Icon={Icon}
    />
  </Box>
);

export default AdditionalTabButton;
