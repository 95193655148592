// @flow
import { type DefaultTheme } from "../themes";

const managerInfo: (DefaultTheme) => * = (theme) => ({
  layout: {
    padding: "8px 16px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    [theme.breakpoints.up("sm")]: {
      padding: "20px 12px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px 12px",
    },
  },
  badge: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "0",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "8px",
    },
    "& > span": {
      display: "none",
    },
  },
  avatar: {
    border: "2px solid rgb(0, 0, 0)",
    boxShadow: "0px 0px 0px 2px rgba(218, 224, 227, 0.4)",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      width: "64px",
      height: "64px",
    },
  },

  name: {
    ...theme.typography.bodyExtraSmallBold,
    color: theme.palette.text.primary,
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    textAlign: "center",
    marginBottom: "4px",
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodySmallBold,
      color: theme.palette.text.main,
      marginBottom: "0",
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodyBold,
    },
  },
  title: {
    ...theme.typography.bodySmall,
    color: "rgba(255, 255, 255, 0.85)",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contacts: {
    "& .MuiIconButton-root": {
      padding: 0,
      margin: "0 16px 0",
      [theme.breakpoints.up("md")]: {
        margin: "8px 20px 0",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
      [theme.breakpoints.up("md")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
      },
    },
  },

  avatarLoading: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  titleLoading: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  nameLoading: {
    ...theme.typography.bodyExtraSmallBold,
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodySmallBold,
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodyBold,
    },
  },
  contactsLoading: {
    height: "20px",
  },
});

export default managerInfo;
