// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  withHookPropsState,
  type PaymentInfoFormComponent,
} from "@fas/cpa-cabinet-ui";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import type { Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import withForm from "@fas/ui-framework/lib/services/form/withForm";
import { Form } from "@fas/ui-core";
import PaymentInfoForm from "@fas/cpa-cabinet-ui/lib/PaymentInfoForm/PaymentInfoForm";
import { withStyles } from "@mui/styles";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import {
  ActivePaxumIcon,
  PaxumIcon,
  ActivePaypalIcon,
  PaypalIcon,
  WireTransferIcon,
  ActiveWireTransferIcon,
  PayoneerIcon,
  ActivePayoneerIcon,
} from "@fas/cpa-cabinet-ui/lib/icons";
import { LockOutlined } from "@mui/icons-material";
import TextFieldForm from "../TextFieldForm";
import { PAYMENT_METHOD_FORM } from "../../constants";
import type { UsePaymentInfoFormHook } from "../../hooks";
import type { PaymentInfoType } from "../../hooks/usePaymentInfoForm/usePaymentInfoForm";
import { paymentInfoFormStyles, paymentInfoCurrencyStyles } from "../../styles";
import type { Props as SelectProps } from "../SelectPopup/SelectPopup";
import { SelectPopupField } from "../SelectPopup/SelectPopup";
import Paxum from "./Paxum";
import PayPal from "./PayPal";
import WireTransfer from "./WireTransfer";
import Payoneer from "./Payoneer";

const DictionarySelectFieldForm: StatelessFunctionalComponent<
  ComponentProps<SelectProps, Dictionaries, UseDictionaryType>
> = withHookPropsState(SelectPopupField, useDictionary);

const FormContainer: StatelessFunctionalComponent<FormProps> = withForm(Form);

const components: PaymentInfoFormComponent = {
  Button: () => (
    <></>
  ),
  TextFieldForm: ({ disabled, ...props }) => (
    <TextFieldForm
      {...props}
      disabled={disabled}
      {...{
        ...(disabled ? { EndIcon: <LockOutlined /> } : {}),
        slotProps: {
          tooltip: {
            title: disabled && "For any updates in this field, please contact your account manager.",
          },
        },
      }}
    />
  ),
  DictionarySelectFieldForm: ({ disabled, ...props }) => (
    <DictionarySelectFieldForm
      disabled={disabled}
      {...props}
      {...{
        ...(disabled ? { EndIcon: <LockOutlined /> } : {}),
        slotProps: {
          tooltip: {
            title: disabled && "For any updates in this field, please contact your account manager.",
          },
        },
      }}
    />
  ),
  FormContainer,
};

type Props = UsePaymentInfoFormHook;

const StyledPaymentInfo = withStyles(paymentInfoFormStyles)(PaymentInfoForm);

const CurrenyLayout = withStyles(paymentInfoCurrencyStyles)(({ classes, currency, paymentMethodName }) => (
  <Box className={classes.root}>
    <Box className={classes.gridItem}>
      <Typography sx={{ marginRight: "10px" }}>Payment method: </Typography>
      <Typography fontWeight="bold">{paymentMethodName}</Typography>
    </Box>
    <Box className={classes.gridItem}>
      <Typography sx={{ marginRight: "10px" }}>Currency: </Typography>
      <Typography fontWeight="bold">{currency}</Typography>
    </Box>
  </Box>
));

const PaymentInfo: StatelessFunctionalComponent<Props> = () => {
  const {
    currency,
    paymentMethodName,
    paymentMethodId,
  }: PaymentInfoType = useSelector((state): PaymentInfoType => getFormData(state, PAYMENT_METHOD_FORM));

  return (
    <Box>
      <Helmet>
        <title>Payment info</title>
      </Helmet>

      <CurrenyLayout paymentMethodName={paymentMethodName} currency={currency} />

      <StyledPaymentInfo
        id={PAYMENT_METHOD_FORM}
        disabled
        components={components}
        paymentMethodId={Number(paymentMethodId)}
        mapMethods={{
          "1": {
            id: 1,
            label: "Wire Transfer",
            icon: <WireTransferIcon />,
            iconActive: <ActiveWireTransferIcon />,
            Form: WireTransfer,
          },
          "2": {
            id: 2,
            label: "Paxum",
            icon: <PaxumIcon />,
            iconActive: <ActivePaxumIcon />,
            Form: Paxum,
          },
          "9": {
            id: 9,
            label: "Payoneer",
            icon: <PayoneerIcon />,
            iconActive: <ActivePayoneerIcon />,
            Form: Payoneer,
          },
          "10": {
            id: 10,
            label: "PayPal",
            icon: <PaypalIcon />,
            iconActive: <ActivePaypalIcon />,
            Form: PayPal,
          },
        }}
      />
    </Box>
  );
};

export default PaymentInfo;
