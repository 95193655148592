// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { type DefaultTheme } from "../../themes";
import WarningIcon from "../../icons/WarningIcon";

type Props = {
  classes?: *,
};

const NoBestOffersData: StatelessFunctionalComponent<*> = withStyles((theme: DefaultTheme): * => ({
  container: {
    background: "rgba(255, 255, 255, 0.05)",
    padding: "20px",
  },
  header: {
    ...theme.typography.bodyBold,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      ...theme.typography.h6,
    },
  },
  text: {
    ...theme.typography.bodySmall,
    color: "rgba(255, 255, 255, 0.85)",
    marginTop: "8px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      ...theme.typography.body,
    },
  },
  icon: {
    fontSize: "60px",
    marginBottom: "12px",
    color: theme.palette.text.primary,

    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
      marginBottom: "0",
      marginRight: "12px",
    },
  },
}))(({ classes = {} }: Props): Element<*> => (
  <Box className={classes.container}>
    <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center">
      <WarningIcon className={classes.icon} />
      <Typography className={classes.header}>
        Sorry, no offers for you yet
      </Typography>
    </Box>
  </Box>
));

export default NoBestOffersData;
