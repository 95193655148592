// @flow
import { type DefaultTheme } from "../themes";

const tabletManagerInfo: (DefaultTheme) => * = (theme) => ({
  popover: {
    "& .MuiPopover-paper": {
      borderRadius: theme.shape.cardBorderRadius,
    },
  },
  name: {
    ...theme.typography.bodySmallBold,
    color: theme.palette.text.main,
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    textAlign: "center",
  },
  title: {
    ...theme.typography.bodySmall,
    color: "rgba(255, 255, 255, 0.85)",
    marginBottom: "4px",
  },

  contacts: {
    "& .MuiIconButton-root": {
      padding: 0,
      margin: "0 16px 0",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },
  },
  avatar: {
    width: "36px",
    height: "36px",
    border: "2px solid rgb(0, 0, 0)",
    boxShadow: "0px 0px 0px 2px rgba(218, 224, 227, 0.4)",
  },
  avatarLoading: {
    width: "36px",
    height: "36px",
  },
});

export default tabletManagerInfo;
