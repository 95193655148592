// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const LogoIcon: () => Node = () => (
  <SvgIcon viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 0C22.4332 0 0 22.4332 0 50C0 77.5668 22.4332 100 50 100C77.5668 100 100 77.5668 100 50C100 22.4332 77.5751 0 50 0ZM50 94.1781C25.6427 94.1781 5.83015 74.3656 5.83015 50.0083C5.83015 25.651 25.6427 5.83015 50 5.83015C74.3573 5.83015 94.1698 25.6427 94.1698 50C94.1698 74.3573 74.3573 94.1698 50 94.1698V94.1781Z" fill="#fff" />
    <path d="M52.6124 26.4555L42.4531 73.5445H51.3269L59.164 30.8343L67.0095 73.5445H75.8749L65.7489 26.4555H52.6124Z" fill="#fff" />
    <path d="M42.6356 32.2939H47.2218L48.474 26.4555H24.1251V32.2939H34.0023V73.5445H38.3148L42.6356 53.5412V32.2939Z" fill="#fff" />
  </SvgIcon>
);

export default LogoIcon;
