// @flow
import { type DefaultTheme } from "../themes";

const eventPostback: (DefaultTheme) => * = (theme) => ({
  labels: {
    display: "none",
  },
  container: {
    paddingTop: "8px",
    margin: 0,
    width: "100%",
    "& .MuiGrid-item": {
      padding: "0",
      [theme.breakpoints.up("sm")]: {
        "&:last-child": {
          flexBasis: "unset",
        },
      },
      "& + .MuiGrid-item": {
        marginTop: "20px",
      },
    },
    "& + .MuiGrid-container": {
      marginTop: "48px",
    },
    [theme.breakpoints.up("sm")]: {
      flexWrap: "noWrap",
      alignItems: "flex-end",
      "& + .MuiGrid-container": {
        marginTop: "20px",
      },
      "& .MuiGrid-item": {
        "& + .MuiGrid-item": {
          margin: "0 0 0 12px!important",
        },
      },
    },
    "& label": {
      display: "block!important",
    },
  },
  iconButton: {
    display: "none",
  },
  button: {
    ...theme.typography.mainButton,
    [theme.breakpoints.up("sm")]: {
      width: "160px",
    },
  },
  skeleton: {
    "& .MuiGrid-container .MuiGrid-container .MuiGrid-item:last-child": {
      [theme.breakpoints.up("sm")]: {
        width: "160px",
        flexShrink: 0,
      },
    },
    "& .MuiGrid-container .MuiGrid-item .MuiGrid-container": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
        "&:first-child": {
          display: "block",
        },
      },
    },
  },
  errorHelper: {
    marginLeft: theme.spacing(0),
  },
});

export default eventPostback;
