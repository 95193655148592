// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, type Element,
} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { useTheme, withStyles } from "@mui/styles";
import { DateRangePickerField } from "@fas/cpa-cabinet-ui/lib/Table/SearchComponent";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { reportColumnsMapMain as columnsMap } from "../Reports";
import Checkbox, { type Props as CheckboxProps } from "./Checkbox";
import { maxDate, minDate, presets } from "../Reports/presets";
import DateRangeFooter from "../DateRangeFooter";
import DateInputComponent from "./DateInputComponent";
import SelectPopup, { SelectPopupField } from "../SelectPopup/SelectPopup";
import type { Props as PropsSelect } from "../SelectPopup/SelectPopup";
import FieldMultiselect, { type Props as PropsTextFieldMultiselect } from "../TextFieldMultiselect";
import { useMemoryOptions, type UseMemoryOptionsHook } from "../../hooks/useMemoryOptions";
import { useDictionaryValueList } from "../../hooks/useDictionaryValueList";
import { useConfigureAccordion } from "../../hooks/useConfigureAccordion";
import { type UseConfigureReportHook } from "../../hooks/useConfigureReport";
import dateRangePickerStyles from "../../styles/dateRangePaper";
import { countrySelectStyles } from "../../styles";

type Props = {
  ...UseConfigureReportHook,
  onClose?: () => mixed,
  onApply?: () => mixed,
  onUpdatePosition?: () => mixed,
  open?: boolean,
};

const StyledAccordion: StatelessFunctionalComponent<*> = withStyles({})(
  withHookPropsState(Accordion, useConfigureAccordion)
);
const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<PropsSelect, Dictionaries, UseDictionaryType>
  > = withStyles(countrySelectStyles)(withHookPropsState(SelectPopup, useDictionaryValueList));

const groupByList: string[] = [
  "date",
  "country",
  "subId",
  "subId2",
];

const ConfigureMainReport: StatelessFunctionalComponent<Props> = ({
  pendingFilters,
  pendingFields,
  setFieldsFromPending,
  onChangePendingFields,
  setFiltersFromPending,
  onChangePendingFilters,
  onClose,
  onApply,
  onUpdatePosition,
}) => {
  const theme: * = useTheme();

  function isDisableChecked(key) {
    return pendingFields.length <= 2 && pendingFields.includes(key);
  }

  const getCheckboxProps: (string) => CheckboxProps = (key) => ({
    checked: pendingFields.includes(key),
    label: columnsMap[key].label,
    disabled: (pendingFilters.groupBy !== key && groupByList.includes(key)) || isDisableChecked(key),
    onChange: (checked: boolean) => {
      if (checked) {
        onChangePendingFields([...pendingFields, key]);
      }
      else {
        onChangePendingFields(pendingFields.filter((field: string): boolean => field !== key));
      }
    },
  });
  const columnsKeys: string[] = Object.keys(columnsMap);

  const handleApply: * = () => {
    setFiltersFromPending(pendingFilters);
    setFieldsFromPending(pendingFields);
    onApply && onApply();
    onClose && onClose();
  };

  const Select: StatelessFunctionalComponent<PropsSelect> = SelectPopupField;

  const TextFieldMultiselect: StatelessFunctionalComponent<
  ComponentProps<PropsTextFieldMultiselect, string, UseMemoryOptionsHook>
  > = withHookPropsState(FieldMultiselect, useMemoryOptions);

  const groupByOptions = groupByList.reduce((acc, option: string) => ([
    ...acc,
    {
      value: option,
      title: columnsMap[option].label,
    },
  ]), []);

  return (
    <>
      <Box sx={(): * => ({
        zIndex: 1,
        position: "sticky",
        top: theme.spacing(-1),
        padding: { xs: "8px 12px", sm: "0" },
        display: { xs: "flex", sm: "none" },
        justifyContent: "space-between",
        alignItems: "center",
      })}
      >
        <Typography variant="h5" color="text.main">Filter</Typography>
        <IconButton data-testid="close-btn" sx={{ padding: 0 }} onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: { xs: "12px", sm: "0" },
          background: { xs: theme.palette.transparent.main, sm: "transparent" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: { xs: "16px", md: "20px" },
          flex: "1",
        }}
      >
        <StyledAccordion
          hookProps={{
            name: "dateRangeMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Date range">
            <Typography>Date range</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DateRangePickerField
              applyOnClose
              applyOnSelect
              DateInputComponent={DateInputComponent}
              presets={presets}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(dateFilters: *): * => onChangePendingFilters({
                ...pendingFilters,
                ...dateFilters,
              })}
              value={pendingFilters.date}
              filters={pendingFilters}
              filterKey="date"
              data-testid="date"
              readOnly={false}
              format="YYYY-MM-DD"
              mask="9999-99-99 - 9999-99-99"
              FooterComponent={DateRangeFooter}
              classes={dateRangePickerStyles()}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "groupByMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Group by">
            <Typography>Group by</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              value={pendingFilters.groupBy}
              name="group"
              data-testid="group"
              options={groupByOptions}
              placeholder="Select Group By"
              defaultValue="date"
              onChange={(value: *): * => {
                onChangePendingFilters({
                  ...pendingFilters,
                  groupBy: value,
                });
                onChangePendingFields([
                  ...pendingFields.filter((field: string): boolean => (
                    field !== value && !groupByList.includes(field))),
                  // $FlowFixMe
                  value,
                ]);
              }}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "showColumnsMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Show columns">
            <Typography>Show columns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              sx={() => ({
                gap: "8px",
                "& label:hover:not(.Mui-disabled) .MuiTypography-root": {
                  color: theme.palette.text.primary,
                },
              })}
            >
              <Box sx={{
                gap: "8px", flex: 1, display: "flex", flexDirection: "column",
              }}
              >
                {columnsKeys
                  .slice(0, Math.ceil(columnsKeys.length / 2))
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Box>
              <Box sx={{
                gap: "8px", flex: 1, display: "flex", flexDirection: "column",
              }}
              >
                {columnsKeys
                  .slice(Math.ceil(columnsKeys.length / 2), columnsKeys.length)
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Box>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subIdMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid">
            <Typography>Subid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subIdMain"
              name="subId"
              placeholder="Input subId"
              placeholderInput="Input subId"
              value={pendingFilters.subId}
              onChange={(e: *): mixed => onChangePendingFilters({
                ...pendingFilters,
                subId: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subId2Main",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid2">
            <Typography>Subid2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subId2Main"
              name="subId2"
              placeholder="Input subId2"
              placeholderInput="Input subId2"
              value={pendingFilters.subId2}
              onChange={(e: *): mixed => onChangePendingFilters({
                ...pendingFilters,
                subId2: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "countryMain",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Country">
            <Typography>Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CountrySelect
              value={pendingFilters.country}
              name="country"
              placeholder="Select country(s)"
              onChange={(country: *): * => onChangePendingFilters({ ...pendingFilters, country })}
              hookProps="country"
              defaultValue={[]}
              isSplitListBoxColumn
            />
          </AccordionDetails>
        </StyledAccordion>

        <Box sx={(): * => ({
          zIndex: 1,
          position: "sticky",
          bottom: { xs: "0", sm: "-10px" },
          marginTop: { xs: "24px" },
        })}
        >
          <Button sx={() => ({ ...theme.typography.mainButton })} disabled={pendingFields.length < 2} data-testid="apply-btn" fullWidth onClick={handleApply}>
            Apply filters
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ConfigureMainReport;
