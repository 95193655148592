// @flow
import { createStyles, makeStyles } from "@mui/styles";

const dateRangePicker = makeStyles((theme) => createStyles({
  paper: {
    background: "#000",
    boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)",
    padding: "20px",
    marginTop: "10px",
    "& .MuiMenuItem-root": {
      padding: "6px 10px",
      ...theme.typography.bodySmall,
      color: "rgba(217, 217, 217, 0.70)",
    },
  },
}));

export default dateRangePicker;
