// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box, Button, Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import AuthLayout from "../AuthLayout";
import image from "../../icons/404.png";
import { page404Styles } from "../../styles";

type Props = {
  classes?: *,
};

const Image = styled("img")({});

const PageNotFound: StatelessFunctionalComponent<Props> = ({ classes = {} }) => (
  <>
    <Helmet>
      <title>Not found</title>
    </Helmet>
    <AuthLayout>
      <Box className={classes.root}>
        <Image alt="404" src={image} className={classes.image} />
        <Box className={classes.content}>
          <Typography className={classes.title}>
            Oops
          </Typography>
          <Typography className={classes.text}>Something went wrong. Please return to the homepage.</Typography>
        </Box>
        <Button
          component={Link}
          to="/"
          variant="standart"
          fullWidth
          className={classes.button}
        >
          go Home
        </Button>
      </Box>
    </AuthLayout>
  </>
);

export default withStyles(page404Styles)(PageNotFound);
