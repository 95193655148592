// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const OutstandingBalance: () => Node = () => (
  <SvgIcon width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" fontSize="16px">
    <path d="M6.74157 8C6.74157 11.1234 8.29306 13.8885 10.6755 15.5827C9.86363 15.8533 8.99407 16 8.08989 16C3.62195 16 0 12.4183 0 8C0 3.58171 3.62195 0 8.08989 0C8.99407 0 9.86363 0.14668 10.6755 0.417318C8.29306 2.11152 6.74157 4.8766 6.74157 8Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 8C24 12.4183 20.378 16 15.9101 16C11.4422 16 7.82022 12.4183 7.82022 8C7.82022 3.58171 11.4422 0 15.9101 0C20.378 0 24 3.58171 24 8ZM15.4027 13.0667V12.0551C14.9766 12.0136 14.5968 11.9012 14.263 11.7179C13.9293 11.5312 13.6683 11.2701 13.4802 10.9346C13.2955 10.5991 13.2068 10.1841 13.2139 9.68958L13.2245 9.66364H14.5293C14.5293 9.99219 14.5843 10.2585 14.6944 10.4625C14.808 10.6631 14.9607 10.8101 15.1524 10.9035C15.3441 10.9934 15.5607 11.0383 15.8021 11.0383C16.0542 11.0383 16.269 10.9968 16.4465 10.9138C16.624 10.8274 16.7589 10.7063 16.8512 10.5507C16.9471 10.3951 16.995 10.2101 16.995 9.99564C16.995 9.79854 16.9524 9.62562 16.8672 9.47689C16.7856 9.32819 16.6524 9.19333 16.4678 9.07227C16.2867 8.95124 16.0453 8.83711 15.7435 8.72988C15.2394 8.56045 14.8187 8.3737 14.4814 8.16966C14.1476 7.96559 13.8973 7.72005 13.7305 7.43301C13.5672 7.14596 13.4855 6.80013 13.4855 6.39551C13.4855 5.81797 13.6666 5.3459 14.0287 4.97933C14.3908 4.61273 14.8843 4.39486 15.5092 4.32568V3.2H16.3507V4.34124C16.9613 4.4277 17.4388 4.67845 17.7832 5.09346C18.1276 5.50846 18.298 6.05485 18.2945 6.73272L18.2519 6.74828H16.9684C16.9684 6.3125 16.8708 5.97015 16.6755 5.72113C16.4838 5.47214 16.2193 5.34762 15.882 5.34762C15.5269 5.34762 15.2607 5.44274 15.0832 5.63294C14.9092 5.81969 14.8222 6.07217 14.8222 6.39033C14.8222 6.58747 14.8613 6.76038 14.9394 6.90908C15.0175 7.05433 15.1488 7.18574 15.3335 7.30332C15.5181 7.4209 15.7719 7.53678 16.095 7.65088C16.5992 7.83073 17.0163 8.02438 17.3465 8.2319C17.6803 8.43939 17.9288 8.6832 18.0921 8.96335C18.2554 9.24346 18.3371 9.58412 18.3371 9.98529C18.3371 10.5801 18.1489 11.0574 17.7726 11.417C17.3998 11.7732 16.8903 11.9842 16.2441 12.0499V13.0667H15.4027Z" />
  </SvgIcon>
);

export default OutstandingBalance;
