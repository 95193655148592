/* eslint-disable import/max-dependencies */
// @flow
import environment from "environment";
import React, { useCallback, useEffect, type Node } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import FileSaver from "file-saver";
import { addNotification } from "@fas/ui-framework";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import {
  changeTableFilters,
  changeTableSorting,
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import {
  getTableFilters,
  getTableSorting,
  getTablePage,
  getTablePageSize,
  getTableFields,
  getTableData,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  changeTableItemsTotalAmount,
  setTableData,
} from "@fas/cpa-state-manager/redux/actions/table";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  PAYMENT_HISTORY_TABLE,
  PERIOD_STATE_TO_PAID,
  PERIOD_STATE_TO_STATUS_MAPPING,
  PERIOD_STATE_REFUND,
} from "../../constants";
import {
  formatCurrency,
  getDownloadFileNamePdf,
} from "../../utils";
import type { PaymentHistoryData } from "../../components/PaymentHistory";
import Download from "../../icons/Download";
import api, { getAuthorizationHeaders } from "../../services/api";

type PaymentHistoryColumnData = PaymentHistoryData & { invoice: * };
export type UsePaymentHistory = {
  columns: Column<PaymentHistoryColumnData>[],
  sorting: Sorting,
  isLoading?: boolean,
  isLoadingExport: boolean,
  data: PaymentHistoryData[],
  refetch: () => void,
};

// eslint-disable-next-line max-len
export const getColumns: ((id: number) => mixed, isLoadingExport: boolean) => Column<PaymentHistoryColumnData>[] = (onExport, isLoadingExport) => [
  {
    field: "created",
    label: "Created",
    sortable: true,
  },
  {
    field: "dateFrom",
    label: "Period from",
    sortable: true,
  },
  {
    field: "dateTo",
    label: "Period to",
    sortable: true,
  },
  {
    field: "paidTotal",
    label: "Paid total",
    sortable: true,
    render: (({ currency, paidTotal }: PaymentHistoryData): Node => (
      <span key={`${currency}-${paidTotal}`}>
        {formatCurrency(currency, paidTotal)}
      </span>
    )),
  },
  {
    field: "paidAt",
    label: "Paid on",
    sortable: true,
  },
  {
    field: "stateId",
    label: "Status",
    sortable: true,
    render: (({ stateId }: PaymentHistoryData): Node => (
      <span>
        {PERIOD_STATE_TO_STATUS_MAPPING[stateId]}
      </span>
    )),
  },
  {
    field: "invoice",
    label: "Invoice",
    render: (({ id, stateId }: PaymentHistoryData): Node => (
      (stateId === PERIOD_STATE_TO_PAID || stateId === PERIOD_STATE_REFUND) && (
        <IconButton
          disabled={isLoadingExport}
          sx={{ padding: 0 }}
          size="small"
          onClick={() => {
            onExport(id);
          }}
        >
          <Download />
        </IconButton>
      ))),
  },
];

export type RequestBody = {
  filters: { [string]: * },
  sorting: { current: string, direction: mixed },
  fields: Array<string>,
  page: number,
  limit: number,
}

export const usePaymentHistory: () => UsePaymentHistory = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const tableData: PaymentHistoryData[] = useSelector((state: *) => getTableData(state, PAYMENT_HISTORY_TABLE));
  const filters: Filters = useSelector((state: *) => getTableFilters(state, PAYMENT_HISTORY_TABLE));
  const sorting: Sorting = useSelector((state: *) => getTableSorting(state, PAYMENT_HISTORY_TABLE));
  const page: number = useSelector((state: *) => getTablePage(state, PAYMENT_HISTORY_TABLE));
  const limit: number = useSelector((state: *) => getTablePageSize(state, PAYMENT_HISTORY_TABLE));
  const fields: string[] = useSelector((state: *) => getTableFields(state, PAYMENT_HISTORY_TABLE));
  const [current = "created", direction = "desc"]: [string, mixed] = Object.entries(sorting)[0] || [];
  const { dateFrom: from, dateTo: to }: Filters = filters;

  const requestBody: RequestBody = {
    filters: { created: [{ from, to }] },
    sorting: { current, direction },
    fields,
    page,
    limit,
  };

  const {
    isLoading,
    isFetching,
    data,
    refetch,
  } = useQuery(["apiPayoutHistory", requestBody], () => api.apiPayoutHistory(requestBody));

  useEffect(() => {
    if (data?.data) {
      dispatch(setTableData(PAYMENT_HISTORY_TABLE, data.data));
      dispatch(changeTableItemsTotalAmount(PAYMENT_HISTORY_TABLE, data.count));
    }
  }, [data, dispatch]);

  const { mutate, isLoading: isLoadingExport } = useMutation({
    mutationKey: ["apiPayoutHistoryExport"],
    mutationFn: (id: string) => fetch(environment.endpoints.post.payoutHistoryExport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(),
      },
      body: JSON.stringify({ filters: { id } }),
    }),
    onSuccess: async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      const blob = await response.blob();
      const filename = getDownloadFileNamePdf(response.headers);

      FileSaver.saveAs(blob, filename);
      dispatch(addNotification({ message: "Successfully downloaded", severity: "success" }));
    },
    onError: (e: { message: string }) => {
      dispatch(addNotification({ message: e.message || "Something went wrong...", severity: "error" }));
    },
  });

  const onExportSingle = (id: number) => {
    mutate(id);
  };

  const columns: Column<PaymentHistoryColumnData>[] = getColumns(onExportSingle, isLoadingExport);
  const isLoadingTable: boolean = isLoading || isFetching;

  const onChangeFilters: (Filters) => mixed = useCallback(
    (newFilters: Filters): mixed => dispatch(changeTableFilters(PAYMENT_HISTORY_TABLE, newFilters)),
    [dispatch, PAYMENT_HISTORY_TABLE]
  );

  const onChangeSorting: (Sorting) => mixed = useCallback(
    (newSorting: Sorting): mixed => dispatch(changeTableSorting(PAYMENT_HISTORY_TABLE, newSorting)),
    [dispatch, PAYMENT_HISTORY_TABLE]
  );

  return {
    columns,
    sorting,
    onChangeFilters,
    onChangeSorting,
    isLoading: isLoadingTable,
    isLoadingExport: isLoadingTable || isLoadingExport,
    refetch,
    data: (isLoadingTable) ? [] : tableData,
  };
};
