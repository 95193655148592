// @flow
import React, { type StatelessFunctionalComponent, type Node, useState } from "react";
import { Button, Popover } from "@mui/material";
import { withStyles } from "@mui/styles";
import Edit from "../../icons/edit";
import type { UseState } from "./types/EditReportButton.types";
import { popoverStyles } from "../../styles";

type Props = {
  children?: Node,
  disabled?: boolean,
};

const StyledPopover: StatelessFunctionalComponent<*> = withStyles(popoverStyles)(Popover);

const EditReportButton: StatelessFunctionalComponent<Props> = ({ children, disabled }) => {
  const [anchorEl, setAnchorEl]: UseState<HTMLButtonElement | null> = useState(null);
  const popoverActions = React.useRef();

  const handleClick: (SyntheticMouseEvent<HTMLButtonElement>) => void = (e: SyntheticMouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose: () => void = () => {
    setAnchorEl(null);
  };

  const open: boolean = Boolean(anchorEl);
  const childrenWithProps: * = React.Children.map(children, (child: *): * => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClose: handleClose,
        open,
        onUpdatePosition: () => popoverActions.current?.updatePosition(),
      });
    }
    return child;
  });
  return (
    <>
      <Button
        sx={(theme) => ({
          ...theme.typography.mainButton,
          flex: 1,
          "& .MuiSvgIcon-root": {
            fontSize: "24px!important",
          },
        })}
        variant="outlined"
        onClick={handleClick}
        disabled={disabled}
        startIcon={<Edit />}
        data-testid="edit-report-btn"
      >
        Edit Report
      </Button>
      <StyledPopover
        action={popoverActions}
        marginThreshold={0}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {childrenWithProps}
      </StyledPopover>
    </>
  );
};

export default EditReportButton;
