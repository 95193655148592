// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Button, CircularProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import DownloadIcon from "../../icons/Download";

import type { DefaultTheme } from "../../themes";

type Props = {
  disabled?: boolean,
  onApply: () => *,
  Icon?: StatelessFunctionalComponent<*>,
  isLoading?: boolean,
  classes?: *,
};

const ExportButton: StatelessFunctionalComponent<Props> = ({
  disabled,
  onApply,
  Icon = DownloadIcon,
  classes = {},
  isLoading,
  ...props
}) => (
  <Button
    {...props}
    variant="transparent"
    onClick={onApply}
    data-testid="export-btn"
    className={classes.root}
    disabled={disabled}
  >
    {isLoading ? <CircularProgress size={24} /> : <Icon />}
  </Button>
);

export default withStyles((theme: DefaultTheme): * => ({
  root: {
    padding: "8px",
    height: "40px",
    width: "40px",
    minWidth: "40px",
    color: theme.palette.text.main,
    backgroundColor: "rgba(255, 255, 255, 0.05)",
  },
}))(ExportButton);
