// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Filter: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 3H5C4.73478 3 4.48043 3.10536 4.29289 3.29289C4.10536 3.48043 4 3.73478 4 4V6.59C4 7.113 4.213 7.627 4.583 7.997L10 13.414V21C10.0002 21.1704 10.0439 21.3379 10.1269 21.4867C10.2099 21.6354 10.3295 21.7605 10.4744 21.8502C10.6193 21.9398 10.7847 21.9909 10.9549 21.9988C11.1251 22.0066 11.2945 21.9709 11.447 21.895L15.447 19.895C15.786 19.725 16 19.379 16 19V13.414L21.417 7.997C21.787 7.627 22 7.113 22 6.59V4C22 3.73478 21.8946 3.48043 21.7071 3.29289C21.5196 3.10536 21.2652 3 21 3ZM14.293 12.293C14.2 12.3857 14.1262 12.4959 14.0759 12.6172C14.0256 12.7386 13.9998 12.8687 14 13V18.382L12 19.382V13C12.0002 12.8687 11.9744 12.7386 11.9241 12.6172C11.8738 12.4959 11.8 12.3857 11.707 12.293L6 6.59V5H20.001L20.003 6.583L14.293 12.293Z" />
  </SvgIcon>
);

export default Filter;
