// @flow
import { autocompleteClasses } from "@mui/material";
import { type DefaultTheme } from "../themes";

const countrySelect: (DefaultTheme) => * = (theme) => ({
  popper: {
    [`& .${autocompleteClasses.paper} .${autocompleteClasses.listbox}`]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "8px 20px",

      [`& .${autocompleteClasses.option}`]: {
        margin: 0,
        padding: 0,
        minHeight: "unset",
        fontSize: "14px",
        lineHeight: "1.428",
        "&.Mui-focused": {
          color: theme.palette.text.primary,
          background: "inherit",
        },
        "&.MuiAutocomplete-option[aria-selected=\"true\"]": {
          background: "inherit",
          color: "inherit",
        },
        "& .MuiCheckbox-root": {
          display: "inherit",
        },
      },
    },
  },
});

export default countrySelect;
