// @flow
import { type DefaultTheme } from "../themes";
import formGridStyles from "./formGrid";

const paymentInfoCurrencyStyles: (DefaultTheme) => * = (theme) => ({
  root: {
    ...formGridStyles(theme).root,
    gridGap: "20px",
    marginBottom: "28px",
    marginTop: "0",
    gridGap: "8px 40px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: "8px 20px",
    },
    [theme.breakpoints.up("lg")]: {
      gridGap: "8px 40px",
    },
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
});

export default paymentInfoCurrencyStyles;
