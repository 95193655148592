// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Typography,
  Chip, Box,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { howToChipsStyles } from "../../styles";
import type { HowToItem } from "./types/HowToCreatePostback.types";

const items: HowToItem[] = [
  {
    label: ["{payout}"],
    example: "Amount paid to you for conversion",
  },
  {
    label: ["{country}"],
    example: "Country name of transaction",
  },
  {
    label: ["{platform}"],
    example: "Platform of transaction",
  },
  {
    label: ["{clickid}"],
    example: "Your click/transaction ID (You can enter maximum 256 characters)",
  },
  {
    label: ["{Date}", "{Time}", "{DateTime}"],
    example: "Datetime of transaction",
  },
  {
    label: ["{subid}"],
    example: "Additional parameter (to mark your sources/any additional information)",
  },
  {
    label: ["{subid2}"],
    example: "Your own additional parameter 3",
  },
  {
    label: ["{conversionType}"],
    example: "Transaction type (\"lead\", \"sale\", \"install\", \"confirm\")",
  },
  {
    label: ["{tds_cid}"],
    example: "Internal click_id (You can enter maximum 40 characters containing letters and numbers)",
  },
  {
    label: ["{payout_in_currency}"],
    example: "Amount paid to you for conversion in offer currency",
  },
  {
    label: ["{currency}"],
    example: "Offer currency (\"USD\", \"EUR\", \"GBP\" ...)",
  },
];

const StyledItem: StatelessFunctionalComponent<HowToItem> = withStyles(howToChipsStyles)(({
  label, example, classes = {},
}: HowToItem): Node => (
  <Box className={classes.container}>
    <Box className={classes.chips}>
      {label.map((labelItem: string): Node => <Chip label={labelItem} key={labelItem} />)}
    </Box>
    <Box className={classes.labels}>
      <Typography component="span" className={classes.label}>
        {example}
      </Typography>
    </Box>
  </Box>
));

const HowToCreatePostback: StatelessFunctionalComponent<{}> = () => (
  <Box direction="column" sx={{ marginTop: { xs: "40px", md: "80px" } }}>
    <Box>
      <Typography variant="h6" sx={{ marginBottom: "12px" }}>
        How to create own postback URLs
      </Typography>
    </Box>
    <Box sx={{ color: "text.mutted" }}>
      <Typography>
        Here you can create your own postback URLs that will be sent any time you have a new transaction.
      </Typography>
      <Typography>
        The example of postback URL:
        <Typography component="span" sx={{ display: "inline", marginLeft: "10px", color: "text.primary" }}>
          {"http://your-domain.com/tracking.php?payout={payout}&clickid={clickid}"}
        </Typography>
      </Typography>
      <Typography>
        After creating the postback URL, ask your manager to approve it. If the code is not approved, it will not work.
      </Typography>
    </Box>
    <Box sx={{ marginTop: "20px", display: { md: "flex" } }}>
      <Box sx={{ marginRight: { md: "40px" }, flex: "1" }}>
        {items.slice(0, 6).map((item: HowToItem): Node => <StyledItem key={item.example} {...item} />)}
      </Box>
      <Box sx={{ flex: "1" }}>
        {items.slice(6).map((item: HowToItem): Node => <StyledItem key={item.example} {...item} />)}
      </Box>
    </Box>
  </Box>
);

export default HowToCreatePostback;
