// @flow
import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import type { StatelessFunctionalComponent } from "react";

type Props = {
  hide: boolean,
  label: string,
  dataKey: string,
  onChange: (string, boolean) => mixed,
}

const LegendItem: StatelessFunctionalComponent<Props> = ({
  hide, label, dataKey, onChange,
}: Props) => (
  <FormControlLabel
    sx={{ margin: { sm: "0 20px 0 0" } }}
    control={(
      <Checkbox
        size="small"
        onChange={(): * => onChange(dataKey, !hide)}
        checked={!hide}
        data-testid={dataKey}
        sx={{ padding: "4px", marginRight: "4px" }}
      />
    )}
    label={(
      <Typography
        variant="responsiveButton"
        sx={{
          textTransform: "uppercase", fontWeight: "700", fontSize: { xs: "12px", lg: "16px" }, lineHeight: { xs: "16px", lg: "24px" },
        }}
      >
        {label}
      </Typography>
    )}
  />
);

export default LegendItem;
