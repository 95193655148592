// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { formatCurrency } from "../../utils";
import OutstandingBalanceMobIcon from "../../icons/OutstandingBalanceMobIcon";

type Props = {
  balance: number,
  isLoading?: boolean,
  disabled?: boolean,
};

const OutstandingBalance: StatelessFunctionalComponent<Props> = ({ balance, isLoading, disabled }) => (
  <Box
    sx={{
      padding: "8px 16px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "rgba(255, 255, 255, 0.05)",
    }}
    data-testid="outstanding_balance_info-container"
  >
    <Box display="flex" alignItems="center">
      <OutstandingBalanceMobIcon />
      <Typography data-testid="title" variant="bodyExtraSmallBold" sx={{ marginLeft: "4px" }}>Balance</Typography>
    </Box>
    <Box width="100%" display="flex" justifyContent="center" sx={{ marginTop: "4px" }}>
      { isLoading ? (
        <Skeleton height={18} width="50%" />
      ) : (
        <Typography
          data-testid="balance"
          variant="bodyExtraSmallBold"
          sx={{
            color: disabled ? "transparent.disabled" : "text.main",
          }}
        >
          {formatCurrency("USD", balance)}
        </Typography>
      )}
    </Box>
  </Box>
);

export default OutstandingBalance;
