// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const ChatBar: () => Node = () => (
  <SvgIcon viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M53.3333 33.333V140H73.3333V33.333H53.3333ZM20 86.6663V140H40V86.6663H20ZM106.667 53.333V140H86.6667V53.333H106.667ZM120 73.333V140H140V73.333H120Z" />
  </SvgIcon>
);

export default ChatBar;
