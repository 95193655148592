/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import { LOGIN_FORM } from "@fas/cpa-state-manager/redux/constants";
import { withStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import { Typography, Button, Box } from "@mui/material";
import { EmailOutlined, LockOpenOutlined } from "@mui/icons-material";
import { withState } from "@fas/cpa-cabinet-ui";
import TextField, { type TextFieldFormProps } from "../TextFieldForm";
import AuthLayout from "../AuthLayout";
import { usePasswordField, type UsePasswordFieldHook } from "../../hooks";
import schema from "./validation";
import { getValidation } from "../../utils";
import { autologinStyles } from "../../styles";

const LoginForm: StatelessFunctionalComponent<FormProps> = withForm(Form);
const PasswordField: StatelessFunctionalComponent<$Diff<TextFieldFormProps, UsePasswordFieldHook>> = withState(
  TextField,
  usePasswordField
);

type Props = {
  isLoading: boolean,
  onKeyPress: (SyntheticKeyboardEvent<*>) => mixed,
  onSave: () => mixed,
  classes?: *,
};

const Login: StatelessFunctionalComponent<Props> = ({
  isLoading,
  onKeyPress,
  onSave,
  classes = {},
}) => (
  <>
    <Helmet>
      <title>Login</title>
      <meta name="description" content="Our system will find the best offers to monetize 100% of your traffic. Earn from any traffic source and any traffic mix in any volume with one of the best CPA networks." />
    </Helmet>
    <AuthLayout>
      <Box className={classes.root}>
        <LoginForm id={LOGIN_FORM} validate={getValidation(schema)} onSave={onSave}>
          <Typography variant="h5" className={classes.title}>
            Log in
          </Typography>
          <TextField
            className={classes.field}
            name="login"
            label="Email"
            placeholder="Input your email"
            autoFocus
            StartIcon={<EmailOutlined />}
            onKeyPress={onKeyPress}
            inputProps={{ inputMode: "email" }}
          />
          <PasswordField
            className={classes.field}
            name="password"
            label="Password"
            placeholder="Input your password"
            StartIcon={<LockOpenOutlined />}
            onKeyPress={onKeyPress}
          />
          <Button
            className={classes.button}
            disabled={isLoading}
            data-testid="save-login"
            fullWidth
            variant="standart"
            type="submit"
          >
            Log in
          </Button>
        </LoginForm>
      </Box>
    </AuthLayout>
  </>
);

export default withStyles(autologinStyles)(Login);
