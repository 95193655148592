// @flow
/* eslint-disable import/max-dependencies */
import {
  getTableFilters,
  getTablePendingFields,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  changeTableFilters,
  changeTablePage,
} from "@fas/cpa-state-manager/redux/actions/table";
import { useSelector, useDispatch } from "react-redux";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import ToolTip from "../../components/Reports/Tooltip";
import { isEqualPreviousBuilder } from "./utils";
import { useReportTable, type UseReportTableHook } from "../useReportTable";
import { useReportExport, type UseExportReportHook } from "../useReportExport";

export type TableResponse<T> = {
  data: T,
  headers: *,
};

export type UseReportActionsHookArgs = {|
  tableKey: string,
|};

export type UseReportActionsHook = {
  isLoading?: boolean,
  disableApply?: boolean,
  filters: *,
  onChange: (*) => mixed,
  onApply: () => mixed,
  onDeleteAll: () => mixed,
  onDelete: (*) => mixed,
};

const tablesCompareCache: { [string]: (*) => boolean } = {};

export function isEqualPrevious(tableKey: string, data: *): boolean {
  tablesCompareCache[tableKey] = tablesCompareCache[tableKey] || isEqualPreviousBuilder();
  return tablesCompareCache[tableKey](data);
}

const defaultSorting: Sorting = { "": "asc" };
export function getAllowedSorting(sorting: Sorting, fields: string[]): Sorting {
  const [[current = ""] = []]: * = Object.entries(sorting);
  return fields.includes(current) ? sorting : defaultSorting;
}

function getClearFilters(tableFilters: Filters): Filters {
  const canClear: (string) => boolean = (key: string): boolean => !["date", "groupBy"].includes(key);
  return Object.keys(tableFilters).reduce((acc: Filters, key: string): Filters => ({
    ...acc,
    [key]: canClear(key) ? "" : tableFilters[key],
  }), {});
}

export const useReportActions: (UseReportActionsHookArgs) => UseReportActionsHook = ({ tableKey }) => {
  const dispatch: <A>(A) => A = useDispatch();

  const { isLoading, refetch }: UseReportTableHook = useReportTable({ tableKey });
  const { isLoading: isLoadingExport }: UseExportReportHook = useReportExport(tableKey);

  const tableFilters: Filters = useSelector((state: *): Filters => getTableFilters(state, tableKey));
  const pendingFields: string[] = useSelector((state: *): string[] => getTablePendingFields(state, tableKey));

  const filters = Object.keys(tableFilters)
    .filter((key) => !["groupBy"].includes(key) && isValidFilter(tableFilters[key]))
    .reduce((acc, key) => ({ ...acc, [key]: tableFilters[key] }), {});

  const onApply = () => {
    dispatch(changeTablePage(tableKey, 1));
    refetch();
  };

  const onChange = (newFilters) => {
    dispatch(changeTableFilters(tableKey, {
      ...tableFilters,
      ...newFilters,
    }));
    dispatch(changeTablePage(tableKey, 1));
  };

  function isValidFilter(filter): boolean {
    return Array.isArray(filter) ? !!filter.length : !!filter;
  }

  return {
    isLoading: isLoading || isLoadingExport,
    disableApply: pendingFields.length < 2,
    filters,
    onApply,
    onChange: (newFilters) => {
      dispatch(changeTableFilters(tableKey, {
        ...tableFilters,
        ...newFilters,
      }));
      dispatch(changeTablePage(tableKey, 1));
    },
    onDeleteAll: () => {
      onChange(getClearFilters(tableFilters));
    },
    onDelete: ({ date, ...newFilters }) => {
      onChange({
        ...tableFilters,
        ...newFilters,
        ...(date ? { date } : {}), // don't clear require filter
      });
    },
    WrapChipLabel: ToolTip,
  };
};
