// @flow
import { type DefaultTheme } from "../themes";

const reportTable: (DefaultTheme) => * = (theme) => ({
  tableContainer: {
    minHeight: "auto",
    [theme.breakpoints.up("md")]: {
      minHeight: "60px",
    },
  },
  table: {
    minWidth: "fit-content",
    borderSpacing: "0 8px",
    borderCollapse: "separate",

    "&[data-empty=\"true\"] > thead": {
      display: "none",
    },
    "& thead": {
      zIndex: 1,
      position: "sticky",
      top: 0,
      background: "#1a1a1a",
    },
  },
  row: {
    background: "rgba(255, 255, 255, 0.05)",
    backgroundBlendMode: "color-dodge",
    rowSpacing: "8px",

    "&.MuiTableRow-hover:hover": {
      backgroundColor: "rgba(221, 223, 75, 0.15)",
    },
  },
  cell: {
    ...theme.typography.bodySmall,
    color: theme.palette.text.main,
    border: "none",
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "0 8px",
    height: "32px",
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.body,
      height: "40px",
    },
  },
  headerRow: {
    background: "transparent",
  },
  headerCell: {
    ...theme.typography.bodyExtraSmallBold,
    color: theme.palette.text.mutted,
    padding: "0 8px",
    textAlign: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodyBold,
      height: "auto",
    },
    "& .MuiTableSortLabel-root": {
      "&:hover .MuiTableSortLabel-icon": {
        opacity: 0.7,
      },
      "&.Mui-active": {
        color: theme.palette.text.mutted,
      },
      "&.Mui-active .MuiTableSortLabel-icon": {
        color: theme.palette.text.primary,
      },
    },
  },

});

export default reportTable;
