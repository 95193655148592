// @flow
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { getTableFields, getTablePendingFields } from "@fas/cpa-state-manager/services/selectors/table";
import {
  getTableFilters,
  getTablePendingFilters,
  getTableSorting,
  type StoreWithTable,
} from "@fas/cpa-state-manager/services/selectors";
import {
  setTablePendingFields,
  changeTableFilters,
  changeTablePendingFilters,
  changeTablePage,
  setTableFields,
  changeTableSorting,
} from "@fas/cpa-state-manager/redux/actions/table";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";

export type UseConfigureReportHookArgs = {|
  tableKey: string,
|};

export type UseConfigureReportHook = {
  filters: Filters,
  pendingFilters: Filters,
  fields: string[],
  pendingFields: string[],
  setFiltersFromPending: (Filters) => mixed,
  onChangePendingFilters: (Filters) => mixed,
  setFieldsFromPending: (string[]) => mixed,
  onChangePendingFields: (string[]) => mixed,
};

export const useConfigureReport: (UseConfigureReportHookArgs) => UseConfigureReportHook = ({ tableKey }) => {
  const dispatch: <A>(A) => A = useDispatch();
  const filters: Filters = useSelector((state: StoreWithTable): Filters => getTableFilters(state, tableKey));
  const pendingFilters: Filters = useSelector((state: StoreWithTable):
   Filters => getTablePendingFilters(state, tableKey));
  const fields: string[] = useSelector((state: *): string[] => getTableFields(state, tableKey));
  const pendingFields: string[] = useSelector((state: *): string[] => getTablePendingFields(state, tableKey));
  const sorting: Sorting = useSelector((state: *) => getTableSorting(state, tableKey));
  const [current = ""] = (Object.entries(sorting)[0]) || [];

  const onChangePendingFilters: (Filters) => mixed = (newFilters) => {
    dispatch(changeTablePendingFilters(tableKey, newFilters));
  };

  const setFiltersFromPending: (Filters) => mixed = useCallback((newFilters): mixed => {
    dispatch(changeTableFilters(tableKey, newFilters));
    dispatch(changeTablePage(tableKey, 1));
  },
  [dispatch, tableKey]);

  const onChangePendingFields: (string[]) => mixed = (newFields) => {
    dispatch(setTablePendingFields(tableKey, newFields));
  };

  const setFieldsFromPending: (string[]) => mixed = (newFields) => {
    if (current && !pendingFields.includes(current)) {
      dispatch(changeTableSorting(tableKey, {}));
    }
    dispatch(setTableFields(tableKey, newFields));
  };

  return {
    filters,
    pendingFilters,
    fields,
    pendingFields,
    setFiltersFromPending,
    onChangePendingFilters,
    setFieldsFromPending,
    onChangePendingFields,
  };
};
