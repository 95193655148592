// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Container } from "@mui/material";
import { Logo, LogoMob } from "../../icons";

type Props = {
  children: *,
};

const AuthLayout: StatelessFunctionalComponent<Props> = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    }}
  >
    <Container
      component="header"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "12px 18px", md: "40px 20px 20px" },
      }}
    >
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <LogoMob />
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Logo />
      </Box>
    </Container>
    <Container
      component="main"
      sx={{
        flex: 1,
        padding: { xs: "12px 18px", md: "20px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Container>
  </Box>
);

export default AuthLayout;
