// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const ArrowDown: () => Node = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.4" x="1" y="1" width="14" height="14" rx="1" stroke="white" strokeWidth="2" />
    <path d="M6.9958 8.55606L5.6998 7.28506L4.2998 8.71506L7.0038 11.3621L11.7028 6.71106L10.2968 5.28906L6.9958 8.55606Z" fill="#DDDF4B" />
  </SvgIcon>
);

export default ArrowDown;
