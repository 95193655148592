// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const ArrowDown: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.293 9.29297L12 13.586L7.70697 9.29297L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29297Z" />
  </SvgIcon>
);

export default ArrowDown;
