// @flow
import {
  REPORTS_PAGE,
  MAIN_REPORT_TAB,
  TRANSACTION_REPORT_TAB,
  MAIN_REPORT_TABLE,
  TRANSACTION_REPORT_TABLE,
} from "../../constants";
import { useParamTabs } from "..";

export type UseReportsHook = {
  param: string,
};

export const paramsToTabs: { [string]: string, } = {
  main: MAIN_REPORT_TAB,
  transaction: TRANSACTION_REPORT_TAB,
};

export const paramsToTableKey: { [string]: string, } = {
  main: MAIN_REPORT_TABLE,
  transaction: TRANSACTION_REPORT_TABLE,
};

export const useReports: () => UseReportsHook = () => useParamTabs({
  paramKey: "type",
  pageKey: REPORTS_PAGE,
  paramsToTabs,
});
