// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const ArrowDown: () => Node = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.4" x="1" y="1" width="14" height="14" rx="1" stroke="white" strokeWidth="2" />
  </SvgIcon>
);

export default ArrowDown;
