// @flow

import React, { type Node } from "react";
import { withStyles } from "@mui/styles";
import type { ContextRouter } from "react-router-dom";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";

const styles: * = (theme): * => ({
  root: {
    padding: 0,
  },
  colorPrimary: {
    "& .MuiSvgIcon-root": {
      fill: theme.palette.text.primary,
    },
  },
});

const IconLinkButton: * = withStyles(styles)(({
  children, to, classes, lookup, ...props
}: *): Node => {
  const { pathname = "" }: $PropertyType<ContextRouter, 'location'> = useLocation();
  const selected: boolean = pathname.includes(to) || pathname.includes(lookup);
  return (
    <IconButton
      classes={classes}
      size="large"
      color={selected ? "primary" : "main"}
      component={RouterLink}
      to={to}
      {...props}
    >
      {children}
    </IconButton>
  );
});

export default IconLinkButton;
