// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Statistics: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H21V19H5V3H3Z" />
    <path d="M15.293 14.707C15.3858 14.7999 15.496 14.8737 15.6173 14.924C15.7386 14.9743 15.8687 15.0002 16 15.0002C16.1313 15.0002 16.2614 14.9743 16.3827 14.924C16.504 14.8737 16.6142 14.7999 16.707 14.707L21.707 9.707L20.293 8.293L16 12.586L13.707 10.293C13.6142 10.2001 13.504 10.1263 13.3827 10.076C13.2614 10.0257 13.1313 9.9998 13 9.9998C12.8687 9.9998 12.7386 10.0257 12.6173 10.076C12.496 10.1263 12.3858 10.2001 12.293 10.293L7.293 15.293L8.707 16.707L13 12.414L15.293 14.707Z" />
  </SvgIcon>
);

export default Statistics;
