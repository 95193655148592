// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import environment from "environment";
import {
  AppNavSidebar as AppNavSidebarComponent,
  AppNavSidebarAdditionalComponent,
  AppNavSidebarMenuComponent as Menu,
  type AppNavSidebarMenuComponentProps as MenuProps,
  type AppNavSidebarProps,
  ManagerInfo,
  type ManagerInfoProps,
  OutstandingBalanceInfo,
  UserInfo,
  type UserInfoProps,
  withState,
} from "@fas/cpa-cabinet-ui";
import { useTheme, withStyles } from "@mui/styles";
import {
  useManagerInfo,
  type UseManagerInfoHook,
} from "@fas/cpa-state-manager/redux/hooks";
import {
  Box, useMediaQuery, Link, Typography, Button,
} from "@mui/material";
import { TabletManagerInfo } from "@fas/cpa-cabinet-ui/lib/ManagerInfo/ManagerInfo";
import OutstandingBalanceIcon from "../../icons/OutstandingBalanceIcon";
import { useOutstandingBalance } from "../../hooks";
import { useUserInfo, type UseUserInfoHook } from "../../hooks/useUserInfo";
import {
  managerInfoStyles,
  appNavBarMenuStyles,
  outstandingBalanceStyles,
  userInfoStyles, appNavBarStyles,
  logoutStyles,
  managerInfoTabletStyles,
} from "../../styles";
import links from "./links";
import { RenderMediaQueryComponent } from "../../utils";
import Logout from "../../icons/Logout";
import { Logo, LogoIcon } from "../../icons";

const StyledAppNavSidebarComponent: StatelessFunctionalComponent<AppNavSidebarProps> = withStyles(appNavBarStyles)(
  AppNavSidebarComponent
);
const StyledMenu: StatelessFunctionalComponent<MenuProps> = withStyles(appNavBarMenuStyles)(Menu);
const StyledUserInfo: StatelessFunctionalComponent<UserInfoProps> = withStyles(userInfoStyles)(UserInfo);
const StyledManagerInfo: StatelessFunctionalComponent<ManagerInfoProps> = withStyles(managerInfoStyles)(ManagerInfo);
const StyledTabledManagerInfo: StatelessFunctionalComponent<ManagerInfoProps> = withStyles(managerInfoTabletStyles)(
  TabletManagerInfo
);
const StyledOutstandingBalance: StatelessFunctionalComponent<*> = withStyles(outstandingBalanceStyles)(
  OutstandingBalanceInfo
);

const User
  :StatelessFunctionalComponent<$Diff<UserInfoProps, UseUserInfoHook>> = withState(StyledUserInfo, useUserInfo);

const TabletManager: StatelessFunctionalComponent<$Diff<ManagerInfoProps, UseManagerInfoHook>> = withState(
  StyledTabledManagerInfo,
  useManagerInfo
);
const Manager: StatelessFunctionalComponent<$Diff<ManagerInfoProps, UseManagerInfoHook>> = withState(
  StyledManagerInfo,
  useManagerInfo
);

const OutstandingBalance: StatelessFunctionalComponent<*> = withState(StyledOutstandingBalance, useOutstandingBalance);

const LogoutStyled: StatelessFunctionalComponent<*> = withStyles(logoutStyles)(AppNavSidebarAdditionalComponent);

const AppNavBar: StatelessFunctionalComponent<{}> = () => {
  const theme: * = useTheme();
  const isExpand: boolean = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledAppNavSidebarComponent
      permanentIconsVisible
      isOpen={isExpand}
    >
      <AppNavSidebarAdditionalComponent>
        <Box
          sx={{
            display: { xs: "block", md: "none" }, margin: "20px auto 24px", textAlign: "center", padding: "0 8px", fontSize: "24px", height: "24px",
          }}
        >
          <LogoIcon />
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" }, margin: "4px auto 0", textAlign: "center" }}>
          <Logo />
        </Box>
      </AppNavSidebarAdditionalComponent>

      <AppNavSidebarAdditionalComponent>
        <User />
      </AppNavSidebarAdditionalComponent>
      <AppNavSidebarAdditionalComponent>
        <Box data-testid="menu-sidebar">
          <StyledMenu links={links} />
        </Box>
      </AppNavSidebarAdditionalComponent>

      <Box sx={{ marginTop: { xs: "auto", md: "0" } }}>
        <AppNavSidebarAdditionalComponent>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "column-reverse" } }}>
            <OutstandingBalance renderIcon={<OutstandingBalanceIcon />} />

            <RenderMediaQueryComponent mediaKey="sm" method="only">
              <TabletManager title="Personal manager" />
            </RenderMediaQueryComponent>
            <RenderMediaQueryComponent mediaKey="sm" method="not">
              <Manager title="Personal manager" />
            </RenderMediaQueryComponent>
          </Box>
        </AppNavSidebarAdditionalComponent>
      </Box>
      <LogoutStyled>
        <Button
          variant="transparent"
          component={Link}
          href={environment.links.logout}
        >
          <Logout />
          <Typography varian="bodyBold" component="span">Logout</Typography>
        </Button>
      </LogoutStyled>
    </StyledAppNavSidebarComponent>
  );
};

export default AppNavBar;
