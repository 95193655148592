/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Navigate } from "react-router-dom";
import environment from "environment";
import {
  SetupCard,
  withHookPropsState,
  withState,
  type CardSetupProps,
  type TabsSwitcherProps,
} from "@fas/cpa-cabinet-ui";
import TabsSwitcher from "@fas/cpa-cabinet-ui/lib/TabsSwitcher";
import { withStyles } from "@mui/styles";
import {
  useTabs,
  useUserInfoForm,
  useContactInfoForm,
  type UseTabsArgs,
  usePaymentInfoForm,
} from "../../hooks";
import { setupCardStyles } from "../../styles";
import { PROFILE_PAGE } from "../../constants";
import UserInfo from "../UserInfo";
import ContactInfo from "../ContactInfo";
import PaymentInfo from "../PaymentInfo";
import tabsSwitcher from "../../styles/tabsSwitcher";

type Props = {
  param: string,
};

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledTabs: StatelessFunctionalComponent<
  $Diff<TabsSwitcherProps, { classes: * }>
> = withStyles(tabsSwitcher)(TabsSwitcher);

const Tabs: StatelessFunctionalComponent<{
  hookProps: UseTabsArgs,
  allowScrollButtonsMobile?: boolean,
  scrollButtons?: string | boolean,
  variant?: string,
}> = withHookPropsState(StyledTabs, useTabs);
const UserInfoForm: StatelessFunctionalComponent<{}> = withState(UserInfo, useUserInfoForm);
const ContactInfoForm: StatelessFunctionalComponent<{}> = withState(ContactInfo, useContactInfoForm);
const PaymentInfoForm: StatelessFunctionalComponent<*> = withState(PaymentInfo, usePaymentInfoForm);

export const paramsToComponents: { [string]: StatelessFunctionalComponent<*> } = {
  user: (): Node => <UserInfoForm />,
  contact: (): Node => <ContactInfoForm />,
  payment: (): Node => <PaymentInfoForm />,
  default: (): Node => <Navigate to={environment.links.notFound} />,
};

const Profile: StatelessFunctionalComponent<Props> = ({ param }) => (
  <StyledSetupCard title="My account">
    <Tabs hookProps={{ key: PROFILE_PAGE }} />
    {paramsToComponents[param]()}
  </StyledSetupCard>
);

export default Profile;
