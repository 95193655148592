// @flow
import { type DefaultTheme } from "../themes";

const logoutButton: (DefaultTheme) => * = (theme) => ({
  additionalComponent: {
    left: 0,
    marginTop: "24px",
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
    },
    "& a": {
      ...theme.typography.outlinedMainButton,
      [theme.breakpoints.down("md")]: {
        border: "none",
      },
    },
    "& span.MuiTypography-root": {
      ...theme.typography.bodyBold,
      fontWeight: 800,
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
        marginLeft: "8px",
      },
    },
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.down("md")]: {
        color: theme.palette.text.main,
      },
    },
  },
});

export default logoutButton;
