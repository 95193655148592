// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, type Element,
} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { useTheme, withStyles } from "@mui/styles";
import { DateRangePickerField } from "@fas/cpa-cabinet-ui/lib/Table/SearchComponent";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import { reportColumnsMapTransaction as columnsMap } from "../Reports";
import Checkbox, { type Props as CheckboxProps } from "../ConfigureMainReport/Checkbox";
import { maxDate, minDate, presets } from "../Reports/presets";
import DateInputComponent from "../ConfigureMainReport/DateInputComponent";
import DateRangeFooter from "../DateRangeFooter";
import type { Props as PropsSelect } from "../SelectPopup/SelectPopup";
import SelectPopup, { SelectPopupField } from "../SelectPopup/SelectPopup";
import { useDictionaryValueList } from "../../hooks/useDictionaryValueList";
import FieldMultiselect, { type Props as PropsTextFieldMultiselect } from "../TextFieldMultiselect";
import { useMemoryOptions, type UseMemoryOptionsHook } from "../../hooks/useMemoryOptions";
import { useConfigureAccordion } from "../../hooks/useConfigureAccordion";
import { transactionTypeList, currencyTypeList } from "../Reports/reportColumnsMapTransaction";
import { type UseConfigureReportHook } from "../../hooks/useConfigureReport";
import dateRangePickerStyles from "../../styles/dateRangePaper";
import { countrySelectStyles } from "../../styles";

type Props = {
  ...UseConfigureReportHook,
  onClose?: () => mixed,
  onApply?: () => mixed,
  onUpdatePosition?: () => mixed,
  open?: boolean,
};

const StyledAccordion: StatelessFunctionalComponent<*> = withStyles({})(
  withHookPropsState(Accordion, useConfigureAccordion)
);

const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<PropsSelect, Dictionaries, UseDictionaryType>
  > = withStyles(countrySelectStyles)(withHookPropsState(SelectPopup, useDictionaryValueList));

const TransactionSelect: StatelessFunctionalComponent<PropsSelect> = withStyles(countrySelectStyles)(SelectPopup);

const TextFieldMultiselect: StatelessFunctionalComponent<
  ComponentProps<PropsTextFieldMultiselect, string, UseMemoryOptionsHook>
  > = withHookPropsState(FieldMultiselect, useMemoryOptions);

const Select: StatelessFunctionalComponent<*> = SelectPopupField;

const ConfigureTransactionReport: StatelessFunctionalComponent<Props> = ({
  pendingFilters,
  pendingFields,
  setFieldsFromPending,
  onChangePendingFields,
  setFiltersFromPending,
  onChangePendingFilters,
  onClose,
  onApply,
  onUpdatePosition,
}) => {
  const theme: * = useTheme();

  function isDisableChecked(key) {
    return pendingFields.length <= 2 && pendingFields.includes(key);
  }

  const getCheckboxProps: (string) => CheckboxProps = (key) => ({
    checked: pendingFields.includes(key),
    label: columnsMap[key].label,
    disabled: isDisableChecked(key),
    onChange: (checked: boolean) => {
      if (checked) {
        onChangePendingFields([...pendingFields, key]);
      }
      else {
        onChangePendingFields(pendingFields.filter((field: string): boolean => field !== key));
      }
    },
  });

  const columnsKeys: string[] = Object.keys(columnsMap);

  const handleApply: * = () => {
    setFiltersFromPending(pendingFilters);
    setFieldsFromPending(pendingFields);
    onApply && onApply();
    onClose && onClose();
  };

  return (
    <>
      <Box sx={(): * => ({
        zIndex: 1,
        position: "sticky",
        top: theme.spacing(-1),
        padding: { xs: "8px 12px", sm: "0" },
        display: { xs: "flex", sm: "none" },
        justifyContent: "space-between",
        alignItems: "center",
      })}
      >
        <Typography variant="h5" color="text.main">Filter</Typography>
        <IconButton data-testid="close-btn" sx={{ padding: 0 }} onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: { xs: "12px", sm: "0" },
          background: { xs: theme.palette.transparent.main, sm: "transparent" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: { xs: "16px", md: "20px" },
          flex: "1",
        }}
      >
        <StyledAccordion
          hookProps={{
            name: "dateRangeTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Date range">
            <Typography>Date range</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DateRangePickerField
              applyOnClose
              applyOnSelect
              DateInputComponent={DateInputComponent}
              presets={presets}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(dateFilters: *): * => onChangePendingFilters({
                ...pendingFilters,
                ...dateFilters,
              })}
              value={pendingFilters.date}
              filters={pendingFilters}
              filterKey="date"
              data-testid="date"
              readOnly={false}
              format="YYYY-MM-DD"
              mask="9999-99-99 - 9999-99-99"
              FooterComponent={DateRangeFooter}
              classes={dateRangePickerStyles()}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "showColumnsTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Show columns">
            <Typography>Show columns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              sx={() => ({
                gap: "8px",
                "& label:hover:not(.Mui-disabled) .MuiTypography-root": {
                  color: theme.palette.text.primary,
                },
              })}
            >
              <Grid item xs>
                {columnsKeys
                  .slice(0, Math.ceil(columnsKeys.length / 2))
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Grid>
              <Grid item xs>
                {columnsKeys
                  .slice(Math.ceil(columnsKeys.length / 2), columnsKeys.length)
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "countryTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Country">
            <Typography>Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CountrySelect
              value={pendingFilters.country}
              name="country"
              data-testid="country"
              onChange={(country: *): * => onChangePendingFilters({
                ...pendingFilters,
                country,
              })}
              hookProps="country"
              placeholder="Select country(s)"
              defaultValue={[]}
              isSplitListBoxColumn
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subIdTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid">
            <Typography>Subid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subIdTransaction"
              name="subId"
              placeholder="Input subId"
              placeholderInput="Input subId"
              value={pendingFilters.subId}
              onChange={(e: *): mixed => onChangePendingFilters({
                ...pendingFilters,
                subId: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "platformTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Platform">
            <Typography>Platform</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              options={[{ title: "Web", value: "web" }, { title: "Mobile", value: "mobile" }]}
              value={pendingFilters.platform}
              name="platform"
              data-testid="platform"
              placeholder="Select platform"
              onChange={(platform: *): * => onChangePendingFilters({
                ...pendingFilters,
                platform,
              })}
              defaultValue=""
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "statusTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Status">
            <Typography>Status</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              options={[{ title: "Approved", value: "Approved" }, { title: "Declined", value: "Declined" }]}
              value={pendingFilters.status}
              name="status"
              data-testid="status"
              placeholder="Select status"
              onChange={(status: *): * => onChangePendingFilters({
                ...pendingFilters,
                status,
              })}
              defaultValue=""
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "clickIdTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Clickid">
            <Typography>Clickid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="clickIdTransaction"
              name="clickId"
              placeholder="Input clickId"
              placeholderInput="Input clickId"
              value={pendingFilters.clickId}
              onChange={(e: *): mixed => onChangePendingFilters({
                ...pendingFilters,
                clickId: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subId2Transaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid2">
            <Typography>Subid2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subId2Transaction"
              name="subId2"
              placeholder="Input subId2"
              placeholderInput="Input subId2"
              value={pendingFilters.subId2}
              onChange={(e: *): mixed => onChangePendingFilters({
                ...pendingFilters,
                subId2: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "platformTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="conversion_name">
            <Typography>Transaction type</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TransactionSelect
              disableSearch
              isLoading={false}
              options={transactionTypeList}
              value={pendingFilters.conversion_name}
              name="conversion_name"
              data-testid="conversion_name"
              placeholder="Select transaction type"
              // eslint-disable-next-line
              onChange={(conversion_name: *): * => onChangePendingFilters({
                ...pendingFilters,
                // eslint-disable-next-line
                conversion_name,
              })}
              defaultValue={[]}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "platformTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="currency">
            <Typography>Conversion currency</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              options={currencyTypeList}
              value={pendingFilters.currency}
              name="currency"
              data-testid="currency"
              placeholder="Select conversion currency"
              onChange={(currency: *): * => onChangePendingFilters({
                ...pendingFilters,
                currency,
              })}
              defaultValue=""
            />
          </AccordionDetails>
        </StyledAccordion>

        <Box sx={(): * => ({
          zIndex: 1,
          position: "sticky",
          bottom: { xs: "0", sm: "-10px" },
          marginTop: { xs: "24px" },
        })}
        >
          <Button sx={() => ({ ...theme.typography.mainButton })} disabled={pendingFields.length < 2} data-testid="apply-btn" fullWidth onClick={handleApply}>
            Apply filters
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ConfigureTransactionReport;
