// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Chat: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.95 4.04999C18.271 5.37299 19 7.13099 19 8.99999C19 10.869 18.271 12.627 16.95 13.95L18.364 15.364C20.064 13.663 21 11.403 21 8.99999C21 6.59699 20.064 4.33699 18.364 2.63599L16.95 4.04999ZM14.121 6.87999C14.688 7.44499 15 8.19799 15 8.99999C15 9.80199 14.688 10.555 14.121 11.12L15.535 12.536C16.479 11.592 17 10.337 17 8.99999C17 7.66299 16.479 6.40799 15.535 5.46399L14.121 6.87999ZM12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 12 8 12 8ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8C10 9.10457 10 8 10 8ZM14 19C14 15.6863 11.3137 13 8 13C4.68629 13 2 15.6863 2 19H4C4 16.7909 5.79086 15 8 15C10.2091 15 12 16.7909 12 19H14Z" />
  </SvgIcon>
);

export default Chat;
