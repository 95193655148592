// @flow
import environment from "environment";
import { parseResponse } from "@fas/ui-framework";
import * as baseApi from "@mtu/contract/lib/openApiClientDefinitions/rest/service/together-ads/client";
import { optimistic } from "oazapfts";
import { get } from "lodash";
import { Cookies } from "react-cookie";
import { AUTH_TOKEN } from "@fas/cpa-state-manager/redux/constants";

// eslint-disable-next-line
const { defaults, servers, ...sdk } = optimistic(baseApi);

function parseErrorMessage(p) {
  return get(p, "error[0].message", "");
}

function getAuthToken(): string {
  const data: {token?: string} = new Cookies().get(AUTH_TOKEN) || {};
  return data.token || "";
}

export const getAuthorizationHeaders = () => ({
  Authorization: `Bearer ${getAuthToken()}`,
});

defaults.baseUrl = environment.baseUrl;
defaults.fetch = (input: RequestInfo | URL, init?) => {
  const customInit = {
    ...init,
    headers: {
      ...init?.headers,
      ...getAuthorizationHeaders(),
    },
  };
  if (!getAuthToken()) {
    window.location.href = environment.links.login;
  }
  return fetch(input, customInit).then(async (r) => {
    if (r.status === 401) {
      window.location.href = environment.links.login;
    }
    if (!r.ok) {
      const b = await r.json();
      b.errorMessage = parseResponse(b) || parseErrorMessage(b);
      return Promise.reject(b);
    }
    return r;
  }).catch((e) => {
    e.errorMessage = parseResponse(e) || parseErrorMessage(e);
    return Promise.reject(e);
  });
};

const api = { ...sdk };

export default api;
