// @flow
import { type DefaultTheme } from "../themes";

const appHeaderBar: (DefaultTheme) => * = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    padding: "0 12px",
    borderBottom: `1px solid ${theme.palette.transparent.main}`,
  },
});

export default appHeaderBar;
