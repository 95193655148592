// @flow
export const TRAFFIC_SOURCES_MODAL: "TRAFFIC_SOURCES_MODAL" = "TRAFFIC_SOURCES_MODAL";

export const PROFILE_PAGE: "PROFILE_PAGE" = "PROFILE_PAGE";

export const USER_INFO_TAB: "Personal info" = "Personal info";
export const CONTACT_INFO_TAB: "Contact info" = "Contact info";
export const PAYMENT_METHODS_TAB: "Payment info" = "Payment info";

export const NO_PAYMENT_DETAILS_MODAL: "NO_PAYMENT_DETAILS_MODAL" = "NO_PAYMENT_DETAILS_MODAL";
export const PAYMENT_METHOD_FORM: "PAYMENT_METHOD_FORM" = "PAYMENT_METHOD_FORM";
export const USER_INFO_FORM: "USER_INFO_FORM" = "USER_INFO_FORM";
export const CONTACT_INFO_FORM: "CONTACT_INFO_FORM" = "CONTACT_INFO_FORM";

export const REPORTS_PAGE: "REPORTS_PAGE" = "REPORTS_PAGE";

export const MAIN_REPORT_TAB: "Main report" = "Main report";
export const TRANSACTION_REPORT_TAB: "Transaction report" = "Transaction report";

export const MAIN_REPORT_TABLE: "MAIN_REPORT_TABLE" = "MAIN_REPORT_TABLE";
export const TRANSACTION_REPORT_TABLE: "TRANSACTION_REPORT_TABLE" = "TRANSACTION_REPORT_TABLE";

export const POSTBACKS_PAGE: "POSTBACKS_PAGE" = "POSTBACKS_PAGE";

export const DEFAULT_POSTBACK_TAB: "Default postback" = "Default postback";
export const INFO_POSTBACKS_TAB: "Informational events postbacks" = "Informational events postbacks";

export const PAYMENT_HISTORY_TABLE: "PAYMENT_HISTORY_TABLE" = "PAYMENT_HISTORY_TABLE";

export const INFO_MAIN_POPUP: "INFO_MAIN_POPUP" = "INFO_MAIN_POPUP";

export const PERIOD_STATE_PENDING: number = 5;
export const PERIOD_STATE_TO_PAID: number = 6;
export const PERIOD_STATE_READY_FOR_1C: number = 10;
export const PERIOD_STATE_SEND_TO_1C: number = 11;
export const PERIOD_STATE_REFUND: number = 12;

export const STATUS_PAID: string = "Paid";
export const STATUS_REFUND: string = "Refund";
export const STATUS_PENDING: string = "Pending";

export const PERIOD_STATE_TO_STATUS_MAPPING: { [number]: string } = {
  [PERIOD_STATE_TO_PAID]: STATUS_PAID,
  [PERIOD_STATE_REFUND]: STATUS_REFUND,
  [PERIOD_STATE_PENDING]: STATUS_PENDING,
  [PERIOD_STATE_READY_FOR_1C]: STATUS_PENDING,
  [PERIOD_STATE_SEND_TO_1C]: STATUS_PENDING,
};
