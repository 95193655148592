// @flow
import { useDispatch } from "react-redux";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import dayjs from "dayjs";
import contentDisposition from "content-disposition";
import isEqual from "lodash.isequal";
import type { Node } from "react";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

type ObjDef = { [*]: *, ..., };

export const getValidation: (*) => (ObjDef) => ErrorsMapper = (schema: *) => (value: ObjDef): ErrorsMapper => {
  const { error: { details = {} } = {} }: * = schema.validate(value, { abortEarly: false });

  return Object.keys(details).reduce((acc: ErrorsMapper, key: string): ErrorsMapper => ({
    ...acc,
    [details[key].path.join(".")]: { message: details[key].message },
  }), {});
};

export function getDownloadFileNameCSV(headers: {[key: string]: string, ...}) {
  const name = getFileNameFromHeaders(headers);
  return name || `Report-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.csv`;
}

export function getDownloadFileNamePdf(headers: {[key: string]: string, ...}) {
  const name = getFileNameFromHeaders(headers);
  return name || `Invoice-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.pdf`;
}

export function getDownloadFileNameZip(headers: {[key: string]: string, ...}) {
  const name = getFileNameFromHeaders(headers);
  return name || `Invoice-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.zip`;
}

export function getFileNameFromHeaders(headers: {[key: string]: string, ...}, defaultName: string = ""): string {
  if (!headers) return defaultName;
  const headersPrepared = new Headers(headers);

  try {
    const disposition: string = String(headersPrepared.get("content-disposition") || headersPrepared.get("Content-Disposition") || headersPrepared.get("Content-disposition") || "");
    return contentDisposition.parse(disposition).parameters.filename || defaultName;
  }
  catch {
    return defaultName;
  }
}

export const isEqualPreviousBuilder: () => (*) => boolean = () => {
  let oldVal: *;
  return function isEqualPrevious(currentVal: *): boolean {
    const isEqualValue: boolean = isEqual(currentVal, oldVal);
    oldVal = currentVal;
    return isEqualValue;
  };
};

export function numberWithCommas(x: number | string): string {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(x || 0));
}

export function formatCurrency(currency: string, value: string | number): string {
  const result: string = Intl.NumberFormat("en-US", { style: "currency", currency: currency || "USD" }).format(Number(value));
  return `${result.split("").splice(0, 1).join("")} ${result.split("").splice(1).join("")}`;
}

export function RenderMediaQueryComponent({ children, mediaKey, method = "down" }: { children: Node, mediaKey: "xs" | "sm" | "md" | "lg", method: "up" | "down" | "only" | "not"}): Node {
  const theme: * = useTheme();
  const isMatch: boolean = useMediaQuery(theme.breakpoints[method](mediaKey));
  return isMatch && children;
}

export const onKeyWrapper: * = (keys: string | string[]) => (fn: (*) => mixed): * => {
  const keyList: string[] = Array.isArray(keys) ? keys : [keys];

  return function wrapped(...args: *) {
    const [{ key }]: * = args;

    if (keyList.includes(key)) fn.apply(this, args);
  };
};

export function useCopy(defaultText: string): (currentText?: string) => void {
  const dispatch: <A>(A) => A = useDispatch();
  return (currentText?: string) => {
    navigator.clipboard.writeText(typeof currentText === "string" ? currentText : defaultText).then((): * => dispatch(addNotification({ severity: "success", message: "Copied successfully" })));
  };
}
