// @flow
import { type DefaultTheme } from "../themes";

const formGrid: (DefaultTheme) => * = (theme) => ({
  root: {
    maxWidth: "800px",
    display: "grid",
    gridGap: "28px 20px",
    marginTop: "8px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.up("lg")]: {
      gridGap: "28px 40px",
    },
    "& .colSpan2": {
      gridColumn: "-1 / 1",
    },
  },
});

export default formGrid;
