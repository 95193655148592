// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { type DefaultTheme } from "../../themes";

type Props = {
  classes?: *,
};

const footerStyles: (DefaultTheme) => * = (theme) => ({
  root: {
    textAlign: "center",
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "4px",
    },
    "& .MuiList-root": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "5px",
      padding: "0",
    },
    "& .MuiListItem-root": {
      display: "flex",
      gap: "16px",
      padding: "0",
      justifyContent: "center",
    },
    "& .MuiLink-root": {
      color: theme.palette.text.primary,
      textDecorationColor: theme.palette.text.primary,
      fontSize: "14px",
    },
  },
  copyright: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    marginTop: "8px",
  },
});

const Footer: StatelessFunctionalComponent<Props> = ({
  classes = {},
}) => (
  <Box className={classes.root}>
    <List>
      <ListItem>
        <Link href="https://together-ads.com/privacy-policy">Privacy Policy</Link>
        <Link href="https://together-ads.com/terms-and-conditions">Terms Of Use</Link>
      </ListItem>
      <ListItem>
        <Link href="https://together-ads.com/do-not-sell-my-personal-information">Do not sell my personal information</Link>
      </ListItem>
    </List>
    <Typography className={classes.copyright}>©2024 TOGETHERADS PTE. LTD All rights reserved.</Typography>
  </Box>
);

export default withStyles(footerStyles)(Footer);
