/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTableData,
} from "@fas/cpa-state-manager/services/selectors";
import { useEnvironment } from "@fas/cpa-state-manager/hooks";
import { useQuery } from "@tanstack/react-query";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import { Box } from "@mui/material";
import type { Environment } from "@fas/cpa-state-manager/services/auth";
import { BEST_OFFERS_LIST } from "@fas/cpa-state-manager/redux/constants";
import { setTableData } from "@fas/cpa-state-manager/redux/actions";
import { formatCurrency } from "../../utils";
import api from "../../services/api";

type OfferSource = {
  "country": string,
  "webMetric": number,
  "mobMetric": number,
}
type Offer = {
  ...OfferSource,
  logoUrl: string,
  currencyWeb: string,
  currencyMob: string,
  vertical: string,
};
export type UseBestOffersHook = {
  data: Offer[],
  columns: Column<Offer>[],
  isLoading: boolean,
};

export const useBestOffers: () => UseBestOffersHook = () => {
  const columns: Column<Offer>[] = [
    {
      field: "country",
      label: "Country",
      render: ({ logoUrl, country }: Offer): Element<*> => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <img src={logoUrl} alt={country} style={{ maxHeight: "18px", maxWidth: "24px" }} />
          <Box sx={{ marginLeft: "8px", textTransform: "uppercase" }}>
            {country}
          </Box>
        </Box>
      ),
    },
    {
      field: "webMetric",
      label: "Web",
      render: ({ webMetric, currencyWeb }: Offer): Element<*> | string => (
        <Box
          display="flex"
          direction="row"
          justifyContent="center"
        >
          {webMetric === null ? "-" : formatCurrency(currencyWeb, webMetric)}
        </Box>
      )
      ,
    },
    {
      field: "mobMetric",
      label: "Mob",
      render: ({ mobMetric, currencyMob }: Offer): Element<*> | string => (
        <Box
          display="flex"
          direction="row"
          justifyContent="center"
        >
          {mobMetric === null ? "-" : formatCurrency(currencyMob, mobMetric)}
        </Box>
      ),
    },
    {
      field: "vertical",
      label: "Vertical",
      render: ({ vertical }: Offer): Element<*> | string => (
        <Box
          display="flex"
          direction="row"
          justifyContent="center"
        >
          {vertical}
        </Box>
      ),
    },
  ];

  const dispatch: <A>(A) => A = useDispatch();
  const tableData: Offer[] = useSelector((state: *): Offer[] => getTableData(state, BEST_OFFERS_LIST));
  const environment: Environment = useEnvironment();

  const {
    isLoading,
    data,
  } = useQuery(["bestOpportunities"], () => api.getTopOffers({
    environment,
  }), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data?.data) {
      const newData = data.data.map((offer: Offer): Offer => ({
        ...offer,
        logoUrl: `/new/img/flags/${offer.country}.png`,
        currencyWeb: "USD",
        currencyMob: "USD",
        vertical: "Dating",
      }));
      dispatch(setTableData(BEST_OFFERS_LIST, newData));
    }
  }, [data, dispatch]);

  return {
    data: (isLoading) ? [] : tableData,
    columns,
    isLoading,
  };
};
