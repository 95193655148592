// @flow
import React, { type Node, type StatelessFunctionalComponent, useState } from "react";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  Input,
  InputAdornment,
  inputClasses,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { getTitleByValue } from "@fas/cpa-cabinet-ui/lib/Table/SearchComponent";
import type { Option } from "@fas/cpa-state-manager/redux/reducers";
import { mergeClasses } from "@fas/cpa-cabinet-ui/lib/utils";

const useStylesHideSearch: * = makeStyles((): * => ({
  root: {
    // instead display: none, for normal working anchorEl popup list box options
    height: 0,
    opacity: 0,
    padding: 0,
    pointerEvents: "none",
  },
}));

const useStyles: * = makeStyles((theme): * => ({
  root: {
    [`& .${inputClasses.root}`]: {
      padding: "9px 8px",
      marginBottom: "8px",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "20px",
      },
      "&.Mui-focused .MuiSvgIcon-root": {
        color: theme.palette.text.primary,
      },
    },

    [`& .${inputClasses.root} .${inputClasses.input}`]: {
      padding: 0,
    },
  },
  popper: {
    position: "relative!important", // override mui popper style
    transform: "none!important", // override mui popper style
    width: "inherit!important",

    [`& .${autocompleteClasses.paper}`]: {
      boxShadow: "none",
    },
    [`& .${autocompleteClasses.paper} .${autocompleteClasses.listbox}`]: {
      background: "none",
      padding: 0,
      maxHeight: "calc(40dvh)",
      display: "grid",
      gridTemplateColumns: "1fr",

      [`& .${autocompleteClasses.option}`]: {
        margin: 0,
        padding: "6px 16px",
        minHeight: "unset",
        fontSize: "14px",
        lineHeight: "1.428",
        color: theme.palette.text.main,
        "&.Mui-focused": {
          backgroundColor: theme.palette.transparent.main,
          color: "inherit",
        },
        "&.MuiAutocomplete-option[aria-selected=\"true\"]": {
          backgroundColor: theme.palette.text.primary,
          color: theme.palette.text.dark,
        },
        "& .MuiCheckbox-root": {
          display: "none",
        },
      },
    },
  },
  header: {
    marginBottom: "12px",
  },
  title: {
    ...theme.typography.bodyBold,
    color: theme.palette.text.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: 1.555,
    },
  },
  subtitle: {
    color: theme.palette.text.mutted,
    ...theme.typography.bodySmall,
  },
  clearAll: {
    ...theme.typography.bodyExtraSmallBold,
    color: theme.palette.text.primary,
    marginLeft: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    "&:hover": {
      backgroundColor: theme.palette.transparent.main,
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.bodySmallBold,
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodyExtraSmallBold,
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodySmallBold,
    },
  },
}));

export type Props = {
  onClose?: () => *,
  value: *,
  label?: string,
  onChange: (*) => *,
  options: Option[],
  isLoading: boolean,
  disableCloseOnSelect?: boolean,
  isSplitListBoxColumn?: boolean,
  disableSearch?: boolean,
  classes?: *,
  defaultValue: string | string[],
}
const filterOptions: * = createFilterOptions({
  stringify: (option: Option): * => `${option.value} ${option.title}`,
  trim: true,
});

function toOption(value?: string, options: Option[]): Option {
  // $FlowFixMe options have param
  return ({ value, title: String(getTitleByValue(value, options)) });
}

export function toOptionValue(value?: string | string[], options: Option[]): Option[] | Option {
  return Array.isArray(value) ? value.map((v: string): Option => toOption(v, options)) : toOption(value, options);
}

const SelectBody: StatelessFunctionalComponent<Props> = ({
  onClose,
  value,
  onChange,
  options,
  isLoading,
  disableCloseOnSelect,
  disableSearch,
  defaultValue = "",
  label,
  classes: propClasses = {},
  isSplitListBoxColumn,
}) => {
  const multiple: boolean = Array.isArray(value || defaultValue);
  const [searchValue, setSearchValue]: * = useState<string>("");

  const hideSearchClasses: { [string]: * } = useStylesHideSearch();
  const ownClasses: { [string]: * } = mergeClasses(useStyles({
    multiple,
    isSplitListBoxColumn,
  }), disableSearch ? hideSearchClasses : {});

  const classes: { [string]: * } = mergeClasses(ownClasses, propClasses);

  const defaultValueOption: Option[] | Option = toOptionValue(defaultValue, options);
  const valueOption: Option[] | Option = toOptionValue(value, options);
  const currentValue: Option[] | Option = value ? valueOption : defaultValueOption;

  return (
    <>
      <Box display="flex" justifyContent="space-between" className={classes.header}>
        <Box>
          <Typography className={classes.title}>{label}</Typography>
          { multiple && <Typography variant="body" className={classes.subtitle}>You can choose several items</Typography> }
        </Box>
        <Box>
          <Button className={classes.clearAll} data-testid="clear-all" onClick={(): * => onChange(defaultValue)}>
            Clear all
          </Button>
        </Box>
      </Box>
      <Autocomplete
        classes={classes}
        className={classes.searchInput}
        multiple={multiple}
        open
        disableCloseOnSelect
        filterOptions={filterOptions}
        loading={isLoading}
        onClose={(event: *, reason: *) => {
          if (reason === "escape") {
            onClose && onClose();
          }
        }}
        value={currentValue}
        onChange={(event: *, newValue: *, reason: *) => {
          if (reason === "clear" || (event.type === "keydown" && event.key === "Backspace" && reason === "removeOption")) {
            return;
          }
          onChange(Array.isArray(newValue) ? newValue.map((option: *): * => option.value) : newValue.value);
          if (disableCloseOnSelect === undefined ? !multiple : !disableCloseOnSelect) {
            onClose && onClose();
          }
          setSearchValue("");
        }}
        disablePortal
        renderTags={(): * => null}
        options={options}
        getOptionLabel={(option: *): string => (option && option.title) || ""}
        isOptionEqualToValue={(
          option: *, current: *
        ): * => current === option.value || (current && current.value === option.value)}
        inputValue={searchValue}
        onInputChange={(e: *, v: *, r: *): * => (r === "input" && setSearchValue(v))}
        renderOption={(props: *, option: *, { selected }: *): Node => (
          <li {...props}>
            <Checkbox
              size="small"
              checked={selected}
            />
            {option.title}
          </li>
        )}
        renderInput={(params: *): * => (
          <Input
            data-testid="search"
            fullWidth
            disableUnderline
            variant="outlined"
            margin="dense"
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            endAdornment={(
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )}
            autoFocus={!disableSearch}
            placeholder="Search..."
          />
        )}
      />
    </>
  );
};

export default SelectBody;
