// @flow
/* eslint-disable import/max-dependencies */
import { useSelector, useDispatch } from "react-redux";
import { addNotification } from "@fas/ui-framework";
import { useMutation } from "@tanstack/react-query";
import FileSaver from "file-saver";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import { getTableExportParams, getTableFields, getTableSorting } from "@fas/cpa-state-manager/services/selectors/table";
import type { Environment } from "@fas/cpa-state-manager/services/auth";
import { useEnvironment } from "@fas/cpa-state-manager/hooks";
import { useReportTable, type UseReportTableHook } from "../useReportTable";
import reportsConfigs, { type ReportConfig } from "../../components/Reports/reportsConfigs";
import { getDownloadFileNameCSV } from "../../utils";
import { getAuthorizationHeaders } from "../../services/api";

export type UseExportReportHook = {
  disabled?: boolean,
  isLoading?: boolean,
  onClick: () => *,
};

export const useReportExport: (string) => UseExportReportHook = (tableKey) => {
  const dispatch: <A>(A) => A = useDispatch();

  const {
    columnsMap, exportEndpoint, operationId,
  }: ReportConfig = reportsConfigs(tableKey);

  const environment: Environment = useEnvironment();
  const { isLoading, data }: UseReportTableHook = useReportTable({ tableKey, columnsMap, operationId });
  const { filters = {} }: * = useSelector((state: *): * => getTableExportParams(state, tableKey));
  const sorting: Sorting = useSelector((state: *): Sorting => getTableSorting(state, tableKey));

  const selectedFields: string[] = useSelector((state: *): string[] => getTableFields(state, tableKey));
  const fields: string[] = Object.keys(columnsMap).filter((k: string): boolean => selectedFields.includes(k));

  const [current = "", direction = ""]: [string, mixed] = Object.entries(sorting)[0] || [];
  const {
    // $FlowFixMe
    date: { from, to } = {},
    groupBy,
    ...restFilters
  }: Filters = filters;

  const { mutate, isLoading: isLoadingExportCSV } = useMutation({
    mutationKey: ["getPayoutHistoryExport"],
    mutationFn: () => fetch(environment.endpoints.post[exportEndpoint], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthorizationHeaders(),
      },
      body: JSON.stringify({
        filters: Object
          .keys(restFilters)
          .filter((key: string): boolean => (
            Array.isArray(filters[key]) ? Boolean(filters[key].length) : Boolean(filters[key])))
          .reduce((acc: *, key: string): * => ({
            ...acc,
            [key]: typeof filters[key] === "string" ? [filters[key].trim()] : filters[key],
          }), {
            // $FlowFixMe
            date: [{ from, to }],
          }),
        // $FlowFixMe
        ...(groupBy && { group: [groupBy] }),
        ...(current && { sorting: [{ current, direction }] }),
        fields: fields.map((key: string): * => ({ fieldName: columnsMap[key].field, name: columnsMap[key].label })),
      }),
    }),
    onSuccess: async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      const blob = await response.blob();
      const filename = getDownloadFileNameCSV(response.headers);

      FileSaver.saveAs(blob, filename);
      dispatch(addNotification({ message: "Successfully downloaded", severity: "success" }));
    },
    onError: (e: { message: string }) => {
      dispatch(addNotification({ message: e.message || "Something went wrong...", severity: "error" }));
    },
  });

  const onClick = () => {
    mutate();
  };

  return {
    disabled: isLoadingExportCSV || isLoading || data.length === 0 || fields.length === 0,
    isLoading: isLoadingExportCSV,
    onClick,
  };
};
