// @flow

import {
  Skeleton, TableBody, TableCell, TableRow,
} from "@mui/material";
import React from "react";
import type { Node, StatelessFunctionalComponent } from "react";

type Props = {
  isLoading?: boolean,
  fieldsLength: number,
  ...,
};

const ReportSkeletonLoading: StatelessFunctionalComponent<Props> = ({
  isLoading,
  fieldsLength = 0,
}) => (isLoading ? (
  <TableBody
    sx={{
      display: { xs: "flex", md: "table-row-group" }, gridArea: "total", flex: 1, width: "100%", borderSpacing: { xs: "1px", md: "0 8px" },
    }}
  >
    {Array.from({ length: 10 }, (_: *, index: number): Node => (
      <TableRow key={index} sx={{ width: "100%", flex: 1 }}>
        {Array.from({ length: fieldsLength }).map((__: *, idx: number): Node => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={idx} sx={{ padding: 0, display: { xs: "block", md: "none" }, margin: "1px" }}>
            <Skeleton
              sx={{
                height: {
                  xs: 32,
                },
                transform: "none",
              }}
            />
          </TableCell>
        ))}
        <TableCell colSpan={500} sx={{ padding: 0, display: { xs: "none", md: "table-cell" } }}>
          <Skeleton
            sx={{
              height: {
                md: 24, lg: 40,
              },
              transform: "none",
            }}
          />
        </TableCell>

      </TableRow>
    ))}
  </TableBody>
) : null);

export default ReportSkeletonLoading;
