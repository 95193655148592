// @flow
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import {
  MAIN_REPORT_TABLE,
  TRANSACTION_REPORT_TABLE,
} from "../../constants";
// eslint-disable-next-line import/no-useless-path-segments
import { reportColumnsMapMain, reportColumnsMapTransaction } from "../Reports";

export type ReportConfig = {
  tableKey: string,
  columnsMap: { [string]: Column<*> },
  exportEndpoint: string,
  operationId: string,
};

const reportsConfigs = (tableKey: string): ReportConfig => {
  const reports: { [string]: ReportConfig, ... } = {
    MAIN_REPORT_TABLE: {
      tableKey: MAIN_REPORT_TABLE,
      columnsMap: reportColumnsMapMain,
      exportEndpoint: "mainReportExport",
      operationId: "apiReportMain",
    },
    TRANSACTION_REPORT_TABLE: {
      tableKey: TRANSACTION_REPORT_TABLE,
      columnsMap: reportColumnsMapTransaction,
      exportEndpoint: "transactionReportExport",
      operationId: "apiReportTransaction",
    },
  };
  return reports[tableKey];
};

export default reportsConfigs;
