/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import {
  Box,
} from "@mui/material";
import { withState } from "@fas/cpa-cabinet-ui";
import {
  EmailOutlined,
  MoreHoriz,
  Mediation,
  Language,
  CategoryOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import { USER_INFO_FORM } from "../../constants";
import TextField, { type TextFieldFormProps } from "../TextFieldForm";
import { usePasswordField, type UsePasswordFieldHook } from "../../hooks";
import ShowByValue from "./ShowByValue";
import AccountUser from "../../icons/AccountUser";
import Person from "../../icons/Person";
import Password from "../../icons/Password";
import Chat from "../../icons/Chat";
import AccountBox from "../../icons/AccountBox";
import { formGridStyles } from "../../styles";

const UserInfoForm: StatelessFunctionalComponent<FormProps> = withForm(Form);
const PasswordField: StatelessFunctionalComponent<$Diff<TextFieldFormProps, UsePasswordFieldHook>> = withState(
  TextField,
  usePasswordField
);

export type Props = {};

const Layout = withStyles(formGridStyles)(({ children, classes }) => (
  <Box className={classes.root}>{children}</Box>
));

const UserInfo: StatelessFunctionalComponent<Props> = () => {
  const tooltipIconProps = {
    EndIcon: <LockOutlined />,
    slotProps: {
      tooltip: {
        title: "For any updates in this field, please contact your account manager.",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Personal info</title>
      </Helmet>
      <UserInfoForm id={USER_INFO_FORM}>
        <Box>
          <Layout>
            <TextField
              {...tooltipIconProps}
              name="firstName"
              label="First Name"
              placeholder="What is your first name?"
              StartIcon={<AccountUser />}
              disabled
            />
            <TextField
              {...tooltipIconProps}
              name="lastName"
              label="Last Name"
              placeholder="What is your last name?"
              StartIcon={<Person />}
              disabled
            />
            <Box className="colSpan2">
              <TextField
                name="email"
                label="Email"
                placeholder="What is your email?"
                StartIcon={<EmailOutlined />}
                disabled
                EndIcon={<LockOutlined />}
                slotProps={{
                  tooltip: {
                    title: "For any updates in this field, please contact your account manager.",
                  },
                }}
              />
            </Box>
            <PasswordField
              {...tooltipIconProps}
              name="password"
              label="Password"
              placeholder="Create a strong password"
              StartIcon={<Password />}
              disabled
            />
            <PasswordField
              {...tooltipIconProps}
              name="confirmation"
              label="Confirmation Password"
              placeholder="Please enter your password again"
              StartIcon={<Password />}
              disabled
            />

            <TextField
              {...tooltipIconProps}
              name="imType"
              label="Messenger"
              placeholder="Select your messenger"
              StartIcon={<Chat />}
              disabled
            />

            <TextField
              {...tooltipIconProps}
              name="imName"
              label="Messenger ID"
              placeholder="What is your messenger ID?"
              StartIcon={<AccountBox />}
              disabled
            />
            <Box sx={{ display: { md: "flex" } }} style={{ width: "100%" }}>
              <TextField
                {...tooltipIconProps}
                name="topVerticals"
                label="Vertical"
                placeholder="What business vertical are you working with?"
                StartIcon={<CategoryOutlined />}
                disabled
              />
              <ShowByValue name="topVerticals" compare={(v: string): boolean => v === "Other"}>
                <TextField
                  {...tooltipIconProps}
                  name="topVerticalsComment"
                  label="Vertical comment"
                  placeholder="Please specify"
                  StartIcon={<MoreHoriz />}
                  disabled
                />
              </ShowByValue>
            </Box>
            <TextField
              {...tooltipIconProps}
              name="trafficType"
              label="Traffic Sources"
              placeholder="What traffic sources are you working with?"
              StartIcon={<Mediation />}
              disabled
            />
            <TextField
              {...tooltipIconProps}
              name="revenue"
              label="Monthly Volumes"
              placeholder="What are your monthly volumes"
              StartIcon={<Mediation />}
              disabled
            />
            <TextField
              {...tooltipIconProps}
              name="topCountries"
              label="Top GEOs"
              placeholder="What are the top GEOs you work with?"
              StartIcon={<Language />}
              disabled
            />
          </Layout>
        </Box>
      </UserInfoForm>
    </>
  );
};

export default UserInfo;
