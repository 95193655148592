// @flow
import { type DefaultTheme } from "../themes";

const setupCard: (DefaultTheme) => * = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.transparent.main,
    padding: "12px 12px 20px",
    height: "100%",
    maxHeight: "100%",
    boxShadow: "none",
    color: theme.palette.text.main,
    flex: "1",
    [theme.breakpoints.up("sm")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "40px",
    },
    "& > div": {
      flexGrow: 1,
    },
    "& > div + div": {
      flexGrow: 1,
    },
  },
  cardContent: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      overflow: "initial",
    },
    "& > div:nth-child(1)": {
      marginBottom: "0",
    },
    "& .MuiTypography-h5": {
      marginBottom: "12px",
      "&:empty": {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: "28px",
      },
    },
  },
});

export default setupCard;
