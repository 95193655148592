// @flow
import { type DefaultTheme } from "../themes";

const outstandingBalance: (DefaultTheme) => * = (theme) => ({
  layout: {
    margin: "24px 0",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0",
    },
  },
  root: {
    display: "block",
  },
  comment: {
    ...theme.typography.bodyBold,
    color: theme.palette.text.main,
    padding: "8px 10px",
    background: "rgb(51, 51, 51)",
    textAlign: "center",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "1.33",
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodyBold,
    },
  },
  balance: {
    "& > .MuiBox-root": {
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        padding: "12px",
        background: theme.palette.transparent.light,
      },
    },
  },
  currency: {
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "16px",
    color: theme.palette.text.mutted,
    marginTop: "4px",
    "&[data-disabled=\"true\"]": {
      color: theme.palette.transparent.disabled,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      ...theme.typography.bodyExtraSmallBold,
      color: theme.palette.text.main,
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodyBold,
    },
  },
});

export default outstandingBalance;
