// @flow
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOutstandingBalanceInfoBalance } from "@fas/cpa-state-manager/services/selectors/outstandingBalanceInfo";
import { setOutstandingBalanceInfo } from "@fas/cpa-state-manager/redux/actions/outstandingBalanceInfo";
import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";

export type UseOutstandingBalanceHook = {
  isLoading?: boolean,
  balance: number,
  disabled?: boolean,
  comment?: string,
};

export const useOutstandingBalance: () => UseOutstandingBalanceHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const balance: number = useSelector(getOutstandingBalanceInfoBalance);

  const {
    isLoading,
    isFetching,
    data,
  } = useQuery(["getOutstandingBalance"], () => api.getOutstandingBalance(), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data?.data?.outBalance) {
      dispatch(setOutstandingBalanceInfo({ balance: Number(data.data.outBalance.split("$")[1]) }));
    }
  }, [data, dispatch]);

  return {
    isLoading,
    balance,
    disabled: isFetching,
    comment: "Outstanding balance",
  };
};
