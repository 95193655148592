// @flow

import { type DefaultTheme } from "../themes";
import tableStyles from "./table";

const reportTable: (DefaultTheme) => * = (theme) => ({
  ...tableStyles(theme),
  table: {
    ...tableStyles(theme).table,
    [theme.breakpoints.down("lg")]: {
      borderSpacing: "0 4px",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
    "& thead": {
      zIndex: 1,
      position: "sticky",
      top: 0,
      background: "#1a1a1a",
      [theme.breakpoints.down("md")]: {
        top: "inherit",
        left: 0,
      },
    },
  },
  tableBody: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  headerRow: {
    ...tableStyles(theme).headerRow,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      backgorund: "#1a1a1a",
    },
  },
  headerCell: {
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.bodyBold,
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodyExtraSmallBold,
      height: "24px",
    },

    [theme.breakpoints.down("md")]: {
      margin: "0 1px",
      display: "flex",
      alignItems: "center",
      padding: 0,
    },
    ...tableStyles(theme).headerCell,
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "12px",
    height: "34px",
  },
  row: {
    ...tableStyles(theme).row,
    [theme.breakpoints.down("md")]: {
      margin: "0 1px",
      display: "flex",
      flexDirection: "column",
      background: "transparent",
    },
  },
  cell: {
    ...tableStyles(theme).cell,

    [theme.breakpoints.down("lg")]: {
      height: "24px",
      "& .MuiSvgIcon-root": {
        fontSize: "16px",
      },
    },
    [theme.breakpoints.down("md")]: {
      margin: "1px 0",
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.05)",
    },
  },
});

export default reportTable;
