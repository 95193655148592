// @flow
import { type DefaultTheme } from "../themes";

const trackingCode: (DefaultTheme) => * = (theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "16px",
    paddingTop: "8px",
    alignItems: "flex-start",
    "& > div": {
      maxWidth: "none",
    },
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 160px",
      gridGap: "20px",
    },
  },
  input: {
    position: "relative",
    "& .MuiFormLabel-root": {
      position: "absolute!important",
      top: "-8px",
      left: "8px",
      zIndex: 1,
      color: "rgba(45, 46, 47, 0.70)",
      background: theme.palette.text.primary,
      padding: "2px 4px 1px",
      textTransform: "uppercase",
      transform: "none",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: 1,
    },
  },
  helper: {
    marginTop: "4px",
    ...theme.typography.bodySmall,
  },
  button: {
    ...theme.typography.mainButton,
  },
});

export default trackingCode;
