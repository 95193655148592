// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";

export type Props = {
  data: mixed[],
  isLoading: boolean,
};

const withNoData: (
  NoData: StatelessFunctionalComponent<*>,
  Component: StatelessFunctionalComponent<*>
) => StatelessFunctionalComponent<*> = (NoData, Component) => ({
  data,
  isLoading,
  ...props
}: Props): Element<*> => (
  (data.length === 0 && !isLoading)
    ? <NoData {...props} data={data} isLoading={isLoading} />
    : <Component {...props} data={data} isLoading={isLoading} />
);

export default withNoData;
