// @flow
import { type DefaultTheme } from "../themes";

const howToChips: (DefaultTheme) => * = (theme) => ({
  container: {
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.05)",
    marginTop: "8px",
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "12px",
      background: "transparent",
      padding: 0,
      display: "flex",
    },
  },
  chips: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "12px",
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    "& .MuiChip-root": {
      background: "transparent",
      borderRadius: 0,
      height: "auto",
      [theme.breakpoints.up("sm")]: {
        background: "rgba(255, 255, 255, 0.05)",
        padding: "10px 12px",
      },
    },
    "& .MuiChip-label": {
      ...theme.typography.bodySmallBold,
      color: theme.palette.text.primary,
      textTransform: "uppercase",
      padding: 0,
      [theme.breakpoints.up("sm")]: {
        textTransform: "initial",
      },
    },
  },

  labels: {
    marginTop: "4px",
    lineHeight: 1,
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
    },
  },
  label: {
    ...theme.typography.bodySmall,
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.body,
    },
  },
});

export default howToChips;
