// @flow
import { type DefaultTheme } from "../themes";

const appNavBarStyles: (DefaultTheme) => * = (theme) => ({
  paper: {
    justifyContent: "flex-start",
    boxShadow: "none",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "12px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "16px",
    },
  },
  drawer: {
    "& > div": {
      borderRight: "none",
      height: "100%",
    },
  },
  drawerOpen: {
    width: "220px",
    transition: "none",
    [theme.breakpoints.up("lg")]: {
      width: "258px",
    },
  },
  drawerClose: {
    width: "72px",
    transition: "none",
  },
});

export default appNavBarStyles;
