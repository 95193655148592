// @flow
import React, { type Node } from "react";

const LogoMob: () => Node = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="160" height="20" viewBox="0 0 160 20" fill="none">
    <g clipPath="url(#clip0_242_106)">
      <path d="M5.04763 19.4556H8.60953V2.9H12.6857V0.559259H0.971436V2.9H5.04763V19.4556Z" fill="white" />
      <path d="M20.6781 0.559259C16.781 0.559259 14.8838 2.52593 14.8838 6.57407V13.4259C14.8838 17.4815 16.7771 19.4556 20.6781 19.4556C24.5791 19.4556 26.4724 17.4852 26.4724 13.4259V6.57407C26.4724 2.52593 24.5791 0.559259 20.6781 0.559259ZM22.9067 13.4296C22.9067 14.7111 22.7467 15.6185 22.4152 16.1963C22.0648 16.8074 21.4819 17.1185 20.6781 17.1185C19.8743 17.1185 19.2914 16.8074 18.941 16.1963C18.6095 15.6148 18.4495 14.7074 18.4495 13.4259V6.57407C18.4495 5.29259 18.6095 4.39259 18.941 3.81481C19.2914 3.20741 19.8743 2.9 20.6781 2.9C21.4819 2.9 22.0648 3.20741 22.4152 3.81481C22.7467 4.38889 22.9067 5.29259 22.9067 6.57407V13.4296Z" fill="white" />
      <path d="M34.9371 0.559259C31.0286 0.559259 29.1314 2.52593 29.1314 6.57407V13.4407C29.1314 17.4889 31.0324 19.4556 34.9371 19.4556C35.9467 19.4556 37.0057 19.2444 38.0876 18.8259C39.1352 18.4222 40.0229 17.9 40.7314 17.2704V9.65926H35.3219V12H37.1695V16.4519L37.0857 16.5148C36.8419 16.6926 36.5105 16.8407 36.1067 16.9519C35.7067 17.063 35.3143 17.1148 34.941 17.1148C34.1257 17.1148 33.5391 16.8074 33.1886 16.2C32.8571 15.6222 32.6971 14.7222 32.6971 13.4407V6.57407C32.6971 5.29259 32.8571 4.39259 33.1886 3.81481C33.5391 3.20741 34.1257 2.9 34.941 2.9C35.7562 2.9 36.3124 3.18519 36.6667 3.75185C37.0057 4.29259 37.1695 5.12593 37.1695 6.3V6.65556H40.7314V6.3C40.7314 2.43704 38.8381 0.559259 34.9371 0.559259Z" fill="white" />
      <path d="M52.7048 17.1185H46.9524V10.7259H52.4076V8.38519H46.9524V2.9H52.7048V0.559259H43.3905V19.4556H52.7048V17.1185Z" fill="white" />
      <path d="M59.4171 19.4556H62.9791V2.9H67.0514V0.559259H55.341V2.9H59.4171V19.4556Z" fill="white" />
      <path d="M73.2762 0.559259H69.7143V19.4556H73.2762V10.7259H77.7524V19.4556H81.3105V0.559259H77.7524V8.38519H73.2762V0.559259Z" fill="white" />
      <path d="M94.2705 17.1185H88.5181V10.7259H93.9771V8.38519H88.5181V2.9H94.2705V0.559259H84.9562V19.4556H94.2705V17.1185Z" fill="white" />
      <path d="M105.486 19.4556H109.112C108.975 19.0481 108.907 18.4889 108.907 17.7593V14.2222C108.907 12.163 108.118 10.9704 106.488 10.5704L105.733 10.3852L106.476 10.1704C107.307 9.92963 107.95 9.47408 108.396 8.81482C108.846 8.14815 109.07 7.29259 109.07 6.27037V4.87037C109.07 3.49259 108.56 2.45556 107.509 1.7C106.45 0.940741 104.926 0.555555 102.983 0.555555H97.9086V19.4519H101.47V11.5444H102.811C103.733 11.5444 104.37 11.7296 104.758 12.1037C105.147 12.4815 105.337 13.0963 105.337 13.9852V17.9444C105.337 18.6 105.383 19.1074 105.478 19.4481L105.486 19.4556ZM104.941 8.65185C104.56 9.02593 103.92 9.20741 102.987 9.20741H101.474V2.9H102.987C103.928 2.9 104.568 3.07778 104.949 3.44815C105.326 3.81481 105.512 4.43704 105.512 5.34444V6.75556C105.512 7.66296 105.326 8.28519 104.941 8.65556V8.65185Z" fill="white" />
      <path d="M138.278 0.559259H133.345V19.4556H138.278C140.545 19.4556 142.236 18.9593 143.314 17.9778C144.389 17 144.93 15.4704 144.93 13.4259V6.57407C144.93 4.54074 144.385 3.01481 143.314 2.03704C142.236 1.05556 140.545 0.559259 138.278 0.559259ZM141.368 13.4296C141.368 14.8074 141.154 15.7407 140.716 16.2852C140.27 16.837 139.451 17.1185 138.278 17.1185H136.91V2.9H138.278C139.451 2.9 140.27 3.18148 140.716 3.73333C141.154 4.27778 141.368 5.20741 141.368 6.57407V13.4296Z" fill="white" />
      <path d="M156.286 9.57778L153.002 7.85556C151.916 7.27407 151.368 6.4037 151.368 5.26296C151.368 4.45556 151.516 3.87778 151.825 3.4963C152.137 3.1037 152.636 2.9037 153.303 2.9037C153.97 2.9037 154.51 3.1 154.823 3.48889C155.128 3.86667 155.276 4.44815 155.276 5.26296V5.92222H158.838V4.62593C158.838 3.37037 158.358 2.4037 157.371 1.66667C156.377 0.929629 155.01 0.555555 153.299 0.555555C151.589 0.555555 150.32 0.944444 149.307 1.71111C148.293 2.47407 147.802 3.42963 147.802 4.62593V5.77778C147.802 6.67037 148.099 7.52963 148.686 8.32593C149.28 9.13333 150.13 9.83333 151.215 10.4037L153.893 11.8148C154.941 12.363 155.47 13.1556 155.47 14.1741V14.3852C155.47 15.3741 155.318 16.0556 154.998 16.4704C154.67 16.9 154.099 17.1185 153.299 17.1185C152.499 17.1185 151.981 16.8593 151.638 16.3519C151.31 15.8667 151.15 15.1111 151.15 14.0519V13.4519H147.589V14.6815C147.589 16.2111 148.084 17.4 149.059 18.2185C150.046 19.0444 151.47 19.463 153.299 19.463C155.128 19.463 156.598 19.0741 157.577 18.3111C158.556 17.5481 159.032 16.4704 159.032 15.0222V13.7778C159.032 11.9185 158.133 10.5444 156.282 9.58148L156.286 9.57778Z" fill="white" />
      <path d="M121.032 0.559259L116.838 19.4556H120.499L123.737 2.31481L126.975 19.4556H130.636L126.453 0.559259H121.032Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_242_106">
        <rect width="160" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoMob;
