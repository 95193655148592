// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { type DefaultTheme } from "../../themes";
import ChatBar from "../../icons/chatBar";

type Props = {
  Icon?: Element<*>,
  headerText?: string,
  text?: string,
  display?: string,
  classes?: *,
};

const NoData: StatelessFunctionalComponent<Props> = withStyles((theme: DefaultTheme): * => ({
  container: {
    marginTop: "20px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "20px 0",
    },
  },
  chatbar: {
    marginBottom: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "80px",
      color: theme.palette.text.mutted,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "20px",
      "& .MuiSvgIcon-root": {
        fontSize: "160px",
      },
    },
  },
  header: {
    color: theme.palette.text.main,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.5,
    maxWidth: "560px",
    marginBottom: "4px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px",
      fontSize: "18px",
      lineHeight: 1.55,
    },
  },
  text: {
    color: theme.palette.text.mutted,
    maxWidth: "560px",
    marginBottom: theme.spacing(2),
    ...theme.typography.bodySmall,
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.body,
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))(({
  classes = {},
  Icon = <ChatBar />,
  headerText = "Oops! Looks like you still have no profit with us.",
  text = "There are no statistics yet. Don’t worry, we have lots of great offers for you.",
  display,
}: Props): Element<*> => (
  <Box display={display || "flex"} className={classes.container}>
    <Box className={classes.chatbar}>
      {Icon}
    </Box>
    <Box component={Typography} className={classes.header}>
      {headerText}
    </Box>
    <Box component={Typography} className={classes.text}>
      {text}
    </Box>
  </Box>
));

export default NoData;
