/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, type Node, type StatelessFunctionalComponent as FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import {
  SetupCard,
  withState,
  type CardSetupProps,
  TrackingCodeV2,
  type TrackingCodeV2Props,
  type TabsSwitcherProps,
  withHookPropsState,
  EventPostbackList,
} from "@fas/cpa-cabinet-ui";
import { useEventsPostback, type UseEventsPostback } from "@fas/cpa-state-manager/redux/hooks/useEventsPostback";
import {
  useTrackingTools,
  type UseTrackingTools,
} from "@fas/cpa-state-manager/redux/hooks";
import { Navigate } from "react-router-dom";
import environment from "environment";
import TabsSwitcher from "@fas/cpa-cabinet-ui/lib/TabsSwitcher";
import { Box } from "@mui/material";
import {
  LockOutlined,
} from "@mui/icons-material";
import {
  setupCardStyles,
  trackingCodeStyles,
  eventPostbackStyles,
} from "../../styles";
import {
  useTabs,
  type UseTabsArgs,
} from "../../hooks";
import { POSTBACKS_PAGE } from "../../constants";
import PostbackLayout from "./PostbackLayout";
import withNoData from "../WithNoData";
import NoData from "./NoData";
import tabsSwitcher from "../../styles/tabsSwitcher";
import SelectPopup from "../SelectPopup";
import TextField from "../TextFieldForm";

type Props = {
  param: string,
};

type InfoPostbackProps = $Diff<*, UseEventsPostback>;
type TrackingCodeProps = $Diff<TrackingCodeV2Props, UseTrackingTools>;

const EventPostbacks: FunctionComponent<InfoPostbackProps> = withNoData(
  (): Element<*> => <NoData title="No info postback yet" description="You currently have no info postbacks. Set them up to see other events (such as confirmations or payments) besides those set in the main postback. To do this, please go to the informational events postback section, press the Create new postback button in the upper right corner, and follow the instructions." />,
  // $FlowFixMe
  EventPostbackList
);

const StyledSetupCard: FunctionComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledInfoPostbacks: FunctionComponent<InfoPostbackProps> = withStyles(eventPostbackStyles)(EventPostbacks);
const InfoPostbacks: FunctionComponent<InfoPostbackProps> = withState(StyledInfoPostbacks, () => ({
  ...useEventsPostback(), disabled: true,
}));
const StyledTrackingCode: FunctionComponent<TrackingCodeV2Props> = withStyles(trackingCodeStyles)(TrackingCodeV2);
// $FlowFixMe
const TrackingCode: FunctionComponent<TrackingCodeProps> = withState(StyledTrackingCode, () => ({
  ...useTrackingTools(), disabled: true, isLoading: true,
}));

const StyledTabs: FunctionComponent<$Diff<TabsSwitcherProps, { classes: * }>> = withStyles(tabsSwitcher)(TabsSwitcher);
const Tabs: FunctionComponent<{
  hookProps: UseTabsArgs,
  allowScrollButtonsMobile?: boolean,
  scrollButtons?: string | boolean,
  variant?: string,
}> = withHookPropsState(StyledTabs, useTabs);

export const paramsToComponents: { [string]: FunctionComponent<*> } = {
  defaulPostback: (): Node => (
    <PostbackLayout>
      <Helmet>
        <title>Default postback</title>
      </Helmet>
      <Box>
        <TrackingCode
          label="Tracking code"
          helperText={"See \"how to\" block below for the details"}
          textFieldVariant="standard"
          buttonSize="small"
        />
      </Box>
    </PostbackLayout>
  ),
  infoPostback: (): Node => (
    <PostbackLayout>
      <Helmet>
        <title>Info event postbacks</title>
      </Helmet>
      <Box>
        <InfoPostbacks
          components={{
            InputField: withStyles()(withState(TextField, (): * => ({ EndIcon: <LockOutlined /> }))),
            SelectField: withStyles()(withState(SelectPopup, (): * => ({
              EndIcon: <LockOutlined />, formControlSx: {}, disableSearch: true,
            }))),
            MultiSelectField: withStyles({
              inputField: { "& ::placeholder": { opacity: 1 } },
            })(withState(SelectPopup, (): * => ({
              EndIcon: <LockOutlined />, formControlSx: {}, isSplitListBoxColumn: true, placeholder: "All",
            }))),
          }}
        />
      </Box>
    </PostbackLayout>
  ),
  default: (): Node => <Navigate to={environment.links.notFound} />,
};

const Postbacks: FunctionComponent<Props> = ({ param }) => (
  <StyledSetupCard title="Tracking tools">
    <Box display="flex" alignItems="flex-end">
      <Box flexGrow={1} minWidth={0}>
        <Tabs hookProps={{ key: POSTBACKS_PAGE }} />
      </Box>
    </Box>
    {paramsToComponents[param]()}
  </StyledSetupCard>
);

export default Postbacks;
