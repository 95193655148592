// @flow
import React, { type Node } from "react";
import type { CommonFormProps } from "@fas/cpa-cabinet-ui/lib/PaymentInfoForm";

function Payoneer({
  components: {
    TextFieldForm,
    DictionarySelectFieldForm,
    FormContainer,
  },
  onSave,
  disabled,
  validate,
  id,
}: CommonFormProps): Node {
  return (
    <FormContainer
      id={id}
      onSave={onSave}
      validate={validate}
    >
      <TextFieldForm disabled={disabled} name="payoneerWallet" label="Payoneer Wallet" placeholder="What is your Payoneer wallet?" />
      <TextFieldForm disabled={disabled} name="beName" label="Beneficiary Name" placeholder="What is your beneficiary name?" />
      <TextFieldForm disabled={disabled} name="beAddress" label="Beneficiary Address" placeholder="What is your beneficiary address?" />
      <DictionarySelectFieldForm disabled={disabled} name="beCountry" label="Beneficiary Country" hookProps="countryId" placeholder="What is your beneficiary country?" />
      <TextFieldForm disabled={disabled} name="vatNumber" label="Vat Number" placeholder="What is your VAT number?" />
    </FormContainer>
  );
}

export default Payoneer;
