// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const ReportEmpty: () => Node = () => (
  <SvgIcon viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M73.3333 46.6667V113.333H86.6667V46.6667H73.3333ZM100 66.6667V113.333H113.333V66.6667H100ZM46.6667 80V113.333H60V80H46.6667ZM20 126.667V33.3333C20 25.98 25.98 20 33.3333 20H126.667C134.02 20 140 25.98 140 33.3333V126.667C140 134.02 134.02 140 126.667 140H33.3333C25.98 140 20 134.02 20 126.667ZM33.3333 126.667H126.673L126.667 33.3333H33.3333V126.667Z" />

  </SvgIcon>
);

export default ReportEmpty;
