// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import environment from "environment";
import { LogoMob, AccountUser } from "../../icons";

import { appHeaderBarStyles } from "../../styles";
import { APP_HEADER_BAR_HEIGHT } from "../../pages/Cabinet/types/Cabinet.types";
import IconLinkButton from "./IconLinkButton";
import Logout from "../../icons/Logout";

const StyledAppHeaderBar: StatelessFunctionalComponent<*> = withStyles(appHeaderBarStyles)(Grid);

const AppHeaderBar: StatelessFunctionalComponent<{}> = () => (
  <StyledAppHeaderBar container alignItems="center" sx={{ height: APP_HEADER_BAR_HEIGHT, display: { xs: "flex", sm: "none" } }}>
    <Box sx={{ display: "flex" }}>
      <LogoMob />
    </Box>

    <Box sx={{ flexGrow: 1 }} />

    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "12px" }}>
      <IconLinkButton to={environment.links.logout} data-testid="logout-btn" lookup="/logout">
        <Logout />
      </IconLinkButton>
      <IconLinkButton to={environment.links.userInfo} data-testid="profile-btn" lookup="/profile">
        <AccountUser />
      </IconLinkButton>
    </Box>
  </StyledAppHeaderBar>
);

export default AppHeaderBar;
