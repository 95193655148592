// @flow
/* eslint-disable import/max-dependencies */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { PAYMENT_METHOD_FORM } from "../../constants";
import api from "../../services/api";

export type PaymentInfoType = {
  bankAccountName: string,
  bankAddress: string,
  beAddress: string,
  beName: string,
  swiftCode: string,
  beCountry: string,
  iban: string,
  payoutCountry: string,
  accountNumber: string,
  bankName: string,
  paymentNote: string,
  paxumMail: string,
  webmoneyWallet: string,
  bitcoin: string,
  qiwi: string,
  yandex: string,
  capitalistWallet: string,
  payoneerWallet: string,
  payPalWallet: string,
  genome: string,
  paysera: string,
  usdt: string,
  vatNumber: string,
  paymentMethodId: number,
  paymentMethodName: string,
  isEdit: boolean,
  currency: string,
};

export type UsePaymentInfoFormHook = {
  isLoading: boolean,
};

export const usePaymentInfoForm: () => UsePaymentInfoFormHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const {
    isLoading,
    data,
  } = useQuery(["apiGetUserPaymentInfo"], () => api.apiGetUserPaymentInfo(), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setFormData(PAYMENT_METHOD_FORM, data.data));
    }
  }, [data, dispatch]);

  return {
    isLoading,
  };
};
