/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, type StatelessFunctionalComponent } from "react";
import {
  Table, TableFilters, withHookPropsState,
} from "@fas/cpa-cabinet-ui";
import { Box } from "@mui/material";
import { usePagination } from "@fas/cpa-state-manager/redux/hooks";
import { withStyles } from "@mui/styles";
import FilterButton from "@fas/cpa-cabinet-ui/lib/Table/FilterButton";
import { useSelector } from "react-redux";
import { getTablePendingFields } from "@fas/cpa-state-manager/services/selectors/table";
import Pagination from "../Pagination";
import { useReportTable, type UseReportTableHook } from "../../hooks/useReportTable";
import { useReportActions } from "../../hooks/useReportActions";
import { tableReportStyles, tableFiltersStyles } from "../../styles";
import EditButton from "../EditReportButton";
import ReportSkeletonLoading from "./ReportSkeletonLoading";
import ApplyButton from "./RefreshButton";
import filterComponentMap from "./filterComponentMap";
import NoDataTable from "./NoData";
import withNoData from "../WithNoData";

type Props = {|
  tableKey: string,
  configureComponent: Element<*>,
  headerTotal?: Element<*>,
  bodyTotal?: Element<*>,
  footerTotal?: Element<*>,
|};

const ReportPagination = withHookPropsState(Pagination, ({ tableKey }) => {
  const { isLoading }: UseReportTableHook = useReportTable({ tableKey });
  return {
    ...usePagination(tableKey),
    isLoading,
  };
});

const StyledFilters: StatelessFunctionalComponent<*> = withStyles(tableFiltersStyles)(TableFilters);
const ReportFilters: StatelessFunctionalComponent<*> = withHookPropsState(StyledFilters, useReportActions);

const StyledFilterButton: StatelessFunctionalComponent<*> = withStyles((theme: *) => ({
  iconButton: {
    "&.MuiIconButton-colorPrimary": {
      color: theme.palette.text.primary,
      marginLeft: "4px",
    },
    "&.MuiIconButton-colorPrimary.Mui-disabled": {
      color: theme.palette.text.mutted,
    },
  },
  popoverPaper: {
    background: "#000",
    boxShadow: "0px 0px 40px 0px rgba(221, 223, 75, 0.40)",
    padding: "20px",
    marginTop: "10px",
    width: "auto",
  },
}))(FilterButton);

const RefreshButton = withHookPropsState(ApplyButton, ({ tableKey }) => {
  const { refetch, isLoading }: UseReportTableHook = useReportTable({ tableKey });
  return {
    disabled: isLoading,
    onApply: () => refetch(),
  };
});

const EditReportButton = withHookPropsState(EditButton, ({ tableKey }) => {
  const { isLoading }: UseReportTableHook = useReportTable({ tableKey });
  return {
    disabled: isLoading,
  };
});

const ReportLoading = withHookPropsState(ReportSkeletonLoading, ({ tableKey }: { tableKey: string }) => {
  const { isLoading }: UseReportTableHook = useReportTable({ tableKey });
  return {
    isLoading,
    fieldsLength: useSelector((state: *): * => getTablePendingFields(state, tableKey)).length,
  };
});

const StyledTable: StatelessFunctionalComponent<*> = withStyles(tableReportStyles)(Table);
const TableWithLoading: StatelessFunctionalComponent<*> = withNoData(NoDataTable, StyledTable);
// eslint-disable-next-line max-len
const ReportTable: StatelessFunctionalComponent<*> = withHookPropsState(TableWithLoading, useReportTable);

const Report: StatelessFunctionalComponent<Props> = ({
  tableKey,
  configureComponent,
  headerTotal,
  bodyTotal,
  footerTotal,
}) => {
  const FilterButtonComponent = (props) => <StyledFilterButton {...props} />;

  return (
    <>
      {headerTotal}
      <ReportFilters hookProps={{ tableKey, isLoading: false }} filterComponentMap={filterComponentMap}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row-reverse", md: "row" },
            width: "100%",
          }}
        >
          <RefreshButton hookProps={{ tableKey }} sx={{ marginLeft: { xs: "12px", md: "0" }, marginRight: { md: "8px" } }} />
          <EditReportButton hookProps={{ tableKey }}>
            {configureComponent}
          </EditReportButton>
        </Box>
      </ReportFilters>

      <ReportTable
        hookProps={{ tableKey }}
        FilterButton={FilterButtonComponent}
      >
        <ReportLoading hookProps={{ tableKey }} />
        {bodyTotal}
      </ReportTable>
      {footerTotal}

      <ReportPagination hookProps={{ tableKey }} />
    </>
  );
};

export default Report;
