// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import {
  Box,
  Pagination as MuiPagination,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";

export type Props = {
  count: number,
  onChangePage: number => mixed,
  onChangePageSize: number => mixed,
  page: number,
  pageSize: number,
  isLoading?: boolean,
  rowsPerPage?: number[],
};

const defaultRowsPerPage: number[] = [10, 25, 50, 100];

const Pagination: StatelessFunctionalComponent<Props> = ({
  isLoading,
  page,
  pageSize,
  count,
  onChangePage,
  onChangePageSize,
  rowsPerPage = defaultRowsPerPage,
}) => {
  const theme: * = useTheme();

  const lg: boolean = useMediaQuery(theme.breakpoints.down("lg"));
  const xl: boolean = useMediaQuery(theme.breakpoints.down("xl"));

  let siblingCount: number = 3;
  if (xl) {
    siblingCount = 1;
  }
  if (lg) {
    siblingCount = 0;
  }

  return (
    <Box
      data-testid="tablePaginationBlock"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-between" },
        marginTop: "auto",
        paddingTop: "20px",
        gap: "8px",
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ display: { xs: "none", md: "flex" } }}>
        <Typography component="p" color="text.mutted" sx={{ display: { xs: "none", lg: "block" }, mr: { xs: "10px", lg: "20px" } }}>Rows per page:&nbsp;</Typography>
        <Select
          data-testid="rowsperpage"
          disableUnderline
          variant="standard"
          value={pageSize}
          disabled={isLoading}
          onChange={(e: *): mixed => {
            onChangePageSize(parseInt(e.target.value, 10));
            onChangePage(1);
          }}
        >
          {rowsPerPage.map((option: number): Element<MenuItem> => (
            <MenuItem key={String(option)} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </Box>
      <MuiPagination
        siblingCount={siblingCount}
        boundaryCount={1}
        disabled={isLoading}
        page={page}
        count={Math.ceil(count / pageSize)}
        onChange={(_: *, newPage: number): mixed => onChangePage(newPage)}
      />
      <Typography component="p" sx={{ typography: "body", display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <Typography component="span" color="text.mutted" sx={{ marginRight: "8px", fontSize: { xs: "12px", lg: "16px" } }}>Results:</Typography>
        {" "}
        <Typography component="span" sx={{ fontSize: { xs: "12px", lg: "16px", fontWeight: "700" } }}>
          {count === 0 ? 0 : (page - 1) * pageSize || 1}
          -
          {count === 0 ? 0 : page * pageSize}
          {" of "}
          {count}
        </Typography>
      </Typography>
    </Box>
  );
};

export default Pagination;
