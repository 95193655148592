// @flow
import { type DefaultTheme } from "../themes";

const autologin: (DefaultTheme) => * = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "556px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      gap: "40px",
    },
  },
  image: {
    width: "100%",
    display: "block",
  },
  title: {
    fontSize: "40px",
    fontWeight: "800",
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "50px",
    },
  },
  text: {
    color: "rgba(255,255,255,0.85)",
    marginTop: "8px",
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },

  },
  button: {
    ...theme.typography.mainButton,
    marginTop: "12px",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
      maxWidth: "200px",
    },
  },
});

export default autologin;
