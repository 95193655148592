// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, type Node, useEffect, useRef, useState,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  List,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { mergeClasses } from "@fas/cpa-cabinet-ui/lib/utils";
import { TextFieldForm } from "../TextFieldForm/TextFieldForm";
import { onKeyWrapper } from "../../utils";

export type Props = {|
  value: *,
  onChange: (*) => *,
  name: string,
  label?: string,
  classes?: *,
  onClose?: () => *,
  formControlSx?: *,
  placeholder?: string,
  options: string[],
  setOptions: (string[]) => *,
  ListWrapper?: StatelessFunctionalComponent<*>,
|};

const DefaultListWrapper: * = ({ children, sx, className }: { children: Node, sx: *, className: * }): * => (
  <Box sx={sx} className={className}>{children}</Box>
);

const useStyles: * = makeStyles((theme): * => ({
  header: {
    marginBottom: "12px",
    alignItems: "center",
  },
  title: {
    ...theme.typography.bodyBold,
    color: theme.palette.text.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: 1.555,
    },
  },
  subtitle: {
    color: theme.palette.text.mutted,
    fontSize: "14px",
  },
  clearAll: {
    ...theme.typography.bodyExtraSmallBold,
    color: theme.palette.text.primary,
    marginLeft: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    "&:hover": {
      backgroundColor: theme.palette.transparent.main,
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.bodySmallBold,
    },
    [theme.breakpoints.up("md")]: {
      ...theme.typography.bodyExtraSmallBold,
    },
    [theme.breakpoints.up("lg")]: {
      ...theme.typography.bodySmallBold,
    },
  },
  list: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "40vh",
    overflowY: "auto",
    padding: "8px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px 8px 8px",
    },
  },
  option: {
    color: theme.palette.text.main,
    "&:hover": {
      color: theme.palette.text.primary,
      background: "inherit",
    },
  },
}));

const TextFieldMultiselectBody: StatelessFunctionalComponent<Props> = ({
  value,
  onChange,
  name,
  label,
  classes: propClasses = {},
  onClose = (): * => null,
  formControlSx = {},
  placeholder,
  ListWrapper = DefaultListWrapper,
  options = [],
  setOptions,
}) => {
  const [anchorEl, setAnchorEl]: * = useState<null | HTMLElement>(null);
  const ref: * = useRef();

  const ownClasses: { [string]: * } = useStyles();
  const classes: { [string]: * } = mergeClasses(ownClasses, propClasses);

  function onDelete(item: string) {
    onChange(value.filter((i: string): boolean => i !== item));
  }

  function onAddNew(item: string) {
    if (!value.includes(item)) {
      onChange([...value, item]);
    }
    if (!options.includes(item)) {
      setOptions([...options, item]);
    }
  }
  const [inputValue, setInputValue]: * = useState<string>("");
  const onValueChange: (string) => * = (v: string): * => setInputValue(v);

  const handleToggle: (string) => * = (item: string): * => {
    if (value.includes(item)) {
      onDelete(item);
    }
    else {
      onAddNew(item);
    }
  };
  const onEnter: * = onKeyWrapper("Enter")(() => {
    const trimmedValue: string = inputValue.trim();
    if (trimmedValue) {
      onAddNew(trimmedValue);
      setInputValue("");
    }
  });

  useEffect(() => {
    ref.current && setAnchorEl(ref.current.children[1]);
  }, [ref]);

  const allOptions: string[] = Array.from(new Set([...options, ...value]));
  const hasOptions: boolean = allOptions.length > 0;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div ref={ref}>
        <Box display="flex" justifyContent="space-between" className={classes.header}>
          <Box>
            <Typography className={classes.title}>{label}</Typography>
          </Box>
          <Box>
            <Button className={classes.clearAll} data-testid="clear-all" onClick={(): * => onChange([])}>
              Clear all
            </Button>
          </Box>
        </Box>
        <TextFieldForm
          autoFocus
          sx={{ zIndex: "modal" }}
          formControlSx={formControlSx}
          name={name}
          label=""
          isAuthenticated
          className={classes.inputField}
          placeholder={placeholder}
          value={inputValue}
          onChange={onValueChange}
          onKeyPress={onEnter}
          inputProps={{
            maxLength: 256,
          }}
        />
        <ListWrapper
          className={classes.popper}
          open={Boolean(anchorEl)}
          sx={{ zIndex: "modal", width: anchorEl && anchorEl.clientWidth }}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          {hasOptions && (
            <List
              className={classes.list}
            >
              {allOptions.map((item: string): Node => (
                <li key={item} className={classes.option}>
                  <FormControlLabel control={<Checkbox size="small" checked={value.includes(item)} onChange={() => handleToggle(item)} />} label={item} />
                </li>
              ))}
            </List>
          )}
        </ListWrapper>
      </div>
    </ClickAwayListener>
  );
};

export default TextFieldMultiselectBody;
