// @flow
/* eslint-disable import/max-dependencies, no-unused-vars */
import environment from "environment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { getUserInfo } from "@fas/cpa-state-manager/services/selectors";
import { setUserInfo } from "@fas/cpa-state-manager/redux/actions/userInfo";
import type { UserInfoDefaultStateType } from "@fas/cpa-state-manager/redux/reducers/userInfo";
import type { UserInfoPayload } from "@fas/cpa-state-manager/redux/containers/UserInfo";

import api from "../../services/api";
import { USER_INFO_FORM } from "../../constants";

export type UseUserInfoHook = {
  isLoading?: boolean,
  id?: string,
  name: string,
  avatarUrl: string,
  onViewClick: () => void,
};
export const useUserInfo: () => UseUserInfoHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();

  const {
    isLoading,
    data,
  } = useQuery(["apiGetUserInfo"], () => api.apiGetUserInfo(), {
    staleTime: Infinity,
  });

  const {
    id, name, avatarUrl,
  }: UserInfoDefaultStateType = useSelector((state: *): * => getUserInfo(state, USER_INFO_FORM));

  useEffect(() => {
    if (data?.data) {
      const userInfo: UserInfoPayload = {
        id: data.data.id,
        name: `${data.data.firstName} ${data.data.lastName}`,
        avatarUrl: data.data.photo,
      };
      dispatch(setUserInfo(userInfo));
    }
  }, [data, dispatch]);

  return {
    isLoading,
    id,
    avatarUrl,
    name,
    onViewClick: () => navigate(environment.links.userInfo),
  };
};
