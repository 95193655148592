// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const OutstandingBalance: () => Node = () => (
  <SvgIcon viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.236 14C11.236 19.466 13.8218 24.3048 17.7925 27.2697C16.4394 27.7433 14.9901 28 13.4831 28C6.03658 28 0 21.732 0 14C0 6.26799 6.03658 0 13.4831 0C14.9901 0 16.4394 0.256689 17.7925 0.730306C13.8218 3.69517 11.236 8.53404 11.236 14Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M40 14C40 21.732 33.9634 28 26.5169 28C19.0703 28 13.0337 21.732 13.0337 14C13.0337 6.26799 19.0703 0 26.5169 0C33.9634 0 40 6.26799 40 14ZM25.6711 22.8667V21.0964C24.961 21.0238 24.3279 20.8271 23.7717 20.5063C23.2155 20.1795 22.7806 19.7226 22.4669 19.1356C22.1592 18.5485 22.0113 17.8223 22.0231 16.9568L22.0409 16.9114H24.2155C24.2155 17.4863 24.3072 17.9524 24.4907 18.3094C24.68 18.6605 24.9344 18.9177 25.254 19.0811C25.5735 19.2384 25.9345 19.3171 26.3368 19.3171C26.757 19.3171 27.115 19.2445 27.4108 19.0992C27.7067 18.9479 27.9316 18.7361 28.0854 18.4638C28.2451 18.1914 28.325 17.8676 28.325 17.4924C28.325 17.1474 28.254 16.8448 28.1121 16.5846C27.9759 16.3243 27.754 16.0883 27.4463 15.8765C27.1445 15.6647 26.7422 15.4649 26.2392 15.2773C25.399 14.9808 24.6978 14.654 24.1356 14.2969C23.5794 13.9398 23.1622 13.5101 22.8841 13.0078C22.6119 12.5054 22.4758 11.9002 22.4758 11.1921C22.4758 10.1814 22.7776 9.35532 23.3812 8.71383C23.9847 8.07227 24.8072 7.691 25.8487 7.56995V5.6H27.2511V7.59718C28.2689 7.74848 29.0647 8.18729 29.6387 8.91355C30.2127 9.63981 30.4967 10.596 30.4908 11.7823L30.4198 11.8095H28.2807C28.2807 11.0469 28.118 10.4478 27.7925 10.012C27.473 9.57624 27.0321 9.35834 26.47 9.35834C25.8782 9.35834 25.4345 9.5248 25.1386 9.85765C24.8486 10.1845 24.7037 10.6263 24.7037 11.1831C24.7037 11.5281 24.7688 11.8307 24.8989 12.0909C25.0291 12.3451 25.248 12.575 25.5558 12.7808C25.8634 12.9866 26.2865 13.1894 26.825 13.389C27.6653 13.7038 28.3606 14.0427 28.9109 14.4058C29.4671 14.7689 29.8813 15.1956 30.1535 15.6859C30.4257 16.1761 30.5618 16.7722 30.5618 17.4743C30.5618 18.5152 30.2482 19.3504 29.621 19.9798C28.9996 20.6032 28.1505 20.9724 27.0735 21.0873V22.8667H25.6711Z" />
  </SvgIcon>
);

export default OutstandingBalance;
