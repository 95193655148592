// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Phone: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 3C5 1.89543 5.89543 1 7 1H17C18.1046 1 19 1.89543 19 3V21C19 22.1046 18.1046 23 17 23H7C5.89543 23 5 22.1046 5 21V3C5 3 5 1.89543 5 3ZM15 3H17V21H7L7 3H9C9 4.10457 9.89543 5 11 5H13C14.1046 5 15 4.10457 15 3Z" />
  </SvgIcon>
);

export default Phone;
